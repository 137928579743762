import styled from "styled-components";

export const InputContainer = styled.div`
  /* outline: 1px solid green; */
  position: relative;
  width: 100%;
  &.full_height {
    height: 100%;
  }
`;

export const SuggestionsDropdown = styled.ul`
  position: absolute;
  display: ${({ $toggle }) => ($toggle ? "flex" : "none")};
  flex-direction: column;
  gap: 0rem;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: white;
  max-height: 10rem;
  overflow-y: auto;
  z-index: 99;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const SuggestionItem = styled.li`
  border-bottom: 1px solid var(--color-bb);
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;

  i {
    font-size: 1.5rem;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const LocationMarker = styled.span`
  /* outline: 1px solid green; */
  height: 100%;
  width: 1.5rem;
`;
