import styled from "styled-components";

export const Wrapper = styled.div`
  /* outline: auto; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--clr-neutral-100);
  flex: 1;
  padding: 1.2rem;

  @media screen and (max-width: 55em) {
    min-height: calc(100vh - (var(--size-rootheader)));
  }
`;

export const ImageAndInformationContainer = styled.div`
  /* outline: auto; */
  display: flex;
  flex-direction: column;
  width: min(100%, 25rem);

  svg {
    width: 100%;
    height: auto;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Information = styled.p`
  font-family: var(--ff-secondary);
  font-weight: var(--fw-bold);
  font-size: var(--fs-700);
  text-align: center;
`;
