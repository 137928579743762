import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CustomSelectButton from "../../../components/Custom Select Button/CustomSelectButton";
import { getUserProfile } from "../../../redux/slices/userInfoSlice";
import {
  CloseButton,
  FilterHeader,
  FilterLabel,
  FiltersContainer,
  FiltersWrapper,
} from "./layoutStyles";

const Filter = ({ filterMenuToggle, onClose }) => {
  const userProfile = useSelector(getUserProfile);

  const [searchParams, setSearchParams] = useSearchParams();

  const tuitionTypeParam = searchParams.get("tuition_type");

  const monthParam = searchParams.get("month");

  const yearParam = searchParams.get("year");

  const [isTuitionTypeOpen, setIsTuitionTypeOpen] = useState(false);

  const [isMonthOpen, setIsMonthOpen] = useState(false);

  const [isYearOpen, setIsYearOpen] = useState(false);

  const [tuitionTypeValue, setTuitionTypeValue] = useState("");
  const [monthValue, setMonthValue] = useState("");
  const [yearValue, setYearValue] = useState("");

  const [tuitionTyepOptions, setTuitionTypeOptions] = useState([]);

  const [yearOptions, setYearOptions] = useState([]);

  const tuitionTypeMap = {
    GEN: "General Tuition",
    HOME: "Home Tuition",
  };

  const monthsOptions = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const handleMonthToggle = () => {
    setIsMonthOpen((curr) => !curr);
  };

  const handleTuitionTypeToggle = () => {
    setIsTuitionTypeOpen((curr) => !curr);
  };

  const handleYearToggle = () => {
    setIsYearOpen((curr) => !curr);
  };

  const handleTuitionTypeSelect = (value, label) => {
    searchParams.set("tuition_type", value);
    setSearchParams(searchParams, { replace: true });
    setTuitionTypeValue(label);
    setIsTuitionTypeOpen(false);
  };

  const handleMonthSelect = (value, label) => {
    searchParams.set("month", value);
    setSearchParams(searchParams, { replace: true });
    setMonthValue(label);
    setIsMonthOpen(false);
  };

  const handleYearSelect = (value, label) => {
    searchParams.set("year", value);
    setSearchParams(searchParams, { replace: true });
    setYearValue(label);
    setIsYearOpen(false);
  };

  useEffect(() => {
    /* Setting the options for tuition type*/

    let optionsMap = {
      GEN: [{ value: "GEN", label: "General Tuition" }],
      HOME: [{ value: "HOME", label: "Home Tuition" }],
      "GEN-HOME": [
        { value: "GEN", label: "General Tuition" },
        { value: "HOME", label: "Home Tuition" },
      ],
    };

    setTuitionTypeOptions(optionsMap[userProfile?.tuition_type]);
  }, [userProfile]);

  useEffect(() => {
    /*
    sets the initial value to tuition type select
    during the initial load for pre selecting an option 
    based on the param
    */
    if (!tuitionTypeValue) {
      setTuitionTypeValue(tuitionTypeMap[tuitionTypeParam]);
    }
  }, [tuitionTypeParam]);

  useEffect(() => {
    /*
    sets the initial value to month select
    during the initial load for pre selecting an option 
    based on the param
    */
    if (!monthValue) {
      monthsOptions.map((month) => {
        if (month.value === monthParam) {
          setMonthValue(month.label);
        }
      });
    }
  }, [monthParam]);

  useEffect(() => {
    /*
        THIS SETS THE OPTIONS FOR YEAR FILTER.
        IT TAKES THE CURRENT YEAR AS THE REFERENCE POINT
        AND GIVES TWO YEARS LESS THAN THE CURRENT YEAR AS 
        OPTIONS ALONG WITH THE CURRENT YEAR.
    */

    const referencePointYear = new Date().getFullYear();

    let optionList = [];

    for (let i = 0; i <= 2; i++) {
      let oneYearLess = referencePointYear - i;
      optionList.push({
        value: oneYearLess.toString(),
        label: oneYearLess.toString(),
      });
    }
    setYearOptions(optionList);
    if (!yearValue) {
      setYearValue(referencePointYear.toString());
    }
  }, [userProfile]);

  return (
    <FiltersWrapper $toggleDisplay={filterMenuToggle}>
      <FilterHeader>
        <p>Filters</p>
        <CloseButton onClick={onClose} type="button">
          <i className="fas fa-times"></i>
        </CloseButton>
      </FilterHeader>
      <FiltersContainer>
        <FilterLabel>Tuition Type</FilterLabel>
        <CustomSelectButton
          isOpen={isTuitionTypeOpen}
          onToggle={handleTuitionTypeToggle}
          options={tuitionTyepOptions}
          value={tuitionTypeValue}
          handleSelect={handleTuitionTypeSelect}
        />
      </FiltersContainer>

      <FiltersContainer>
        <FilterLabel>Month</FilterLabel>
        <CustomSelectButton
          isOpen={isMonthOpen}
          onToggle={handleMonthToggle}
          options={monthsOptions}
          value={monthValue}
          handleSelect={handleMonthSelect}
        />
      </FiltersContainer>

      <FiltersContainer>
        <FilterLabel>Year</FilterLabel>
        <CustomSelectButton
          options={yearOptions}
          isOpen={isYearOpen}
          onToggle={handleYearToggle}
          value={yearValue}
          handleSelect={handleYearSelect}
        />
      </FiltersContainer>
    </FiltersWrapper>
  );
};

export default Filter;
