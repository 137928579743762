import styled from "styled-components";

export const TableContainer = styled.div`
  border-radius: 1rem;
  background-color: var(--clr-neutral-100);
  border: 2px solid #edeef1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px rgba(0, 0, 0, 0.08) 0px 0px
    0px 1px;
  text-align: left;
  padding: 1.5rem;
  /* height: 100%; */
  overflow-x: auto;

  @media screen and (max-width: 50em) {
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--clr-neutral-900);
    border-radius: 4px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

export const TableHeader = styled.thead`
  /* background-color: rgb(245, 245, 245); */
  color: var(--clr-neutral-900);
  border-top: 2px solid #edeef1;
`;

export const TableCaption = styled.caption`
  font-size: 1.12rem;
  font-weight: var(--fw-bold);
  text-align: left;
  margin-block: 0.5rem;
`;

export const TableRow = styled.tr`
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
`;

export const TableHeaderCell = styled.th`
  padding: 1.25rem 1rem;
  color: #a0a7b1;
`;

export const TableBody = styled.tbody`
  tr {
    cursor: pointer;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
      background-color: #e2ecff;
    }
  }
`;

export const TableCell = styled.td`
  padding: 0.5rem 1rem;
  vertical-align: middle;
`;

export const StudentNameAndPic = styled.span`
  display: flex;
  align-items: center;
  gap: 0.9rem;
  font-weight: var(--fw-bold);
  img {
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const PaymentStatusValue = styled.span`
  background-color: ${({ $status }) =>
    $status === "PENDING"
      ? "#fff6e5"
      : $status === "PAID"
      ? "#eafef3"
      : "#fff"};
  color: ${({ $status }) =>
    $status === "PENDING"
      ? "#f4bd50"
      : $status === "PAID"
      ? "#2ecc71"
      : "#fff"};
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.3rem;
  font-weight: var(--fw-bold);
  border-radius: 0.3rem;
`;

export const BoldText = styled.span`
  font-weight: var(--fw-bold);
`;

export const ItemCount = styled.span`
  font-size: 0.8rem;
  font-weight: var(--fw-bold);
  background-color: #f1f2f6;
  padding: 0.5rem;
  display: inline-flex;

  align-items: center;
  margin-left: 1rem;
  border-radius: 0.5rem;
`;
