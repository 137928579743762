import { Content, ContentHeader } from "./t&cMainStyles";

const AccountDeletion = () => {
  return (
    <Content id="account_deletion">
      <ContentHeader>
        <p>10. Deletion of Your Account</p>
      </ContentHeader>
      <p>
        As a user of 'Schoolies', you have the right to delete your account at
        any time. Account deletion can be initiated by contacting us through the
        email address listed in the ‘Contact for User Support’ section or by
        utilizing the deletion feature available within your account on the
        Platform. Once your account is deleted, access to any services, data, or
        content associated with your account on the Platform will be permanently
        removed and cannot be recovered.
      </p>

      <p>
        <span>Restrictions on Account Deletion for Students:</span>
      </p>
      <p>
        Users registered as students are not permitted to delete their accounts
        if there are outstanding fees or dues pending. Account deletion requests
        for students will only be processed once the account balance is cleared
        and paid in full.
      </p>

      <p>
        <span>Effects of deleting a Student's account:</span>
      </p>
      <p>
        Upon the deletion of a student’s account, all associated data will be
        permanently erased. This includes personal information provided during
        registration and subsequently, any pending requests sent to teachers,
        and enrollment details confirming your engagements with teachers.
      </p>
      <p>
        <span>Effects of deleting a Teacher's account:</span>
      </p>
      <p>
        Similarly, deleting a teacher’s account results in the permanent
        deletion of all related data. This encompasses information submitted at
        registration and afterwards, pending requests from students, and details
        of student enrollments under your guidance.
      </p>
      <p>
        Please be aware that the deletion of your account is irreversible. We
        encourage users to carefully consider the implications of account
        deletion before proceeding with this action.
      </p>
    </Content>
  );
};

export default AccountDeletion;
