import { Link } from "react-scroll";
import styled from "styled-components";

const Wrapper = styled.main`
  min-height: calc(100vh - (var(--main-header-height)));
  overflow-x: hidden;
  display: flex;
  gap: 0.8rem;
  background: #fcfcfc;
  font-family: var(--primary-ff);
`;

const MainContentContainer = styled.section`
  /* border: 1px solid green; */
  width: 71rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline: auto;
  padding: 2rem 0;

  @media screen and (max-width: 480px) {
    padding: 2rem 1rem;
  }
`;

const MainHeading = styled.header`
  width: 100%;

  p {
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-panel-dark);
  }
`;

const TableContentContainer = styled.section`
  ol {
    li {
      display: list-item;
      list-style: unset;
      list-style-position: inside;
      margin-bottom: 0.5rem;
      color: var(--color-panel-primary);
    }
  }
`;

const TableContentLink = styled(Link)`
  color: var(--color-panel-primary);
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ol {
    li {
      display: list-item;
      list-style: unset;
      list-style-position: outside;
      margin-bottom: 0.5rem;

      p {
        margin-block: 0.5rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  span {
    font-weight: var(--fw-bold);
  }
`;

const ContentHeader = styled.header`
  p {
    font-size: 1.2rem;
    color: var(--color-panel-dark);
    font-weight: var(--fw-bold);
  }
`;

export {
  Wrapper,
  MainContentContainer,
  MainHeading,
  Content,
  TableContentLink,
  ContentHeader,
  TableContentContainer,
};
