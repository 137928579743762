import { Content, ContentHeader } from "./t&cMainStyles";

const ContactForUserSupport = () => {
  return (
    <Content id="contact_user_support">
      <ContentHeader>
        <p>11. Contact for User Support</p>
      </ContentHeader>
      <p>
        For queries and concerns, please write to us at{" "}
        <span>support@schoolies.in</span>.
      </p>
    </Content>
  );
};

export default ContactForUserSupport;
