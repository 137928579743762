import styled from "styled-components";

const Container = styled.div`
  /* border: 2px solid green; */
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--primary-padding);
  font-family: var(--primary-ff);
  gap: 1rem;

  @media screen and (max-width: 50em) {
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    gap: 0.5rem;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-bb);
  padding: 0.5rem var(--primary-padding);
  background-color: var(--color-white);
  height: 3.5rem;
  width: 100%;
`;

const LeftContainer = styled.div`
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  height: 100%;
`;

const RightContainer = styled(LeftContainer)``;

const FullLogo = styled.img`
  width: 10rem;

  @media screen and (max-width: 50em) {
    display: none;
  }
`;

const SmallLogo = styled.img`
  width: 3rem;
  display: none;

  @media screen and (max-width: 50em) {
    display: block;
  }
`;

const LogoutButton = styled.button`
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  outline: none;
  border: none;
  background: #1a73e8;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 0.62rem;
  cursor: pointer;
  transition: all 270ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

  &:hover {
    background: #135cba;
  }
`;

export {
  Header,
  LeftContainer,
  FullLogo,
  SmallLogo,
  RightContainer,
  LogoutButton,
};
