import { Fragment } from "react";

import {
  FeesInfoContainer,
  TuitionInfoContainer,
  GenFeeInfoCont,
  HomeFeeCont,
  InfoHeading,
  Table,
  TableHead,
  TableBody,
} from "./tuitionAndFeesStyles";

const TuitionAndFeesInfo = ({ profile }) => {
  return (
    <>
      <TuitionInfoContainer>
        <p className="info">
          <span>Current Proffession : </span>
          {profile && profile.current_profession}
        </p>
        {profile.current_profession === "TEACHER" && (
          <p className="info">
            <span>Teaching in school : </span>
            {profile && profile.name_of_school}
          </p>
        )}
        <p className="info">
          <span>Location : </span>
          {profile && profile.location}
        </p>

        <p className="info">
          {profile.tuition_type === "GEN" && (
            <>
              <span>Tuition Type : </span> General Tuition
            </>
          )}
          {profile.tuition_type === "HOME" && (
            <>
              <span>Tuition Type : </span> Home Tuition
            </>
          )}
          {profile.tuition_type === "GEN-HOME" && (
            <>
              <span>Tuition Type : </span> Both General and Home Tuition
            </>
          )}
        </p>

        <p className="info">
          <span>Shifts available : </span> {profile && profile.shift}
        </p>
        <p className="info">
          <span>Tuition available for subject(s) : </span>
          {profile && profile.subject}
        </p>

        {["GEN", "GEN-HOME"].includes(profile.tuition_type) && (
          <p className="info">
            <span>General Tuition taken for classes: </span>
            {profile.general_tuition_classes}
          </p>
        )}

        {["HOME", "GEN-HOME"].includes(profile.tuition_type) && (
          <p className="info">
            <span>Home Tuition taken for classes : </span>
            {profile.home_tuition_classes}
          </p>
        )}

        <p className="info">
          <span>Tuitions available for boards : </span>
          {profile && profile.board}
        </p>
      </TuitionInfoContainer>
      <FeesInfoContainer>
        {["GEN", "GEN-HOME"].includes(profile.tuition_type) && (
          <GenFeeInfoCont>
            <InfoHeading>
              <p>General Tuition Fees</p>
            </InfoHeading>
            <Table>
              <TableHead>
                <tr>
                  <th>For Classes</th>
                  <th>Fee Amount</th>
                </tr>
              </TableHead>
              <TableBody>
                {profile.tch_fee.map((element, index) => {
                  if (element.tuition_type === "GEN") {
                    return (
                      <tr key={index}>
                        <td>{element.for_classes}</td>
                        <td>₹ {element.tch_fee_amount}</td>
                      </tr>
                    );
                  } else {
                    return <Fragment key={index}></Fragment>;
                  }
                })}
              </TableBody>
            </Table>
          </GenFeeInfoCont>
        )}
        {["HOME", "GEN-HOME"].includes(profile.tuition_type) && (
          <HomeFeeCont>
            <InfoHeading>
              <p>Home Tuition Fees</p>
            </InfoHeading>
            <Table>
              <TableHead>
                <tr>
                  <th>For Classes</th>
                  <th>Fee Amount</th>
                </tr>
              </TableHead>
              <TableBody>
                {profile.tch_fee.map((element, index) => {
                  if (element.tuition_type === "HOME") {
                    return (
                      <tr key={index}>
                        <td>{element.for_classes}</td>
                        <td>₹ {element.tch_fee_amount}</td>
                      </tr>
                    );
                  } else {
                    return <Fragment key={index}></Fragment>;
                  }
                })}
              </TableBody>
            </Table>
          </HomeFeeCont>
        )}
      </FeesInfoContainer>
    </>
  );
};

export default TuitionAndFeesInfo;
