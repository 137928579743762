import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { sendReq } from "../../../api/enrollmentApi";
import { NotificationBar } from "../../../components/notificationBar";
import {
  getAuthenticatedStatus,
  getUser,
} from "../../../redux/slices/authSlice";
import { setRedirectPath } from "../../../redux/slices/redirectSlice";
import { getTuitionTypeName } from "../../../utils/getTuitionTypeName";
import {
  ButtonContainer,
  CloseButton,
  Form,
  FormCointainer,
  Header,
  InputFieldContainer,
  Label,
  SubmitButton,
} from "./formStyles";
const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "100%",
    border: state.isFocused ? "2px solid #1ad7e8" : "2px solid #aeaeb5",
    boxShadow: "none",
    "&:hover": {
      border: "2px solid #1ad7e8", // Change border color on hover
    },
    cursor: state.isDisabled ? "not-allowed" : "default",
  }),
  indicatorSeparator: () => null,

  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%",
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: "nowrap", // Prevent wrapping of placeholder text
    overflow: "hidden",
    textOverflow: "ellipsis", // Add ellipsis for text that overflows
    fontSize: "0.9rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: "nowrap", // Prevent wrapping of selected value
    overflow: "hidden",
    textOverflow: "ellipsis", // Add ellipsis for text that overflows
  }),
  valueContaine: (provided) => ({
    ...provided,
    // outline: "1px solid green",
  }),
  // ... other styles
};
const ApplicationForm = ({ teacherDetail, toggleFormDisplay, onClose }) => {
  const animatedComponents = makeAnimated();

  const isUserAuthenticated = useSelector(getAuthenticatedStatus);
  const user = useSelector(getUser);

  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();

  const [standardOptions, setStandardOptions] = useState([]);
  const [tuitionTypeOptions, setTuitionTypeOptions] = useState([]);
  const [boardOptions, setBoardOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [subjectValues, setSubjectValues] = useState([]);

  const [applicationDetails, setApplicationDetails] = useState({
    teacherId: "",
    applyingForTuitionType: "",
    applyingForStandard: "",
    applyingForBoard: "",
    applyingForSubjects: "",
  });

  const {
    applyingForTuitionType,
    applyingForStandard,
    applyingForBoard,
    applyingForSubjects,
  } = applicationDetails;

  const handleSelectChange = (selectedValue, event) => {
    const { name } = event;

    setApplicationDetails({
      ...applicationDetails,
      [name]: selectedValue.value,
    });
  };

  const handleSubjectMultiSelect = (selectedValue, event) => {
    let subjectArr = selectedValue.map((item) => item.value);

    setApplicationDetails({
      ...applicationDetails,
      applyingForSubjects: subjectArr.join(", "),
    });

    setSubjectValues(selectedValue);
  };

  useEffect(() => {
    // used for setting the options of tuition type
    let tuitionTypeMap = {
      GEN: [{ value: "GEN", label: "General Tuition" }],
      HOME: [{ value: "HOME", label: "Home Tuition" }],
      "GEN-HOME": [
        { value: "GEN", label: "General Tuition" },
        { value: "HOME", label: "Home Tuition" },
      ],
    };

    setTuitionTypeOptions(tuitionTypeMap[teacherDetail?.tuition_type]);

    let boardArr = teacherDetail?.board.split(",");
    setBoardOptions(boardArr?.map((item) => ({ value: item, label: item })));

    let subjectArr = teacherDetail?.subject.split(",");
    setSubjectOptions(
      subjectArr?.map((item) => ({ value: item, label: item }))
    );

    setApplicationDetails({
      ...applicationDetails,
      teacherId: teacherDetail?.id,
    });
  }, [teacherDetail]);

  useEffect(() => {
    console.log(teacherDetail);
    if (applyingForTuitionType !== "") {
      let classArr;
      let tuitionTypeMap = {
        GEN: teacherDetail?.general_tuition_classes?.split(","),
        HOME: teacherDetail?.home_tuition_classes?.split(","),
      };
      classArr = tuitionTypeMap[applyingForTuitionType];
      setApplicationDetails({
        ...applicationDetails,
        applyingForStandard: "",
      });
      setStandardOptions(
        classArr.map((item) => ({ value: item.trim(), label: item.trim() }))
      );
    }
  }, [applyingForTuitionType]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isUserAuthenticated) {
        if (user.regd_as !== "STU") {
          NotificationBar(
            "Only users registered as students can apply for tuition",
            "info",
            4500
          );
          return;
        }

        let response = await sendReq(applicationDetails);
        if (response.status === 201) {
          NotificationBar(`${response.data.message}`, "success", 4000);
          return navigate("/student/request-status", { replace: true });
        }
      } else {
        dispatch(
          setRedirectPath({
            path: location.pathname,
            redirectionMode: "unauthenticated",
            search: location.search,
          })
        );
        NotificationBar(
          "You have to be logged in to be able to apply for tuition",
          "info",
          4500
        );
        navigate("/login");
      }
    } catch (error) {
      console.log(error.response);
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data.message !== "undefined") {
            NotificationBar(`${error.response.data.message}`, "error", 4000);
          } else {
            NotificationBar(`Hmm something went wrong`, "error", 4000);
          }
        } else {
          NotificationBar(
            "We are unable to process the request at the moment. Please try again later",
            "error",
            4000
          );
        }
      }
    }
  };

  const handleDisable = () => {
    const isFieldEmpty = Object.values(applicationDetails).some((item) => {
      return item === "";
    });

    return isFieldEmpty;
  };

  return (
    <FormCointainer $toggleDisplay={toggleFormDisplay}>
      <Header>
        <h3>Please fill the below fields to apply for tuition</h3>
        <CloseButton onClick={onClose} type="button">
          <i className="fas fa-times"></i>
        </CloseButton>
      </Header>
      <Form onSubmit={handleSubmit}>
        <InputFieldContainer>
          <Label htmlFor="applyingForTuitionType">Tuition Type</Label>
          <Select
            name="applyingForTuitionType"
            id="applyingForTuitionType"
            components={animatedComponents}
            value={
              applyingForTuitionType === ""
                ? applyingForTuitionType
                : {
                    value: applyingForTuitionType,
                    label: getTuitionTypeName(applyingForTuitionType),
                  }
            }
            options={tuitionTypeOptions}
            placeholder="Select tuition type"
            onChange={(selectedValue, event) =>
              handleSelectChange(selectedValue, event)
            }
            styles={customSelectStyles}
          />
        </InputFieldContainer>
        <InputFieldContainer>
          <Label htmlFor="applyingForStandard">Class</Label>
          <Select
            name="applyingForStandard"
            id="applyingForStandard"
            isSearchable={true}
            components={animatedComponents}
            options={standardOptions}
            value={
              applyingForStandard === ""
                ? applyingForStandard
                : { value: applyingForStandard, label: applyingForStandard }
            }
            onChange={(selectedValue, event) =>
              handleSelectChange(selectedValue, event)
            }
            placeholder="Select class"
            styles={customSelectStyles}
            isDisabled={!applyingForTuitionType}
          />
        </InputFieldContainer>
        <InputFieldContainer>
          <Label htmlFor="applyingForBoard">Board</Label>
          <Select
            name="applyingForBoard"
            id="applyingForBoard"
            isSearchable={true}
            components={animatedComponents}
            options={boardOptions}
            value={
              applyingForBoard === ""
                ? applyingForBoard
                : { value: applyingForBoard, label: applyingForBoard }
            }
            onChange={(selectedValue, event) =>
              handleSelectChange(selectedValue, event)
            }
            placeholder="Select board"
            styles={customSelectStyles}
          />
        </InputFieldContainer>
        <InputFieldContainer>
          <Label htmlFor="applyingForSubjects">Subject</Label>
          <Select
            name="applyingForSubjects"
            id="applyingForSubjects"
            isSearchable={true}
            isMulti={true}
            components={animatedComponents}
            options={subjectOptions}
            value={subjectValues}
            onChange={(selectedValue, event) =>
              handleSubjectMultiSelect(selectedValue, event)
            }
            placeholder="Select subjects"
            closeMenuOnSelect={false}
            styles={customSelectStyles}
          />
        </InputFieldContainer>

        <ButtonContainer>
          <SubmitButton disabled={handleDisable()} type="submit">
            Apply for Tuition
          </SubmitButton>
        </ButtonContainer>
      </Form>
    </FormCointainer>
  );
};

export default ApplicationForm;
