import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const fetchUserProfile = createAsyncThunk(
  "userInfo/fetchUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosInstance.get(`/account/get-user-info/`);

      return response.data;
    } catch (error) {
      if (error.response) {
        rejectWithValue(error.response);
      }
      rejectWithValue({
        data: { detail: "Unable to fetch user information", status: 500 },
      });
    }
  }
);

export const createTeacherProfile = createAsyncThunk(
  "userInfo/submitTeacherProfile",
  async ({ detailsData, photo, feeData }, { rejectWithValue }) => {
    let formData = new FormData();

    formData.append("name", detailsData.name);
    formData.append("profile_photo", photo);
    formData.append("adhaar_number", detailsData.adhar);
    formData.append("phone", detailsData.phone);
    formData.append("current_profession", detailsData.currentProfession);
    formData.append("location", detailsData.location);
    formData.append("state", detailsData.state);
    formData.append("full_address", detailsData.fullAddress);
    formData.append("date_of_birth", detailsData.dob);
    formData.append("name_of_school", detailsData.schooName);
    formData.append("experience", detailsData.experience);
    formData.append("teach_for_no_days", detailsData.teachForNoDays);
    formData.append("shift", detailsData.shift);
    formData.append("subject", detailsData.subject);
    formData.append("board", detailsData.board);
    formData.append("gender", detailsData.gender);
    formData.append("tuition_type", detailsData.tuitionType);
    formData.append(
      "general_tuition_classes",
      detailsData.generalTuitionClasses
    );
    formData.append("home_tuition_classes", detailsData.homeTuitionClasses);
    formData.append("feeData", JSON.stringify(feeData));

    try {
      let response = await axiosInstance.post(
        `/teacher/submit-detail/`,
        formData
      );

      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue("Unable to submit user profile");
    }
  }
);

export const updateTeacherProfile = createAsyncThunk(
  "userInfo/updateTeacherProfile",
  async (profileData, { rejectWithValue }) => {
    const { newDetailsData, feeData, rmvFeeDataIds } = profileData;

    let formData = new FormData();

    Object.keys(newDetailsData).forEach((key) => {
      formData.append(`${key}`, newDetailsData[key]);
    });

    formData.append("feeData", JSON.stringify(feeData));
    formData.append("rmvFeeDataIds", JSON.stringify(rmvFeeDataIds));

    try {
      let response = await axiosInstance.patch(
        `/teacher/edit-detail/`,
        formData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue("Unable to submit user profile");
    }
  }
);

export const createStudentProfile = createAsyncThunk(
  "userInfo/createStudentProfile",
  async (profileData, { rejectWithValue }) => {
    const { detailsData, photo } = profileData;

    let formData = new FormData();

    formData.append("name", detailsData.name);
    formData.append("date_of_birth", detailsData.dob);
    formData.append("student_phone", detailsData.studentPhone);
    formData.append("parent_phone", detailsData.parentPhone);
    formData.append("address", detailsData.address);
    formData.append("parent_name", detailsData.parentName);
    formData.append("standard", detailsData.standard);
    formData.append("school_name", detailsData.schoolName);
    formData.append("board", detailsData.board);
    formData.append("gender", detailsData.gender);
    formData.append("state", detailsData.state);
    formData.append("profile_photo", photo);

    try {
      let response = await axiosInstance.post(
        `/student/submit-detail/`,
        formData
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue("Unable to submit user profile");
    }
  }
);

export const updateStudentProfile = createAsyncThunk(
  "userInfo,/updateStudentProfile",
  async (profileData, { rejectWithValue }) => {
    let formData = new FormData();

    Object.keys(profileData).forEach((key) => {
      formData.append(`${key}`, profileData[key]);
    });

    try {
      let response = await axiosInstance.patch(
        `/student/edit-details/`,
        formData
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue("Unable to submit user profile");
    }
  }
);
