import { Content, ContentHeader } from "./t&cMainStyles";

const RightToStudentAceess = () => {
  return (
    <Content id="right_to_student_access">
      <ContentHeader>
        <p>2. Right to Student Access</p>
      </ContentHeader>
      <p>
        As a student, to access the Platform and use certain Services, you will
        be required to register and create an account on the Platform by
        providing the requested details.
      </p>
      <p>
        To register on the Schoolies Platform you must meet the ‘Age
        Requirements’ specified herein below. By using the Schoolies Platform,
        you, through your actions, affirm that the Age Requirements are met. You
        can register on the platform for free
      </p>
      <p>
        <span>
          Age Requirements to register and use on the Platform (“Age
          Requirements”)
        </span>
      </p>
      <p>
        If you are a resident of India or any other country then you must have
        attained at least 18 (eighteen) years of age to register. A student who
        does not fulfill the Age Requirements mentioned above and is desirous of
        registering on the Platform, that is, if a student is a “Minor”/
        “Child”, then the student may use the Platform with the consent of, and
        under the supervision of their parent or legal guardian (“Parent”).
        Accordingly, in such a case, the Parent must agree to the Platform Terms
        at the time of their registration on the Platform. Please note that
        minors/children are not by themselves eligible to register on the
        Platform. When a Minor/Child uses the Platform, we assume that the
        Parent of such Minor/Child has enabled the Minor/Child’s usage of the
        Platform by agreeing to the Platform Terms and conditions and that such
        usage is under the supervision of their Parent.
      </p>
      <p>
        Schoolies reserves the right to restrict your access to the Platform, if
        it is discovered that you do not meet the Age Requirements and/or the
        consent to register and use the Platform is not obtained as above.{" "}
      </p>
    </Content>
  );
};

export default RightToStudentAceess;
