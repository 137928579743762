import { Link } from "react-router-dom";
import { EmailShareButton, WhatsappShareButton } from "react-share";
import { staticUrl } from "../../utils/axiosInstance";
import {
  Col,
  Container,
  Developer,
  Foooter,
  Row,
  SocialLink,
} from "./footerStyles";

const Footer = () => {
  return (
    <Foooter>
      <Container>
        <Row>
          <Col>
            <img src={`${staticUrl}images/footer-logo.svg`} alt="footer logo" />
          </Col>
          <Col>
            <h4>Company</h4>
            <ul>
              <li>
                <Link to="/service">Our Services</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms And Conditions</Link>
              </li>
            </ul>
          </Col>

          <Col>
            <h4>Get Help</h4>
            <ul>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </Col>
          <Col>
            <h4>Share</h4>
            <SocialLink>
              <WhatsappShareButton url={"https://schoolies.in/"}>
                <i className="fab fa-whatsapp"></i>
              </WhatsappShareButton>
              <EmailShareButton url={"https://schoolies.in/"}>
                <i className="fas fa-envelope"></i>
              </EmailShareButton>
            </SocialLink>
          </Col>
        </Row>
      </Container>
      <Developer>
        <p>
          &copy; {new Date().getFullYear()}-{new Date().getFullYear() + 1}{" "}
          Schoolies | All Rights Reserved.
        </p>
        {/* <p>Developer - Ritankar Bhattacharjee</p> */}
      </Developer>
    </Foooter>
  );
};

export default Footer;
