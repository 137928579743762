import axiosInstance from "../utils/axiosInstance";

export const sendContactMessage = async (formData) => {
  let body = {
    name: formData.name,
    email: formData.email,
    phone: formData.phone,
    message: formData.message,
  };

  try {
    let response = await axiosInstance.post(`/contact/create-message/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
};
