import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { getLocation } from "../../api/locationApi";
import LocationAutocomplete from "../../components/Location Autocomplete/LocationAutocomplete";
import useChoices from "../../hooks/useChoices";
import useDebounce from "../../hooks/useDebounce";
import {
  InputContainer,
  SearchButton,
  SearchForm,
  SearchbarWrapper,
} from "./homeStyles";

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,

    height: "100%",
    border: "none",

    boxShadow: "none",
    outline: "none",
  }),
  indicatorSeparator: () => null,

  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%",
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: "nowrap", // Prevent wrapping of placeholder text
    overflow: "hidden",
    textOverflow: "ellipsis", // Add ellipsis for text that overflows
    fontSize: "0.9rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: "nowrap", // Prevent wrapping of selected value
    overflow: "hidden",
    textOverflow: "ellipsis", // Add ellipsis for text that overflows
  }),
  valueContaine: (provided) => ({
    ...provided,
  }),
  // ... other styles
};

const Searchbar = () => {
  const navigate = useNavigate();
  // animation for react select class

  const [searchQueries, setSearchQueries] = useState({
    location: "",
    subject: "",
    standard: "",
  });
  const [isLocationSelected, setIsLocationSelected] = useState(true);

  const [locationQuery, setLocationQuery] = useState("");

  const debouncedQuery = useDebounce(searchQueries.location, 300);

  const { subjectChoices, standardChoices } = useChoices();

  const fetchLocation = useQuery({
    queryKey: ["home/fetchLocation", locationQuery],
    queryFn: () => getLocation(locationQuery),
    retry: false,
    enabled: locationQuery !== "" && searchQueries.location !== "",
    refetchOnWindowFocus: false,
  });

  const handleLocationOnChange = (e) => {
    // THIS IS HANDLE LOCATION INPUT ON CHANGE

    const { value } = e.target;

    if (value === "") {
      setIsLocationSelected(true);
    } else {
      setIsLocationSelected(false);
    }
    setSearchQueries({
      ...searchQueries,
      location: value,
    });
  };

  const handleLocationSelectClick = (locationSuggestion) => {
    setSearchQueries({
      ...searchQueries,
      location: locationSuggestion,
    });

    setIsLocationSelected(true);
  };

  useEffect(() => {
    // THIS DEBOUNCES THE LOCATION QUERY
    if (debouncedQuery && !isLocationSelected) {
      setLocationQuery(debouncedQuery);
    }
  }, [debouncedQuery, isLocationSelected]);

  const handleSelectOnChange = (selectedValue, event) => {
    const { name } = event;

    setSearchQueries({
      ...searchQueries,
      [name]: selectedValue.value,
    });
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams({
      location: searchQueries.location,
      standard: searchQueries.standard,
      subject: searchQueries.subject,
    }).toString();

    navigate(`/search?${queryParams}`, { replace: true });
  };

  return (
    <SearchbarWrapper>
      <SearchForm onSubmit={handleSearchSubmit}>
        <InputContainer className="location">
          <label htmlFor="location">Location</label>
          <LocationAutocomplete
            name="location"
            id="location"
            placeholder="Search by Location"
            value={searchQueries.location}
            suggestions={fetchLocation.data?.data.predictions}
            onChange={handleLocationOnChange}
            onSuggestionClick={handleLocationSelectClick}
            isSuggestionSelected={isLocationSelected}
            fullHeight={true}
            required={true}
          />
        </InputContainer>
        <InputContainer className="subject">
          <label htmlFor="subject">Subject</label>
          <Select
            placeholder="Search by Subject"
            styles={customSelectStyles}
            name="subject"
            id="subject"
            isSearchable={true}
            closeMenuOnSelect={true}
            value={
              searchQueries.subject === ""
                ? searchQueries.subject
                : { value: searchQueries.subject, label: searchQueries.subject }
            }
            options={subjectChoices}
            onChange={(selectedValue, event) =>
              handleSelectOnChange(selectedValue, event)
            }
            required={true}
          />
        </InputContainer>

        <InputContainer className="standard">
          <label htmlFor="standard">Class</label>
          <Select
            name="standard"
            id="standard"
            placeholder="Search by Class"
            isSearchable={true}
            closeMenuOnSelect={true}
            value={
              searchQueries.standard === ""
                ? searchQueries.standard
                : {
                    value: searchQueries.standard,
                    label: searchQueries.standard,
                  }
            }
            options={standardChoices}
            onChange={(selectedValue, event) =>
              handleSelectOnChange(selectedValue, event)
            }
            styles={customSelectStyles}
            required={true}
          />
        </InputContainer>
        <InputContainer className="button">
          <SearchButton type="submit">
            Search <i className="fas fa-search"></i>
          </SearchButton>
        </InputContainer>
      </SearchForm>
    </SearchbarWrapper>
  );
};

export default Searchbar;
