import { useInfiniteQuery } from "@tanstack/react-query";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { searchTeachers } from "../../../api/searchApi";
import NoResultsFound from "../../../components/No Results Found/NoResultsFound";
import TeacherCards from "./TeacherCards";
import {
  ContentCardContainer,
  ContentWrapper,
  ResultInfo,
} from "./contentStyles";
import { useDispatch } from "react-redux";
import { setProgress } from "../../../redux/slices/loadingbarSlice";

const Content = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const locationParam = searchParams.get("location");
  const standardParam = searchParams.get("standard");
  const subjectParam = searchParams.get("subject");

  const teacherNameParam = searchParams.get("tch_name");

  const teacherSchoolNameParam = searchParams.get("tch_school_name");
  const tuitionTypeParam = searchParams.get("tuition_type");

  const isParams =
    locationParam !== null && standardParam !== null && subjectParam !== null;

  const {
    data: teacherData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [
      "query/fetchTeachers",
      locationParam,
      standardParam,
      subjectParam,
      teacherNameParam,
      teacherSchoolNameParam,
      tuitionTypeParam,
    ],
    queryFn: ({ pageParam = 1 }) =>
      searchTeachers({
        locationParam,
        standardParam,
        subjectParam,
        teacherNameParam,
        teacherSchoolNameParam,
        tuitionTypeParam,
        page: pageParam,
      }),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.current_page < lastPage.total_pages) {
        return lastPage.current_page + 1;
      } else {
        return undefined;
      }
    },
    refetchOnWindowFocus: false,
    enabled: isParams === true,
  });
  console.log("🚀 ~ Content ~ teacherData:", teacherData);

  const handleScroll = () => {
    const isAtBottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;
    if (isAtBottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    // detects the scroll event
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    if (isLoading) {
    } else {
      dispatch(setProgress(100));
    }
  }, [isLoading]);

  if (isLoading) {
    return <></>;
  }

  return (
    <ContentWrapper>
      <ResultInfo>
        {!teacherData?.pages[0].data.matches_found &&
        teacherData?.pages[0].data.count !== 0 ? (
          <p>
            No teachers found based on your search, but here are some
            suggestions
          </p>
        ) : (
          <p>
            Found {teacherData?.pages[0].data.count}{" "}
            {teacherData?.pages[0].data.count > 1 ? "results" : "result"}{" "}
            matching your search
          </p>
        )}
      </ResultInfo>
      {teacherData?.pages[0].data.count > 0 ? (
        <ContentCardContainer>
          {teacherData?.pages.map((page, index) => (
            <Fragment key={index}>
              {page.data.results.map((teacher) => (
                <TeacherCards key={teacher.id} data={teacher} />
              ))}
            </Fragment>
          ))}
        </ContentCardContainer>
      ) : (
        <NoResultsFound />
      )}
    </ContentWrapper>
  );
};

export default Content;
