import { Content, ContentHeader } from "./t&cMainStyles";

const FeePayment = () => {
  return (
    <Content id="fee-payment">
      <ContentHeader>
        <p>5. Fee FeePayment</p>
      </ContentHeader>
      <p>
        <span>
          Fee payment is only applicable for users who register as students.
        </span>
      </p>
      <p>
        Please read the below terms applicable for students for the payment of
        tuition fee of their respective teacher through Schoolies. The below
        terms are to be read with any other terms communicated to you at the
        time of payment of your tuition fee.
      </p>
      <ol type="a">
        <li>
          The student hereby agrees to remit the designated fee for the selected
          teacher(s) for the specified monthly period, in accordance with the
          due date as outlined on the payment summary card. This commitment
          forms part of the student's agreement upon registration and use of the
          'Schoolies' platform.
        </li>
        <li>
          Should the student not fulfill the fee payment by the stipulated due
          date, 'Schoolies' reserves the prerogative to levy a late fee against
          the student. The late fee amount shall be clearly delineated within
          the payment summary card. By proceeding with the use of 'Schoolies',
          the student acknowledges and consents to this term.
        </li>
        <li>
          In addition, the student agrees to pay a platform fee, which is
          incurred as a condition of utilizing the 'Schoolies' platform. This
          platform fee is essential for accessing the services provided by
          'Schoolies' and will be detailed within the payment summary card. The
          student's agreement to this fee underscores their acceptance of
          'Schoolies'' terms of use and service. section, until your account
          becomes current and paid in full.
        </li>
        <li>
          We retain the discretion to recover any outstanding fees through
          various collection strategies. These strategies may encompass, but are
          not limited to, the utilization of alternative payment methods
          previously authorized by you and/or engaging collection agencies or
          legal representation to facilitate the recovery of debts.
        </li>
        <li>
          FeePayments made by you, the student, to 'Schoolies' will be inclusive
          of all relevant taxes as mandated by law, which encompasses, but is
          not limited to, Goods and Services Tax (GST).
        </li>
        <li>
          We reserve the right to change/revise the pricing of platform fee.
        </li>
        <li>
          <p>
            Payments are processed exclusively through Razorpay, our chosen
            third-party payment gateway. Razorpay enables you to securely
            complete your service charge payments and may require your personal
            information to finalize transactions on our platform. In the process
            of making a payment, you may be redirected to an external site
            operated by Razorpay.
          </p>
          <p>
            Razorpay may not charge you fees to use or access their services and
            may require your Personal Information to complete any transaction
            for the Platform. Further, to facilitate completion of your payments
            to us through the Platform or avail the payment options provided to
            you, you may be redirected to an external website operated by
            Razorpay. We cannot and do not (i) guarantee the adequacy of the
            privacy and security practices employed by or the content and media
            provided by the Razorpay or its respective websites or (ii) control
            collection or use of your Personal Information by Razorpay. Hence,
            prior to using any services offered by Razorpay, we suggest that you
            read their terms and conditions, privacy policy and other policies,
            that may apply, to understand their terms of usage and to understand
            how your Personal Information is being processed. Schoolies is not
            affiliated to Razorpay and neither Schoolies nor Razorpay are agents
            or employees of the other. You, the student, agree that you are
            solely responsible for all charges that occur through Razorpay.
          </p>
          <p>
            Further, pursuant to the payment option you may choose, you may be
            required to enter into a separate agreement with Razorpay. This
            agreement with Razorpay is an independent contract/agreement between
            you and Razorpay and Schoolies shall in no manner be a party to the
            same. Schoolies is only facilitating various payment options to you
            and is not offering the payment by itself in any manner.
          </p>
        </li>
        <li>
          You agree that you are solely responsible for all charges that occur
          through such Razorpay and acknowledge and agree to indemnify, defend,
          and hold harmless Schoolies, its licensors, their affiliates, and
          their respective officers, directors, employees, and agents from any
          loss arising out of or related to the use of the Platform or any
          payments made through the Platform. This obligation will survive your
          use of the Platform and termination of your Agreement with us.
        </li>
        <li>
          Students are expressly prohibited from making payments directly to
          teachers outside of the 'Schoolies' platform. Any such external
          transactions are undertaken at your own risk. You agree to indemnify,
          defend, and hold harmless 'Schoolies', its licensors, their
          affiliates, and their respective officers, directors, employees, and
          agents from any loss arising out of or related to such unauthorized
          payments.
        </li>
        <li>
          For purposes of the Platform Terms, “Loss” means all losses,
          liabilities, damages, awards, settlements, claims, suits, proceedings,
          costs, and expenses (including reasonable legal fees and disbursements
          and costs of investigation, litigation, settlement, judgment,
          interest, and penalties). Schoolies shall not be liable to you for any
          claims arising out of any act or omission on the part of Razorpay
          including, but not limited to, any lost, stolen, or incorrectly
          processed payments. Schoolies expressly disclaims any responsibility
          and liability for all services provided by Razorpay.
        </li>
        <li>
          Further, Schoolies is solely authorized to offer discounts / offers,
          if any, on the paltform fee price. These discounts / offers are
          communicated on the Platform or via direct communication to you from
          Schoolies via email, SMS, phone, or such other means of communication,
          and can be availed only through the Platform, unless otherwise
          specifically communicated by Schoolies.
        </li>
        <li>
          Other than Schoolies, no person, including without limitation any
          third-party platform, are allowed to offer any discounts/ offers on
          the service fee prices offered on the Platform. Schoolies shall not be
          liable for any claims arising from such unauthorized discounts/ offers
          offered by any person (including any third-party platforms), other
          than Schoolies.
        </li>
      </ol>
    </Content>
  );
};

export default FeePayment;
