const SchooliesLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 50">
      <defs>
        <style>
          {
            ".cls-1{fill:#e8481d}.cls-2{fill:#1d1d1b;stroke:#1d1d1b;stroke-miterlimit:10;stroke-width:.75px}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <path
          d="M34.43 12a2 2 0 0 0-.12 3.61l11 5.79L50 24a.77.77 0 0 1 0 1.37l-3.85 1.91-5.89 2.65-5.53 2.8-5.76-3.27-15.58-8.13L7 18l1.72-.76a9.07 9.07 0 0 1 7.86.26l2.52 1.3L34 26.62a.75.75 0 0 0 .7 0l.8-.4a2.63 2.63 0 0 0 .07-4.69L25.2 16.1l-2.54-1.33a2.09 2.09 0 0 1 .13-3.77l1.45-.64 5.21-2.31 3.74-1.66a5.75 5.75 0 0 1 4.93.13l4.09 2.06 14.45 7.3 5.75 2.9-3.84 1.88a4.69 4.69 0 0 1-4.18 0l-3.66-1.85-13.26-6.71a3.56 3.56 0 0 0-3.04-.1Z"
          className="cls-1"
        />
        <path
          d="M19.36 27.13S32.92 35 34.7 34.88s10.29-4.2 16-7v6.32a6.77 6.77 0 0 1-1.21 3.95c-1.71 2.43-5.83 5.54-15.67 5.55a2.75 2.75 0 0 1-.41 0c-1.84-.19-14.72-1.81-14.33-9.49ZM55 25.74l2.13-1.01v11.96H55V25.74zM55.45 37.79h1.15a1.69 1.69 0 0 1 1.44.8l.57.91a1.36 1.36 0 0 1-.16 1.64l-1.61 1.74a1 1 0 0 1-1.5 0L53.62 41a1.14 1.14 0 0 1-.09-1.41l.86-1.24a1.29 1.29 0 0 1 1.06-.56Z"
          className="cls-1"
        />
      </g>
      <g id="Layer_3" data-name="Layer 3">
        <path
          d="M83 40.72a11.61 11.61 0 0 1-5.89-1.56 5.93 5.93 0 0 1-2.6-2.61v-.1a.2.2 0 0 1 0-.09.3.3 0 0 0 0-.1v-.06a1.24 1.24 0 0 1 0-.33.69.69 0 0 1 0-.25.52.52 0 0 1 .14-.19l.17-.16a1 1 0 0 1 .2.13l.12.11.09.09a.53.53 0 0 1 .17.24.43.43 0 0 0 .07.2 3.15 3.15 0 0 0 .65.85 6.31 6.31 0 0 0 1.71 1.21 11.66 11.66 0 0 0 4.2 1.21h.93a8.65 8.65 0 0 0 3.27-.63 7.31 7.31 0 0 0 2.09-1.48 9.72 9.72 0 0 0 .9-1.23 2.64 2.64 0 0 0 .44-1.45 2.71 2.71 0 0 0-.81-1.78 11.87 11.87 0 0 0-1.58-1.37c-.58-.43-1.19-.86-1.8-1.29h-.1A21.47 21.47 0 0 1 80 25.47a7.71 7.71 0 0 1-1.59-4.71 10.53 10.53 0 0 1 2.62-6.72 11.08 11.08 0 0 1 4.06-2.9 14.43 14.43 0 0 1 5.52-1A7.44 7.44 0 0 1 95 11.3a4.06 4.06 0 0 1 1.6 2.18 6.27 6.27 0 0 1 .06 2.52 14 14 0 0 1-1.37 4.44 30.21 30.21 0 0 1-1.65 3 1.76 1.76 0 0 1-1 .77l-.09-.07v-.26a1.71 1.71 0 0 1 .25-.88 25.82 25.82 0 0 0 1.37-3 12.63 12.63 0 0 0 1-4.5 3.22 3.22 0 0 0-1.35-2.91 5.62 5.62 0 0 0-2.82-.72h-1.09a11.6 11.6 0 0 0-4.2.94 9 9 0 0 0-3.9 3.28 8.8 8.8 0 0 0-1.53 4.72 6.24 6.24 0 0 0 1.21 3.63 20.8 20.8 0 0 0 4.94 4.31q.93.67 1.83 1.38 2.89 2.13 2.9 4.46a5.6 5.6 0 0 1-1.85 3.67l-.47.44A8.71 8.71 0 0 1 83 40.72ZM101.76 38.85a12 12 0 0 1-3-.39h-.07c-.23-.06-.46-.14-.69-.22h-.19l-.58-.24a4.43 4.43 0 0 1-2.43-2.28 5 5 0 0 1-.52-2.25 12.21 12.21 0 0 1 2.53-6.72 15.14 15.14 0 0 1 2.09-2.35 11.39 11.39 0 0 1 5.71-3.08 5.72 5.72 0 0 1 1.3-.13 6.48 6.48 0 0 1 2.11.4 5 5 0 0 1 2.48 1.95l-.07.07a7.68 7.68 0 0 0-3.39-.9 7.9 7.9 0 0 0-3.24.79 12.94 12.94 0 0 0-3.32 2.39 19.76 19.76 0 0 0-2.23 2.62 11.12 11.12 0 0 0-2 4.26 8.06 8.06 0 0 0-.16 1.61 3 3 0 0 0 1.55 2.81 9.61 9.61 0 0 0 1.23.5h.1a10.7 10.7 0 0 0 2.87.41 12.74 12.74 0 0 0 6.82-2.33 26.18 26.18 0 0 0 5.82-5.47.88.88 0 0 1 .49-.31h.07a.78.78 0 0 1 .21 0h.07a.54.54 0 0 1 .07.27v.11c0 .26-.53 1-1.6 2.2a19.81 19.81 0 0 1-7.35 5.38 12.57 12.57 0 0 1-4.68.9Z"
          className="cls-2"
        />
        <path
          d="M116.21 39h-.32a2.26 2.26 0 0 1-2-1.21 4.82 4.82 0 0 1-.6-2.28v-.82a20.3 20.3 0 0 1 .21-2.41 38.54 38.54 0 0 1 1.25-5.12 68.19 68.19 0 0 1 4.71-11.67 37.2 37.2 0 0 1 3.39-5.47 10.17 10.17 0 0 1 .76-.86 1.09 1.09 0 0 1 .82-.31h.25a.22.22 0 0 0 .12 0l.2.15.12.09V9.91a.72.72 0 0 1-.13.44 21.7 21.7 0 0 0-1.63 2.48q-1.29 2.37-2.78 5.45-1.09 2.27-2.48 5.62a53.56 53.56 0 0 0-2.6 7.72 20 20 0 0 0-.65 4.17 4.62 4.62 0 0 0 .18 1.45 1.3 1.3 0 0 0 1.12.86.71.71 0 0 0 .46-.24 8.42 8.42 0 0 0 1.56-2c.59-1 1.29-2.29 2.11-3.87q1.74-3.41 3-5.45 3.1-5.3 6.26-5.3a3.39 3.39 0 0 1 2.11.62 3.31 3.31 0 0 1 1.14 2.74 19 19 0 0 1-1.16 5.52c-.36 1.08-.69 2.18-1 3.27a12.33 12.33 0 0 0-.44 2.62 2.56 2.56 0 0 0 .23 1.19c.33.7 1.09 1 2.3 1a4 4 0 0 0 1.23-.2 6.23 6.23 0 0 0 1.58-.68 21.85 21.85 0 0 0 6.05-6.28l.88-1.26a.31.31 0 0 1 .16 0h.07a1.59 1.59 0 0 1 .4.15l.09.13v.2a13.08 13.08 0 0 1-.84 1.34A21.44 21.44 0 0 1 136 38a7.9 7.9 0 0 1-2.72 1 6.33 6.33 0 0 1-.76.05 6.5 6.5 0 0 1-1.28-.14 3.13 3.13 0 0 1-1.83-1.14 4.72 4.72 0 0 1-.77-2.77 10.94 10.94 0 0 1 .19-2 42.23 42.23 0 0 1 1.23-4.9l.53-1.89a.08.08 0 0 1 0-.06 11.82 11.82 0 0 0 .45-2.64c0-.82-.37-1.28-1.09-1.36a3.13 3.13 0 0 0-.58-.05 3.62 3.62 0 0 0-2.09.79 17.79 17.79 0 0 0-4.28 5.5q-1 1.7-2.46 4.68-1.32 2.58-1.65 3.15a10.09 10.09 0 0 1-1.62 2.28 1.82 1.82 0 0 1-1.06.5Z"
          className="cls-2"
        />
        <path
          d="M148.08 39a6.41 6.41 0 0 1-4.57-1.76 7.76 7.76 0 0 1-1.62-2.24 6 6 0 0 1-.58-2.71 8.51 8.51 0 0 1 .79-3.51 12.82 12.82 0 0 1 3.2-4.25 13.38 13.38 0 0 1 3-2 10.86 10.86 0 0 1 5-1.23 8.44 8.44 0 0 1 3.94 1 7.89 7.89 0 0 1 2.62 2.26l-.07.07a16.6 16.6 0 0 0-1.46-.84 8.94 8.94 0 0 0-4-1 10.35 10.35 0 0 0-3.64.71 13 13 0 0 0-6 4.74 10.4 10.4 0 0 0-1.25 2.71 6.67 6.67 0 0 0-.21 1.65 4.86 4.86 0 0 0 .68 2.46 4.92 4.92 0 0 0 4.61 2.66 9.93 9.93 0 0 0 3.41-.66 16.53 16.53 0 0 0 5.5-3.43 3.72 3.72 0 0 1-1.6-3.41 5.87 5.87 0 0 1 1.32-3.73 3.29 3.29 0 0 1 2.74-1.49 4 4 0 0 1 .83.7 3.28 3.28 0 0 1 .81 2.18 5.51 5.51 0 0 1-.48 2.13 10.9 10.9 0 0 1-2 3.1 5.65 5.65 0 0 0 1.72.26 7.28 7.28 0 0 0 4.66-2 11.22 11.22 0 0 0 1.3-1.37 1 1 0 0 1 .72-.28h.07a1.56 1.56 0 0 1 .07.37c0 .34-.5 1-1.51 1.94a7.83 7.83 0 0 1-5.27 2.31 7.6 7.6 0 0 1-2.55-.44l-.21.19a17.66 17.66 0 0 1-5.72 3.91 10.46 10.46 0 0 1-4.25 1Zm10-6.18a11.68 11.68 0 0 0 2.14-3.1 4.36 4.36 0 0 0 .39-1.73 2.45 2.45 0 0 0-1-2.07 2.56 2.56 0 0 0-1.79 1 5 5 0 0 0-1.06 3.12 2.94 2.94 0 0 0 1.18 2.66Z"
          className="cls-2"
        />
        <path
          d="M172.69 39a6.41 6.41 0 0 1-4.57-1.76A7.76 7.76 0 0 1 166.5 35a6 6 0 0 1-.58-2.71 8.51 8.51 0 0 1 .79-3.51 12.82 12.82 0 0 1 3.2-4.25 13.38 13.38 0 0 1 3-2 10.86 10.86 0 0 1 5-1.23 8.44 8.44 0 0 1 3.94 1 7.89 7.89 0 0 1 2.62 2.26l-.07.07a16.6 16.6 0 0 0-1.46-.84 8.94 8.94 0 0 0-4-1 10.35 10.35 0 0 0-3.64.71 13 13 0 0 0-6 4.74 10.4 10.4 0 0 0-1.3 2.64 6.67 6.67 0 0 0-.21 1.65 4.86 4.86 0 0 0 .68 2.46 4.92 4.92 0 0 0 4.61 2.66 9.93 9.93 0 0 0 3.41-.66 16.53 16.53 0 0 0 5.5-3.43 3.72 3.72 0 0 1-1.6-3.41 5.87 5.87 0 0 1 1.32-3.73A3.29 3.29 0 0 1 184.5 25a4 4 0 0 1 .83.7 3.28 3.28 0 0 1 .81 2.18 5.51 5.51 0 0 1-.48 2.13 10.9 10.9 0 0 1-2 3.1 5.64 5.64 0 0 0 1.71.26 7.29 7.29 0 0 0 4.67-2 11.22 11.22 0 0 0 1.3-1.37 1 1 0 0 1 .72-.28h.07a1.56 1.56 0 0 1 .07.37c0 .34-.51 1-1.51 1.94a7.83 7.83 0 0 1-5.27 2.31 7.6 7.6 0 0 1-2.55-.44l-.21.19a17.66 17.66 0 0 1-5.72 3.91 10.46 10.46 0 0 1-4.25 1Zm10-6.18a11.63 11.63 0 0 0 2.13-3.1 4.21 4.21 0 0 0 .4-1.73 2.45 2.45 0 0 0-1-2.07 2.56 2.56 0 0 0-1.79 1 5 5 0 0 0-1.06 3.12 2.94 2.94 0 0 0 1.18 2.66Z"
          className="cls-2"
        />
        <path
          d="M193.61 39a3 3 0 0 1-1.46-.38 4 4 0 0 1-1.4-1.6 4.18 4.18 0 0 1-.41-1.71 24.6 24.6 0 0 1 .14-2.86c.09-.75.27-1.7.53-2.86a56.34 56.34 0 0 1 4.09-10.9 54 54 0 0 1 4.29-7.6 14.82 14.82 0 0 1 1.1-1.51 1.63 1.63 0 0 1 .71-.4l.27-.07h.4l.14.29v.13a1.35 1.35 0 0 1 .07.44 1.54 1.54 0 0 1-.18.79l-.65 1.3q-1 2.07-2.6 5.1a95.23 95.23 0 0 0-4.85 10.26 32.69 32.69 0 0 0-1.71 5.3c-.1.42-.2 1.09-.33 2a5.18 5.18 0 0 1 0 .7v.44a2.35 2.35 0 0 0 .7 1.78 1.73 1.73 0 0 0 1.25.5 4.93 4.93 0 0 0 2.85-1.31 17.4 17.4 0 0 0 2.46-2.6c.42-.49 1-1.25 1.74-2.26a19.35 19.35 0 0 1 1.52-2 1.32 1.32 0 0 1 .45-.16l.1.05a1.48 1.48 0 0 1 .07.37.78.78 0 0 1-.1.44c-.24.44-.65 1.06-1.2 1.85a21.62 21.62 0 0 1-5.11 5.38 5 5 0 0 1-2.88 1.1Z"
          className="cls-2"
        />
        <path
          d="M204.31 39h-.21a2.52 2.52 0 0 1-1-.18 2.38 2.38 0 0 1-1-.66 4.18 4.18 0 0 1-1.27-3.16 14.34 14.34 0 0 1 .53-3.12 35.09 35.09 0 0 1 2.2-5.74 22.7 22.7 0 0 1 2.62-4.37 1 1 0 0 1 .82-.44 3.62 3.62 0 0 1 .53 0h.07l.14.19.06.12s0 .07.08.11a1.27 1.27 0 0 1 .14.53 6.48 6.48 0 0 1-.44 1.27 33.29 33.29 0 0 1-1.58 3.24 2.22 2.22 0 0 1-.21.37 51 51 0 0 0-3.31 7.1 5.85 5.85 0 0 0-.31 1.52 2.6 2.6 0 0 0 .58 1.73 1.76 1.76 0 0 0 1.4.68 2.58 2.58 0 0 0 1-.19 6.57 6.57 0 0 0 2.32-1.72 28 28 0 0 0 2.39-3.18l1.14-1.83.47-.83a1.19 1.19 0 0 1 .8-.6l.07.05a.83.83 0 0 1 .14.48 11.71 11.71 0 0 1-1.76 3.23 15.92 15.92 0 0 1-4.27 4.59 4.74 4.74 0 0 1-2.14.81Zm4.63-20c-.57-.19-.88-.42-.92-.71s-.05-.49-.05-.6a2.86 2.86 0 0 1 .65-1.67 1.07 1.07 0 0 1 .85-.57 1.14 1.14 0 0 1 .72.26 1.06 1.06 0 0 1 .37.39 3.23 3.23 0 0 1 .07.66 2 2 0 0 1-.44 1.31 2.76 2.76 0 0 1-1 .83l-.19.08Z"
          className="cls-2"
        />
        <path
          d="M218 38.9a13 13 0 0 1-3-.36 9.51 9.51 0 0 1-2.27-.78 4.24 4.24 0 0 1-1.73-1.8 5.13 5.13 0 0 1-.63-2.47 9.58 9.58 0 0 1 .39-2.59l.33-.9a12.26 12.26 0 0 1 .83-1.74 18.36 18.36 0 0 1 2-2.74 14 14 0 0 1 5.64-4 7.78 7.78 0 0 1 2.87-.55 6.88 6.88 0 0 1 4.25 1.49 1 1 0 0 0 .23.18 1.31 1.31 0 0 0 .28.24 2.06 2.06 0 0 1 .81 1.62 2.47 2.47 0 0 1-.76 1.76 9.59 9.59 0 0 1-2.83 2.1 21.7 21.7 0 0 1-4.26 1.64 28.17 28.17 0 0 1-7.26 1 9.76 9.76 0 0 0-.68 2.68 7.75 7.75 0 0 0 0 .88 3.11 3.11 0 0 0 1.25 2.24 3.38 3.38 0 0 0 1 .53l.44.17a9.31 9.31 0 0 0 3.13.57 6.54 6.54 0 0 0 .86 0 9.58 9.58 0 0 0 2.6-.48 15.08 15.08 0 0 0 3.71-1.91 25.6 25.6 0 0 0 4.57-4.31c.49-.62.81-1 .95-1.23a.53.53 0 0 1 .51-.24l.21.06a.76.76 0 0 1 .16.4 7.84 7.84 0 0 1-1 1.58 23.4 23.4 0 0 1-3 3.14 16.69 16.69 0 0 1-5.8 3.28 12.65 12.65 0 0 1-3.8.54Zm-4.62-9a26 26 0 0 0 8.28-2 13 13 0 0 0 3.53-2c.68-.55 1-1 1-1.4a.36.36 0 0 0-.1-.25l-.05-.07a.17.17 0 0 0-.08-.07s-.06 0-.07 0l-.09-.1a.38.38 0 0 0-.17-.11 2.45 2.45 0 0 0-.9-.59 4.57 4.57 0 0 0-2.21-.53 7.2 7.2 0 0 0-4.06 1.36 15.11 15.11 0 0 0-3 2.64 16.87 16.87 0 0 0-2.16 3.14Z"
          className="cls-2"
        />
        <path
          d="M232.88 38.79a5.59 5.59 0 0 1-1.46-.2 4.78 4.78 0 0 1-2.92-2.13 3.29 3.29 0 0 1-.44-1.65 4.41 4.41 0 0 1 1.6-3.21.6.6 0 0 1 .41-.17 1.15 1.15 0 0 1 .31 0 .65.65 0 0 1 .09.37.42.42 0 0 1-.12.33A4.78 4.78 0 0 0 229 35a2.15 2.15 0 0 0 .91 1.71 4.17 4.17 0 0 0 2.76 1 2.68 2.68 0 0 0 1.71-.48 1.55 1.55 0 0 0 .56-1L235 36v-.31a.06.06 0 0 0 0-.06 11.67 11.67 0 0 0-1.69-3.39c-.56-.94-.92-1.57-1.09-1.89a16.39 16.39 0 0 1-1.32-3.14 6.35 6.35 0 0 1-.26-1.82 4 4 0 0 1 .49-1.94 4 4 0 0 1 2-1.81 7.74 7.74 0 0 1 3.09-.58 10.26 10.26 0 0 1 2.81.37v.09l-2.18.55a12.54 12.54 0 0 0-2.46.85 2.51 2.51 0 0 0-1.74 2.46 8.37 8.37 0 0 0 .85 3.23c.28.64.74 1.6 1.37 2.85a25.86 25.86 0 0 1 1.35 3.06 4.66 4.66 0 0 1 .21.92v.27a2.18 2.18 0 0 1-.12.72 3 3 0 0 1-1.17 1.69 3.82 3.82 0 0 1-2.26.67Z"
          className="cls-2"
        />
      </g>
    </svg>
  );
};

export default SchooliesLogo;
