import axiosInstance from "../utils/axiosInstance";

// API TO SUBMIT DETAILS
export const submitStudentDetail = async (detailsData, photo) => {
  let formData = new FormData();

  formData.append("name", detailsData.name);
  formData.append("date_of_birth", detailsData.dob);
  formData.append("phone", detailsData.phone);
  formData.append("address", detailsData.address);
  formData.append("parent_name", detailsData.parentName);
  formData.append("standard", detailsData.standard);
  formData.append("school_name", detailsData.schoolName);
  formData.append("board", detailsData.board);
  formData.append("gender", detailsData.gender);
  formData.append("state", detailsData.state);
  formData.append("profile_photo", photo);

  try {
    let response = await axiosInstance.post(
      `/student/submit-detail/`,
      formData
    );
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

export const editDetails = async (detailsData, photo) => {
  let formData = new FormData();

  formData.append("name", detailsData.name);
  formData.append("date_of_birth", detailsData.date_of_birth);
  formData.append("phone", detailsData.phone);
  formData.append("address", detailsData.address);
  formData.append("parent_name", detailsData.parent_name);
  formData.append("standard", detailsData.standard);
  formData.append("school_name", detailsData.school_name);
  formData.append("board", detailsData.board);
  formData.append("gender", detailsData.gender);
  formData.append("state", detailsData.state);
  if (typeof photo !== "string") {
    formData.append("profile_photo", photo);
  }

  try {
    let response = await axiosInstance.patch(
      `/student/edit-details/`,
      formData
    );
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

// FUNCTION TO GET THE TEACHERS' INITIAL DETAIL
export const getMyTeachers = async () => {
  try {
    let response = await axiosInstance.get("/student/get-teacher/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getEditIfoDetails = async () => {
  try {
    let response = await axiosInstance.get(`student/get-edit-info-details/`);
    return response;
  } catch (error) {
    throw error;
  }
};
