import { Content, ContentHeader } from "./privacyStyles";

const UseOfPlatformByChild = () => {
  return (
    <Content id="use_of_platform_by_children">
      <ContentHeader>
        <p>7. Use of Platform/ Services by Children</p>
      </ContentHeader>
      <p>
        As stated in our Terms and conditions, to register on the Platform you
        must meet the ‘Age Requirements’ (as defined in our Terms and
        conditions). If you are a <span>“Minor”</span> or <span>“Child”</span>,
        that is, an individual who does not meet the Age Requirements, then you
        may not register on the Platform, and only your Parent (defined below)
        can register on the Platform on your behalf, agree to all Platform Terms
        and enable access to you under their guidance and supervision.
      </p>
      <p>
        Schoolies also ask Child’s Personal Information during the time of
        registration as a student, we do not knowingly collect such Personal
        Information from a Child and assume that information has been provided
        with consent of the Parent. If you are a Parent and you are aware that
        your Child has provided us with any Personal Information without your
        consent, please write to us (contact us). If we become aware that we
        have collected Personal Information from a Child in the absence of
        consent of the Parent, we will take steps to remove such information
        from our servers.
      </p>
      <p>
        Schoolies is a platform which acts independently. It is independent from
        teachers, students and third parties. While availing our Services If
        your Child faces any form of abuse or harassment from the teachers
        clearly Schoolies is not responsible for this but you must write to us
        at(contact us), so that necessary actions could be taken against them.
      </p>
      <p>
        Schoolies is a platform which acts independently. It is independent from
        teachers, students and third parties. While availing our Services If
        your Child faces any form of abuse or harassment from the teachers
        clearly Schoolies is not responsible for this but you must write to us
        at(contact us), so that necessary actions could be taken against them.
      </p>
    </Content>
  );
};

export default UseOfPlatformByChild;
