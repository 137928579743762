import styled from "styled-components";

const BasicBtn = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  /* border-radius: 0.9375em; */
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  text-align: center;
  gap: 0.6em;
  justify-content: space-between;
  font-size: 1rem;
  /* font-weight: var(--fw-bold); */

  outline: none;
  padding: 0.7em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const SlateButton = styled(BasicBtn)`
  border: 0.06em solid #000;
  color: #fff;
  background-color: #1a1a1a;
  &:hover {
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
`;

export const OutlineButton = styled(BasicBtn)`
  border: 0.06em solid #ccc;

  @media screen and (min-width: 50em) {
    &:hover {
      color: #fff;
      background-color: #1a1a1a;
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
    }
  }

  ${({ $active }) =>
    $active &&
    `
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(0);
    background-color: #1a1a1a;
  `}
`;

export const GhostButton = styled(BasicBtn)`
  border: none;
`;
