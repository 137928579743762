import axiosInstance from "../utils/axiosInstance";

// this is for loging a user in
export const userLogin = async ({ email, password }) => {
  const body = { email, password };

  try {
    const response = await axiosInstance.post(`/account/token/`, body);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAuthUserInfo = async () => {
  try {
    let response = await axiosInstance.get(`/account/get-user-info/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProfileStatus = async () => {
  try {
    let response = await axiosInstance.get(
      `/account/check-details-availability/`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const changeNewPassword = async (newPassword) => {
  let body = {
    new_password: newPassword,
  };

  try {
    let response = await axiosInstance.post(`/account/new-password/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
};

export const deleteUserAccount = async () => {
  try {
    let response = await axiosInstance.delete(`/account/delete-user-account/`);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
};
