import { Content, ContentHeader } from "./privacyStyles";

const HowLongInfoStored = () => {
  return (
    <Content id="how_long">
      <ContentHeader>
        <p>5. How Long Your Information can be Stored</p>
      </ContentHeader>
      <p>
        Mostly, when you delete any of the information provided by you or when
        you delete your account, on the Platform, the same will be deleted from
        our servers/ databases too.
      </p>
    </Content>
  );
};

export default HowLongInfoStored;
