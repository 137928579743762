import { useState, Suspense, lazy } from "react";
import LoadingBar from "react-top-loading-bar";
import { sendContactMessage } from "../../../api/contactApi";

import { NotificationBar } from "../../../components/notificationBar";
import { scroll_to_top } from "../../../utils/scrollActions";
import { Container, Wrapper } from "./contactformStyles";
import FormFragments from "./FormFragments";
const SuccessMessage = lazy(() => import("./SuccessMessage"));

const ContactForm = () => {
  //state to control Loading
  const [progress, setProgress] = useState(0);

  // STATE TO CONTROL SENT MESSAGE
  const [messageSent, setMessageSent] = useState(false);

  // STATE TO STORE THE FORM DATA
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // FUCNTION TO HANDLE DISABILITY OF THE SEND BUTTON
  const disableSendBtn = () => {
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.message === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  // ON CHANGE FUCNTION FOR TEXTS
  const changeFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // ON CHANGE FUNCTION FOR NUMBERS
  const onlyNumber = (e) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // FUNCTION TO SUBMIT THE MESSAGE
  const submitFormData = async (e) => {
    e.preventDefault();
    setProgress(20);
    try {
      setProgress(40);
      let response = await sendContactMessage(formData);
      setProgress(90);
      if (response.status === 201) {
        setProgress(100);
        scroll_to_top();
        return setMessageSent(true);
      } else {
        scroll_to_top();

        NotificationBar(
          "We are unable to send your message. Please try contacting us later.",
          "error"
        );
        return setMessageSent(false);
      }
    } catch (error) {
      setProgress(100);
      scroll_to_top();
      NotificationBar(
        "We are facing some server issues. Please trying contacting us later",
        "error"
      );
      return setMessageSent(false);
    }
  };

  return (
    <Wrapper>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Container>
        {!messageSent ? (
          <FormFragments
            formData={formData}
            disableSendBtn={disableSendBtn}
            changeFormData={changeFormData}
            onlyNumber={onlyNumber}
            submitFormData={submitFormData}
          />
        ) : (
          <Suspense fallback={<div>Loading...</div>}>
            <SuccessMessage />
          </Suspense>
        )}
      </Container>
    </Wrapper>
  );
};

export default ContactForm;
