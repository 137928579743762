import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { mailOnLogin } from "../../api/accountActivateApi";
import { userLoginAction } from "../../redux/actions/authAction";
import { staticUrl } from "../../utils/axiosInstance";

import { jwtDecode } from "jwt-decode";
import { NotificationBar } from "../../components/notificationBar";
import { setProgress } from "../../redux/slices/loadingbarSlice";
import { setMetaData } from "../../redux/slices/metaDataSlice";
import {
  clearRedirectPath,
  getRedirectPath,
  getRedirectState,
} from "../../redux/slices/redirectSlice";
import { scroll_to_top } from "../../utils/scrollActions";
import {
  Container,
  ForgotPasswordContainer,
  FormMainContainer,
  LoginBtn,
  Logo,
  MemberContainer,
  WelcomeContainer,
} from "./loginStyles";

const Login = () => {
  //dispatch action
  const dispatch = useDispatch();

  // initialising navigate for redirection
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const redirectUrlParam = searchParams.get("next");

  const searchUrlParam = searchParams.get("search");

  const redirectPath = useSelector(getRedirectPath);

  const { path, redirectionMode, search } = useSelector(getRedirectState);

  //for storing the form data
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // distructring formData for using in value in the form
  const { email, password } = formData;

  // function to handle disability of login button
  const disableLoginBtn = () => {
    if (email === "" || password === "") {
      return true;
    } else {
      return false;
    }
  };

  // on change function to change the state the of formData
  const changeData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendAccountActivationMail = async (email) => {
    try {
      await mailOnLogin(email);
    } catch (error) {
      NotificationBar(
        "We are unable to send you an account activation email for the time being. Please try logging in later",
        "error",
        4000
      );
    }
  };

  useEffect(() => {
    if (path !== null && redirectionMode === "unauthenticated") {
      const queryParams = new URLSearchParams({
        next: path,
        search: search,
      }).toString();

      setSearchParams(queryParams, { replace: true });
    }
  }, [redirectPath]);

  // handeling data on submit
  const formDataSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(setProgress(70));

      let response = await dispatch(
        userLoginAction({ email, password })
      ).unwrap();

      const decoded_user = jwtDecode(response.access);
      if (redirectUrlParam !== null && redirectionMode === "unauthenticated") {
        navigate(`${redirectUrlParam}${searchUrlParam}`, {
          replace: true,
        });
        dispatch(clearRedirectPath());
      } else if (decoded_user.regd_as === "TCH") {
        navigate("/teacher/profile", {
          replace: true,
        });
      } else if (decoded_user.regd_as === "STU") {
        navigate("/student/profile", {
          replace: true,
        });
      } else if (decoded_user.is_superuser) {
        navigate("/superuser", { replace: true });
      } else {
        navigate("/login", { replace: true });
      }
    } catch (error) {
      if (error.status === 401) {
        if (error.detail === "The account is inactive") {
          NotificationBar(`${error.detail}`, "info", 4000);
          await sendAccountActivationMail(email);
          return navigate("/verify-email", { replace: true });
        } else {
          NotificationBar(`${error.detail}`, "error", 4000);
        }
      } else {
        NotificationBar(
          "We are unable to log you in for the time being. Please try again later",
          "error",
          4000
        );
      }
    } finally {
      dispatch(setProgress(100));
    }
  };

  // to bring the view on top
  useEffect(() => {
    scroll_to_top();
  }, []);

  useEffect(() => {
    dispatch(setMetaData({ title: "Login" }));
  }, []);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0 }}
    >
      <FormMainContainer>
        <Logo src={`${staticUrl}images/only-logo.svg`} alt="schoolies" />

        <WelcomeContainer>
          <p>Welcome Back!</p>
        </WelcomeContainer>
        <form onSubmit={formDataSubmit}>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => changeData(e)}
            autoComplete="on"
            required
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => changeData(e)}
            autoComplete="on"
            required
          />

          <LoginBtn type="submit" disabled={disableLoginBtn()}>
            Login
          </LoginBtn>
        </form>

        <ForgotPasswordContainer>
          <Link to="/forgot-password">Forgot your password ?</Link>
        </ForgotPasswordContainer>

        <MemberContainer>
          <p>Don't have an account?</p> <Link to="/sign-up">Sign up</Link>
        </MemberContainer>
      </FormMainContainer>
    </Container>
  );
};

export default Login;
