import React, { useState } from "react";
import { MobileHeaderContainer } from "./layoutStyles";
import CustomButton from "../../../components/Button/CustomButton";

const MobileHeader = ({
  setIsFilterMenuOpen,
  setIsBankOpen,
  setActiveBtn,
  activeBtn,
}) => {
  const handleFilterMenuToggle = (value) => {
    setActiveBtn(value);
    setIsFilterMenuOpen((curr) => !curr);
    setIsBankOpen(false);
  };
  const handleBankToggle = (value) => {
    setActiveBtn(value);
    setIsBankOpen((curr) => !curr);
    setIsFilterMenuOpen(false);
  };

  return (
    <MobileHeaderContainer>
      <CustomButton
        buttonLabel="Bank Info"
        icon={<i className="fas fa-info-circle"></i>}
        variant="outline"
        id="bank_info"
        currentActiveBtn={activeBtn}
        onClick={() => handleBankToggle("bank_info")}
      />
      <CustomButton
        buttonLabel="Filters"
        icon={<i className="fas fa-filter"></i>}
        variant="outline"
        id="filters"
        currentActiveBtn={activeBtn}
        onClick={() => handleFilterMenuToggle("filters")}
      />
    </MobileHeaderContainer>
  );
};

export default MobileHeader;
