import styled, { css } from "styled-components";

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  background-color: #ffffff;
  border-radius: 0.5rem;

  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
`;

export const Button = styled.div`
  border: 1px solid #bdbdbd;
  display: flex;
  align-items: center;

  width: ${({ buttonWidth }) =>
    buttonWidth === "100%" ? "100%" : `${buttonWidth}rem`};
  gap: 1rem;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      box-shadow: 0 0 10px rgba(33, 150, 243, 0.8); /* Adjust the color and size as needed */
    `}
`;

export const ArrowIcon = styled.div`
  margin-left: auto;
  transform: ${({ $isOpen }) => ($isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
`;

export const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: ${({ buttonWidth }) =>
    buttonWidth === "100%" ? "100%" : `${buttonWidth}rem`};
  list-style: none;
  border: 1px solid #bdbdbd;
  background: #fff;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  z-index: 1;
  max-height: 10rem;
  overflow: hidden;
  overflow-y: auto;
`;

export const Option = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;
