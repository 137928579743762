import { Content, ContentHeader } from "./t&cMainStyles";

const PlatformAvailability = () => {
  return (
    <Content id="platform_availability">
      <ContentHeader>
        <p>9. Platform Availability</p>
      </ContentHeader>
      <p>
        Your access to the Platform may occasionally be suspended or restricted
        to allow for repairs, maintenance, or due to the introduction of new
        facilities or services at any time without prior notice. We will attempt
        to limit the frequency and duration of any such suspension or
        restriction.
      </p>
    </Content>
  );
};

export default PlatformAvailability;
