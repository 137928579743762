import { useLayoutEffect } from "react";

import TableContents from "./TableContents";

import { scroll_to_top } from "../../utils/scrollActions";
import AboutPlatformServices from "./AboutPlatformServices";
import AccountDeletion from "./AccountDeletion";
import ContactForUserSupport from "./ContactForUserSupport";
import FeePayment from "./FeePayment";
import PlatformAvailability from "./PlatformAvailability";
import ProcessingOfPersonalInfo from "./ProcessingOfPersonalInfo";
import RefundPolicy from "./RefundPolicy";
import RightToStudentAceess from "./RightToStudentAceess";
import RightToTeacherAccess from "./RightToTeacherAccess";
import SchooliesRights from "./SchooliesRights";
import Security from "./Security";
import TandC from "./TandC";
import { MainContentContainer, MainHeading, Wrapper } from "./t&cMainStyles";

const TermsAndConditions = () => {
  useLayoutEffect(() => {
    scroll_to_top();
  }, []);

  return (
    <Wrapper>
      <MainContentContainer>
        <MainHeading>
          <p>Schoolies - Terms and Conditions</p>
        </MainHeading>
        <TableContents />
        <TandC />
        <AboutPlatformServices />
        <RightToStudentAceess />
        <RightToTeacherAccess />
        <Security />
        <FeePayment />
        <RefundPolicy />
        <ProcessingOfPersonalInfo />
        <SchooliesRights />
        <PlatformAvailability />
        <AccountDeletion />
        <ContactForUserSupport />
      </MainContentContainer>
    </Wrapper>
  );
};

export default TermsAndConditions;
