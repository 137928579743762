import styled from "styled-components";

export const HeaderContainer = styled.div`
  /* outline: auto; */
  display: none;
  justify-content: flex-end;

  @media screen and (max-width: 57em) {
    display: flex;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--fw-bold);
  /* min-height: 3.75em; */
  min-width: 0;
  outline: none;
  padding: 0.5em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:hover {
    color: #fff;
    /* background-color: #1a1a1a; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #bdc3c7; /* Disabled button color */
    border: 0.06em solid #bdc3c7;
    color: #7f8c8d;
  }
`;

export const ApplyButton = styled(Button)`
  border: 0.06em solid #1b4dff;
  color: #fff;
  background-color: #1b4dff;
`;
