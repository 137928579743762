import styled from "styled-components";

export const Container = styled.main`
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4.375rem);
  overflow-x: hidden;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: "Poppins", sans-serif;

  p {
    font-size: 3rem;
    text-align: center;
  }

  @media (max-width: 50em) {
    min-height: calc(92vh - 4.375rem);
    p {
      font-size: 1.5rem;
    }
  }
`;

export const VerifyBtn = styled.button`
  font-size: 2rem;
  font-weight: 600;
  padding: 0.8rem 3rem;
  letter-spacing: 0.2rem;
  border: none;
  outline: none;
  background: rgb(0, 100, 241);
  color: #fff;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background: rgb(0, 64, 154);
  }

  &:disabled {
    background: rgb(138, 187, 255);
    cursor: not-allowed;
  }

  @media (max-width: 50em) {
    font-size: 1rem;
  }
`;
