import { configureStore } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import userInfoReducer from "../slices/userInfoSlice";
import optionsReducer from "../slices/optionsSlice";
import redirectReducer from "../slices/redirectSlice";
import loadingbarReducer from "../slices/loadingbarSlice";
import metadataReducer from "../slices/metaDataSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    userInfo: userInfoReducer,
    options: optionsReducer,
    redirect: redirectReducer,
    loadingbar: loadingbarReducer,
    metadata: metadataReducer,
  },
  devTools: true,
});
