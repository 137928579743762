import axiosInstance from "../utils/axiosInstance";

export const getLocation = async (locationQuery) => {
  try {
    let response = await axiosInstance.get(
      `/choicemanager/places-autocomplete/`,
      {
        params: {
          location_query: locationQuery,
        },
      }
    );

    return response;
  } catch (error) {
    console.log(error.response);
    throw error;
  }
};
