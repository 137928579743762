import {
  PhyContactContainer,
  PhyContactHeading,
  PhyContactInformation,
  PhyInformationContainer,
} from "./contactStyles";

const PhysicalContact = () => {
  return (
    <PhyContactContainer>
      <PhyContactHeading>
        You may also contact us using the information below
      </PhyContactHeading>
      <PhyInformationContainer>
        <PhyContactInformation>
          <span>Phone/ WhatsApp:</span> +91 60094 59385
        </PhyContactInformation>
        <PhyContactInformation>
          <span>Email:</span> support@schoolies.in
        </PhyContactInformation>
        <PhyContactInformation>
          <span>Address:</span> Kripamaya Kutir, Upland Road, Shillong,
          Meghalaya-793003
        </PhyContactInformation>
      </PhyInformationContainer>
    </PhyContactContainer>
  );
};

export default PhysicalContact;
