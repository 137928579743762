import { useLayoutEffect, useState } from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import PersonalInfo from "./PersonalInfo";

import {
  BtnContainer,
  Container,
  InformationContainer,
  NextBtn,
  PrevBtn,
  TitleHeader,
} from "./teacherProfileStyles";
import TuitionAndFeesInfo from "./TuitionAndFeesInfo";

import { getUserProfile } from "../../../redux/slices/userInfoSlice";

const TeacherProfile = () => {
  const profile = useSelector(getUserProfile);

  // state for storing which page to display
  const [page, setPage] = useState(0);

  // state to store the page heading
  const heading = ["Personal Information", "Tuition and Fees Information"];

  // ONCLICK FOR NEXT BUTTON
  const nextPage = () => {
    setPage((currPage) => currPage + 1);
  };
  // ONCLICK FOR PREVIOUS BUTTON
  const prevPage = () => {
    setPage((currPage) => currPage - 1);
  };

  const disableNextPage = () => {
    if (page === 1) {
      return true;
    }
    return false;
  };
  const disablePreviousPage = () => {
    if (page === 0) {
      return true;
    }
    return false;
  };

  const informationPage = () => {
    if (page === 0) {
      return <PersonalInfo profile={profile} />;
    }

    if (page === 1) {
      return <TuitionAndFeesInfo profile={profile} />;
    }
  };
  // for scrolling on top
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Container>
      <Helmet>
        <title>{profile.name ? "Profile - " + profile.name : "Profile"}</title>
      </Helmet>
      <TitleHeader>
        <p>{heading[page]}</p>
      </TitleHeader>
      <InformationContainer>{informationPage()}</InformationContainer>
      <BtnContainer>
        <PrevBtn onClick={prevPage} disabled={disablePreviousPage()}>
          <i className="fas fa-angle-double-left"></i> Previous
        </PrevBtn>
        <NextBtn onClick={nextPage} disabled={disableNextPage()}>
          Next <i className="fas fa-angle-double-right"></i>
        </NextBtn>
      </BtnContainer>
    </Container>
  );
};

export default TeacherProfile;
