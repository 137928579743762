import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  progress: 0,
};

const loadingbarSlice = createSlice({
  name: "loadingbar",
  initialState,
  reducers: {
    setProgress: (state, action) => {
      const { payload } = action;
      state.progress = payload;
    },
  },
});

const { reducer, actions } = loadingbarSlice;

export const { setProgress } = actions;

export const getProgress = (state) => state.loadingbar.progress;

export default reducer;
