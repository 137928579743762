import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import { verifyAccount, checkActivation } from "../../api/accountActivateApi";
import LoadingBar from "react-top-loading-bar";
import { jwtDecode } from "jwt-decode";
import { NotificationBar } from "../notificationBar";
import { VerifyBtn, Container } from "./aAstyles";
import { scroll_to_top } from "../../utils/scrollActions";

const AcitvateAccount = () => {
  // getting params from the link
  const { token } = useParams();

  // state to control activated
  const [verified, setVerfied] = useState(false);

  // for loading bar
  const [progress, setProgress] = useState(0);

  //initialising the navigate
  const navigate = useNavigate();

  //state to control the is verified
  const [isActive, setIsActive] = useState(false);

  //function to handle verify button click

  const sendToken = async () => {
    setProgress(20);
    let response = await verifyAccount(token);
    setProgress(90);
    if (response.status === 200) {
      setProgress(100);
      NotificationBar(
        "Your account has been created and verified successfully",
        "success"
      );
      setVerfied(true);
      return navigate("/login");
    } else if (response.status === 401) {
      if (response.data.message === "Activation link expired") {
        NotificationBar("The verification link has expired", "info");
        return navigate("/verify-email");
      }
    }
  };

  // to check if the user is already active
  useEffect(() => {
    scroll_to_top();
    const decode_id = jwtDecode(token);

    const getTheUser = async () => {
      try {
        let response = await checkActivation(decode_id.user_id);
        console.log(response);
        if (response.status === 200) {
          if (response.data.message === "active") {
            setIsActive(true);
          } else {
            setIsActive(false);
          }
        }
      } catch (error) {
        if (error.response) {
          console.log(error.response);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log(error.message);
        }
      }
    };

    getTheUser();
  }, []);

  return (
    <Container>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Helmet>
        <title>Schoolies - Activation</title>
      </Helmet>
      <p>Click on the button below to verify your account</p>
      <VerifyBtn
        disabled={isActive || verified ? true : false}
        onClick={sendToken}
      >
        {isActive ? "VERIFIED" : "VERIFY"}
      </VerifyBtn>
    </Container>
  );
};

export default AcitvateAccount;
