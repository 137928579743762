import styled from "styled-components";

import { motion } from "framer-motion";

export const Container = styled(motion.main)`
  /* outline: 1px solid red; */
  min-height: calc(100vh - 4.375rem);
  overflow-x: hidden;
  padding: 1rem 2rem;

  @media screen and (max-width: 480px) {
    padding: 1.3rem;
  }
`;

export const FormMainContainer = styled.div`
  /* border: 1px solid black; */
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 23rem;
  padding: 1rem 2rem 2rem;
  align-items: center;
  margin: auto;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 10px;
  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    input {
      outline: none;
      border: 2px solid #cdcdcd;
      width: 16.75rem;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
    }
  }

  @media screen and (max-width: 480px) {
    margin: unset;
    width: 100%;
    height: fit-content;
    gap: 0.8rem;
    padding: 0.5rem 1rem 2rem;
    form {
      gap: 1rem;
    }
  }
`;

export const RegisterBtn = styled.button`
  width: 100%;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  background: rgb(213, 12, 34);
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: rgb(127, 6, 20);
  }

  &:disabled {
    cursor: not-allowed;
    background: rgb(255, 113, 130);
  }
`;

export const Logo = styled.img`
  width: 5rem;
  aspect-ratio: 1;
`;
export const WelcomeContainer = styled.div`
  width: 100%;
  p {
    font-family: "Fraunces", serif;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
  }

  @media (max-width: 480px) {
    p {
      font-size: 1.4rem;
    }
  }
`;

export const Box = styled.div`
  /* border: 1px solid red; */
  /* padding: 1.25rem 2.5rem; */
  background: #fff;
  border-radius: 10px;
  width: 100%;
  label {
    display: flex;
    height: 3.313rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid lightgray;
    border-radius: 10px;
    margin: 0.625rem 0;
    cursor: pointer;
    transition: all 0.3s ease;

    .text {
      color: #333;
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
      padding-left: 0.625rem;
      transition: color 0.3s ease;
    }
  }

  #teacher:checked ~ .teacher,
  #student:checked ~ .student {
    background: #1a73e8;
    border-color: #1a73e8;
  }
  #teacher:checked ~ .teacher .text,
  #student:checked ~ .student .text {
    color: #fff;
  }

  input[type="radio"] {
    display: none;
  }
`;

export const MemberContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: bold;

  a {
    color: rgb(26, 115, 232);
    position: relative;
    &:after {
      content: "";
      height: 0.1rem;
      background: rgb(26, 115, 232);
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.01rem;
    }
  }
`;
export const TermsConditons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.625rem;

  a {
    color: rgb(26, 115, 232);
    position: relative;
    &:after {
      content: "";
      height: 0.1rem;
      background: rgb(26, 115, 232);
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.01rem;
    }
  }
`;
