import styled from "styled-components";

const Container = styled.section`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  /* box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%); */
  padding: 1.5rem 2rem;
  width: 100%;
  min-height: 100%;
  font-family: var(--primary-ff);
  color: var(--clr-neutral-900);
  @media (max-width: 50em) {
    padding: 0.5rem;
  }
`;

const Headline = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;

  p {
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.1rem;
      background: rgb(1, 59, 135);
      left: 0;
      bottom: -0.075rem;
    }
  }

  @media (max-width: 50em) {
    p {
      font-size: 1.2rem;
    }
  }
`;

const DetailsContainer = styled.div`
  /* border: 1px solid red; */
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem;

  @media screen and (max-width: 50em) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const PhotoContainer = styled.div`
  /* border: 1px solid green; */
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 50%;
`;

const ProfilePhoto = styled.div`
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
  height: 20rem;
  width: 20rem;

  @media screen and (max-width: 50em) {
    height: 13rem;
    width: 13rem;
  }
`;

const PersonalDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  span {
    font-weight: var(--fw-bold);
  }
`;

export {
  Container,
  DetailsContainer,
  Headline,
  PhotoContainer,
  ProfilePhoto,
  PersonalDetails,
};
