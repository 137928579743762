import styled from "styled-components";

const PhotoUploadContainer = styled.div`
  /* border: 1px solid red; */
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  input[type="file"] {
    display: none;
  }

  label {
    background: var(--color-panel-primary);
    color: var(--color-white);
    padding: 0.5rem 1.5rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
  }

  @media screen and (max-width: 50em) {
    width: 100%;
    height: fit-content;
  }
`;

const ProfilePhotoPreview = styled.div`
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
  height: 20rem;
  width: 20rem;

  @media screen and (max-width: 50em) {
    height: 13rem;
    width: 13rem;
  }
`;

const PhotoDisclaimer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  padding-inline: 1rem;
`;

const PersonalDetailsContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
`;

export {
  PhotoUploadContainer,
  ProfilePhotoPreview,
  PersonalDetailsContainer,
  PhotoDisclaimer,
};
