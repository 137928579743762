import styled from "styled-components";

const Container = styled.section`
  /* border: 1px solid cyan; */
  width: 100%;
  min-height: 100%;
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  background: #fff;

  @media screen and (max-width: 50em) {
    padding: 0.5rem 0.5rem 1.5rem 0.5rem;
  }
`;

const TitleHeader = styled.header`
  /* border: 1px solid blue; */
  width: 100%;
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    color: var(--clr-neutral-900);
    font-size: 1.5rem;
    font-weight: var(--fw-bold);
  }
`;

const BtnContainer = styled.section`
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

const Btn = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  /* border-radius: 0.9375em; */
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  text-align: center;
  gap: 0.6em;
  justify-content: space-between;
  font-size: 1rem;
  /* font-weight: var(--fw-bold); */
  outline: none;
  padding: 0.5em 1em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:disabled {
    /* pointer-events: none; */
    cursor: not-allowed;
  }
`;

const NextBtn = styled(Btn)`
  border: 0.06em solid #007bff;
  background: #007bff;
  color: var(--clr-neutral-100);

  &:hover {
    border: 0.06em solid #007bff;

    background: #0056b3;
  }

  &:disabled {
    border: 0.06em solid #ccc;
    color: var(--clr-neutral-400);
    background: none;
  }
`;
const PrevBtn = styled(Btn)`
  border: 0.06em solid #ccc;

  &:hover {
    border: 0.06em solid #007bff;
    background: #0056b3;
    color: var(--clr-neutral-100);
  }

  &:disabled {
    border: 0.06em solid #ccc;
    color: var(--clr-neutral-400);
    background: none;
  }
`;

const InformationContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;

  @media screen and (max-width: 40em) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: 1rem;
  }
`;

// PERSONAL INFORMATION

export {
  Container,
  TitleHeader,
  BtnContainer,
  NextBtn,
  PrevBtn,
  InformationContainer,
};
