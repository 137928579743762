import {
  FeeContainer,
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "./detailsStyles";

const FeeInformation = ({ feeDetails, tuitionType }) => {
  return (
    <FeeContainer>
      {["GEN", "GEN-HOME"].includes(tuitionType) && (
        <TableContainer>
          <Table>
            <TableCaption>General Tuition Fee Information</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Sl. No</TableHeaderCell>
                <TableHeaderCell>For Classes</TableHeaderCell>
                <TableHeaderCell>Fee Amount</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {feeDetails.map((item, index) => {
                if (item.tuition_type === "GEN") {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{String(index + 1)}</TableCell>
                      <TableCell>{item.for_classes}</TableCell>
                      <TableCell>₹ {item.tch_fee_amount}</TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {["HOME", "GEN-HOME"].includes(tuitionType) && (
        <TableContainer>
          <Table>
            <TableCaption>Home Tuition Fee Information</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Sl. No</TableHeaderCell>
                <TableHeaderCell>For Classes</TableHeaderCell>
                <TableHeaderCell>Fee Amount</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {feeDetails.map((item, index) => {
                if (item.tuition_type === "HOME") {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{String(index + 1)}</TableCell>
                      <TableCell>{item.for_classes}</TableCell>
                      <TableCell>₹ {item.tch_fee_amount}</TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </FeeContainer>
  );
};

export default FeeInformation;
