import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "Schoolies",
  description: null,
};

const metaDataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      const { title, description } = action.payload;
      state.title = title ? `Schoolies - ${title}` : null;
      state.description = description ? description : null;
    },
  },
});

const { reducer, actions } = metaDataSlice;

export const { setMetaData } = actions;

export const getMetaDataStates = createSelector(
  (state) => state.metadata.title,
  (state) => state.metadata.description,
  (title, description) => {
    return { title, description };
  }
);

export default reducer;
