import React from "react";
import RootHeader from "../components/Header/RootHeader";
import Footer from "../components/Footer/Footer";
import { Outlet } from "react-router-dom";

const RootLayout = () => {
  return (
    <>
      <RootHeader />
      <Outlet />
      <Footer />
    </>
  );
};

export default RootLayout;
