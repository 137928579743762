import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { getUserProfile } from "../../../redux/slices/userInfoSlice";
import Analytics from "./Analytics";
import BankAccountDetail from "./BankAccountDetail";
import Filter from "./Filter";
import MobileHeader from "./MobileHeader";
import PaymentStatus from "./PaymentStatus";
import { Wrapper } from "./layoutStyles";

const FeeManagementLayout = () => {
  const userProfile = useSelector(getUserProfile);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isBankOpen, setIsBankOpen] = useState(false);
  const [activeBtn, setActiveBtn] = useState(null);

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const tuitionTypeParam = searchParams.get("tuition_type");
    const monthParam = searchParams.get("month");
    const yearParam = searchParams.get("year");
    const isParams =
      tuitionTypeParam !== null && monthParam !== null && yearParam !== null;
    let optionsMap = {
      GEN: "GEN",
      HOME: "HOME",
    };
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    if (!isParams & location.pathname.includes("fee-management")) {
      setSearchParams(
        {
          tuition_type: optionsMap[userProfile.tuition_type] || "GEN",
          month: currentMonth.toString(),
          year: currentYear.toString(),
          payment_status: "PENDING",
        },
        { replace: true }
      );
    }
  }, [userProfile.tuition_type, searchParams, setSearchParams]);

  const handleOnClose = () => {
    setIsBankOpen(false);
    setIsFilterMenuOpen(false);
    setActiveBtn(null);
  };

  return (
    <>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Helmet>
        <title>
          {userProfile
            ? "Fee Management"
            : `Fee Management - ${userProfile.name}`}
        </title>
      </Helmet>
      <Wrapper>
        <MobileHeader
          setIsFilterMenuOpen={setIsFilterMenuOpen}
          setIsBankOpen={setIsBankOpen}
          setActiveBtn={setActiveBtn}
          activeBtn={activeBtn}
        />
        <Analytics />
        <PaymentStatus />
        <Filter filterMenuToggle={isFilterMenuOpen} onClose={handleOnClose} />
        <BankAccountDetail
          setLoadingProgress={setProgress}
          toggleBankDisplay={isBankOpen}
          onClose={handleOnClose}
        />
      </Wrapper>
    </>
  );
};

export default FeeManagementLayout;
