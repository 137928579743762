import { useLayoutEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { userRegister } from "../../api/signupApi";
import { NotificationBar } from "../../components/notificationBar";

import { staticUrl } from "../../utils/axiosInstance";

import {
  Box,
  Container,
  FormMainContainer,
  Logo,
  MemberContainer,
  RegisterBtn,
  TermsConditons,
  WelcomeContainer,
} from "./stylesRegister";

const Register = () => {
  // getting search params
  const [searchParams, setSearchParams] = useSearchParams();
  const referralCode = searchParams.get("ref");

  // intialising naviagation
  const navigate = useNavigate();

  // intialsing for loading bar
  const [progress, setProgress] = useState(0);

  // initial state of form
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    regd_as: "",
    conPassword: "",
  });

  // destructuring of form data
  const { email, password, conPassword, regd_as } = formData;

  // fucntion to control disablity of register button
  const disableRegdBtn = () => {
    if (
      email === "" ||
      password === "" ||
      conPassword === "" ||
      regd_as === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  // changing form data
  const changeData = (e) => {
    try {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    } catch (error) {
      NotificationBar(
        "We are facing some problems. Please try again later.",
        "error"
      );
    }
  };

  // handeling data on submit
  const handleFormDataSubmit = async (e) => {
    e.preventDefault();
    if (password !== conPassword) {
      NotificationBar("Password and Confirm Password did not match.", "error");
    } else {
      if (regd_as === "") {
        NotificationBar(`"Registered As" cannot be blank`, "info");
      } else {
        setProgress(50);
        let response = await userRegister({
          email,
          password,
          regd_as,
          referralCode,
        });
        setProgress(90);
        console.log(response);
        if (response.status === 201) {
          setProgress(100);
          navigate(`/verify-email`, { replace: true });
        } else if (response.status === 400) {
          if (response.data.message === "User Exists") {
            NotificationBar("Account Already Exits", "info");
          } else {
            NotificationBar(
              "We are unable to register you account. Please try again after sometime",
              "error"
            );
          }
        } else {
          NotificationBar(
            "We are facing some server issues. Please try registering later.",
            "error"
          );
        }
      }
    }
  };

  // to bring the view on top
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0 }}
    >
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />

      <Helmet>
        <title>Schoolies - Register</title>
      </Helmet>

      <FormMainContainer>
        <Logo src={`${staticUrl}images/only-logo.svg`} alt="logo" />

        <WelcomeContainer>
          <p>Welcome to Schoolies</p>
        </WelcomeContainer>
        <form onSubmit={(e) => handleFormDataSubmit(e)}>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={email}
            autoComplete="on"
            onChange={(e) => changeData(e)}
            required
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Create a Password"
            value={password}
            autoComplete="on"
            onChange={(e) => changeData(e)}
            required
          />
          <input
            type="password"
            name="conPassword"
            id="conPassword"
            placeholder="Confirm Password"
            value={conPassword}
            onChange={(e) => changeData(e)}
            required
          />

          <Box>
            <input
              type="radio"
              value="TCH"
              name="regd_as"
              id="teacher"
              onChange={(e) => changeData(e)}
            />
            <input
              type="radio"
              value="STU"
              name="regd_as"
              id="student"
              onChange={(e) => changeData(e)}
            />
            <label htmlFor="teacher" className="teacher">
              <div className="dot"></div>
              <div className="text">Register as a Teacher</div>
            </label>
            <label htmlFor="student" className="student">
              <div className="dot"></div>
              <div className="text">Register as a Student</div>
            </label>
          </Box>

          <RegisterBtn type="submit" disabled={disableRegdBtn()}>
            Sign up
          </RegisterBtn>
        </form>

        <TermsConditons>
          <p>By registering you agree to our terms and conditions</p>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </TermsConditons>
        <MemberContainer>
          <p>Already have an account?</p> <Link to="/login">Log In</Link>
        </MemberContainer>
      </FormMainContainer>
    </Container>
  );
};

export default Register;
