import styled from "styled-components";

export const Container = styled.section`
  /* border: 1px solid blue; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.4rem;
  padding: 1rem;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  background: #fff;
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;

  &:hover {
    transform: scale(1.02);
  }
`;

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25rem;
  border-bottom: 2px solid #e0e0e0;
`;

export const Cards = styled.div`
  height: 21rem;
  max-height: 25rem;
  width: 20rem;
  border-radius: 0.5rem;
  background-color: #fff;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
`;

export const CardTitle = styled.h2`
  font-size: 1.4rem;
  color: #333;
`;

export const CardDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.65rem;
  padding: 1.2rem;
`;

export const CardItem = styled.div`
  color: #666;
  .bold {
    font-weight: var(--fw-bold);
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
`;

const Button = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  /* border-radius: 0.9375em; */
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--fw-bold);

  outline: none;
  padding: 0.7em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    color: #fff;
    /* background-color: #1a1a1a; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
`;

export const AcceptButton = styled(Button)`
  border: 0.06em solid #4caf50;
  color: #fff;
  background-color: #4caf50;
  &:hover {
  }
`;

export const DeclineButton = styled(Button)`
  border: 0.06em solid #1a1a1a;
  &:hover {
    border: 0.06em solid #f44336;
    background-color: #f44336;
  }
`;
