import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.main)`
  /* outline: 1px solid red; */
  display: flex;
  min-height: calc(100vh - 4.375rem);
  overflow-x: hidden;
  padding: 1rem 2rem;
  font-family: var(--ff-secondary);
  @media screen and (max-width: 480px) {
    padding: 1.3rem;
  }
`;

export const FormMainContainer = styled.div`
  /* border: 1px solid black; */
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 23rem;
  padding: 2rem;
  align-items: center;
  margin: auto;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 10px;
  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    input {
      outline: none;
      border: 2px solid #cdcdcd;
      width: 16.75rem;
      padding: 0.5rem 1rem;
      border-radius: 1rem;
      font-size: 1rem;
    }
  }

  @media screen and (max-width: 480px) {
    margin: 2rem auto;
    width: 100%;
    height: fit-content;
    padding: 2rem 1rem;
    gap: 1rem;
  }
`;

export const LoginBtn = styled.button`
  width: 100%;
  outline: none;
  border: none;

  font-size: 1rem;
  font-weight: var(--fw-bold);
  font-family: var(--ff-button);
  background: rgb(213, 12, 34);
  color: #fff;
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: rgb(127, 6, 20);
  }

  &:disabled {
    cursor: not-allowed;
    background: rgb(255, 113, 130);
  }
`;

export const Logo = styled.img`
  width: 5rem;
  aspect-ratio: 1;
`;

export const WelcomeContainer = styled.div`
  width: 100%;
  p {
    font-family: var(--ff-primary);
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
  }

  @media (max-width: 480px) {
    p {
      font-size: 1.4rem;
    }
  }
`;

export const ForgotPasswordContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  a {
    font-size: 1rem;
    font-weight: bold;
    color: #000;
  }
`;

export const MemberContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;

  a {
    color: rgb(26, 115, 232);
    position: relative;
    &:after {
      content: "";
      height: 0.1rem;
      background: rgb(26, 115, 232);
      position: absolute;
      left: 0;
      right: 0;
      bottom: -0.01rem;
    }
  }
`;
