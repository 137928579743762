import { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { resetPassword } from "../../../api/passwordResetApi";
import { NotificationBar } from "../../../components/notificationBar";

import { staticUrl } from "../../../utils/axiosInstance";

// STYLES
import {
  Container,
  FormContainer,
  Logo,
  PasswordResetBtn,
  PasswordResetForm,
} from "../forgotpasswordStyles";

const PasswordReset = () => {
  // initialising navigate
  const navigate = useNavigate();

  // for loading bar
  const [progress, setProgress] = useState(0);

  // getting token from params
  const { token } = useParams();

  // initialising state for password
  const [password, setPassword] = useState({
    newPassword: "",
    conPassword: "",
  });

  const { newPassword, conPassword } = password;

  //  onchange function to handle password input
  const onChangePassword = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  //   function to handle form submission
  const submitPassword = async (e) => {
    e.preventDefault();
    setProgress(20);
    if (newPassword !== conPassword) {
      NotificationBar(
        "New Password and Confirm Password did not match",
        "error"
      );
      setProgress(100);
    } else {
      setProgress(50);
      let response = await resetPassword(token, newPassword);
      setProgress(90);
      if (response.status === 200) {
        setProgress(100);
        NotificationBar("Password Changed Successfully.", "success");
        return navigate(`/login`);
      } else if (response.status === 400) {
        setProgress(100);
        NotificationBar("We were unable to change your password", "error");
      }
    }
  };
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0 }}
    >
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Helmet>
        <title>Schoolies - Reset Password</title>
      </Helmet>
      <FormContainer>
        <Logo src={`${staticUrl}images/only-logo.svg`} alt="schoolies" />
        <PasswordResetForm onSubmit={submitPassword}>
          <input
            type="password"
            name="newPassword"
            id="newPassword"
            placeholder="New Password"
            value={newPassword}
            onChange={onChangePassword}
            required
          />
          <input
            type="password"
            name="conPassword"
            id="conPassword"
            placeholder="Confirm Password"
            value={conPassword}
            onChange={onChangePassword}
            required
          />
          <PasswordResetBtn>
            <i className="fas fa-lock"></i> Reset Password
          </PasswordResetBtn>
        </PasswordResetForm>
      </FormContainer>
    </Container>
  );
};

export default PasswordReset;
