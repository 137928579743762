import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const HeaderWrapper = styled.header`
  /* outline: auto; */
  height: var(--size-rootheader);
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: var(--clr-neutral-100);
  box-shadow: ${({ $isScrolling }) =>
    $isScrolling
      ? "0 var(--size-100) var(--size-600) rgba(0, 0, 0, 0.2)"
      : "none"};
  transition: box-shadow 150ms ease-in;
`;

export const Container = styled.div`
  flex: 1;
  width: min(var(--size-container), 100% - (var(--size-400) * 2));
  margin-inline: auto;
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-block: 0.5rem;
  height: 100%;

  @media screen and (max-width: 55em) {
    gap: 0;
  }
`;
export const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const LogoLink = styled(NavLink)`
  /* outline: auto; */
  display: flex;
  height: 100%;
  flex-shrink: 0;
  width: clamp(10rem, 12vw + 1rem, 13rem);
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  text-decoration: none;
  svg {
    width: 100%;
    height: auto;
  }
`;

export const NonAuthNavbar = styled.nav`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  background: var(--clr-neutral-100);
  .push_right {
    margin-left: auto;
  }

  @media screen and (max-width: 55em) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 101;
    padding: 2rem 2rem 2rem 0;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-end;
    justify-content: flex-start;
    width: 60%;
    height: 100vh;
    transform: ${({ $toggleDisplay }) =>
      $toggleDisplay ? "translateX(0)" : "translateX(100%)"};
    box-shadow: ${({ $toggleDisplay }) =>
      $toggleDisplay ? "-8px 0 15px -3px rgba(0, 0, 0, 0.7)" : "none"};
    transition: all 300ms ease;

    .push_right {
      margin-left: unset;
    }
  }
`;

export const AuthNavbar = styled.nav`
  /* outline: auto; */
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;

export const NavigationLinks = styled(NavLink)`
  font-family: var(--ff-nav);
  font-weight: var(--fw-bold);
  text-decoration: none;
  color: var(--clr-neutral-900);
  border: none;
  outline: none;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  position: relative;
  text-align: center;

  &:not(:last-of-type) {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%; // Start from the center
      right: 50%; // Start from the center
      background: var(--clr-neutral-900);
      display: block;
      height: 0.189rem;
      transition: all 300ms ease;
      transform: scaleX(0); // Start with a scale of 0
      transition-property: transform, left, right;
    }

    &:hover::after,
    &.active::after {
      left: 25%; // Spread 25% to the left
      right: 25%; // Spread 25% to the right
      transform: scaleX(1); // Scale back to full width
    }
  }

  @media screen and (max-width: 55em) {
    font-size: 1.2rem;
  }
`;

export const CTALink = styled(NavigationLinks)`
  border: 2px solid var(--clr-neutral-900);
  border-radius: 0.55rem;

  &.active,
  &:hover {
    background: var(--clr-neutral-900);
    color: var(--clr-neutral-100);
  }

  @media screen and (max-width: 55em) {
    border: none;
    &.active,
    &:hover {
      background: none;
      color: var(--clr-neutral-900);
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%; // Start from the center
      right: 50%; // Start from the center
      background: var(--clr-neutral-900);
      display: block;
      height: 0.189rem;
      transition: all 300ms ease;
      transform: scaleX(0); // Start with a scale of 0
      transition-property: transform, left, right;
    }

    &:hover::after,
    &.active::after {
      left: 25%; // Spread 25% to the left
      right: 25%; // Spread 25% to the right
      transform: scaleX(1); // Scale back to full width
    }
  }
`;

export const ProfileButton = styled(NavigationLinks)`
  border: 2px solid var(--clr-neutral-900);
  border-radius: 0.55rem;

  &.active,
  &:hover {
    background: var(--clr-neutral-900);
    color: var(--clr-neutral-100);
  }
`;

export const CloseButtonContainer = styled.div`
  margin-bottom: 2rem;
  display: none;
  margin-left: auto;

  @media screen and (max-width: 55em) {
    display: flex;
  }
`;

export const CloseButton = styled.button`
  font-size: 1.5rem;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  background: transparent;
`;

export const BurgerMenuContainer = styled.div`
  display: none;
  flex: 1;
  justify-content: flex-end;

  @media screen and (max-width: 55em) {
    display: flex;
  }
`;

export const BurgerMenuButton = styled.button`
  text-align: center;
  font-size: 1.5rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
`;
