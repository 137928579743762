import React from "react";
import {
  CloseBtnDiv,
  CloseButton,
  SearchIcon,
  SearchbarContainer,
  SearchbarInput,
} from "./searchbarStyles";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../../../hooks/useDebounce";
import { removeExtraSpace } from "../../../../utils/stringActions";
const SearchBar = ({ toggleSearchbar, handleToggle }) => {
  /*
    THIS SEARCH COMPONENT USES DEBOUNCE TO SEARCH.
    IT REQUIRES REACT-QUERY IN THE PARENT TO WORK
  */
  const [placeholders, setPlaceholders] = useState([
    "student's name",
    "student's phone number",
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  const searchQueryParam = searchParams.get("search_query");

  const tuitionTypeParam = searchParams.get("tuition_type");

  const [searchQuery, setSearchQuery] = useState(searchQueryParam || "");

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  useEffect(() => {
    /*
        THIS IS USED TO CHANGE PLACEHOLDERS FROM THE PLACEHOLDER LIST
    */

    const interval = setInterval(() => {
      setPlaceholderIndex((curr) => (curr + 1) % placeholders.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [placeholderIndex]);

  const handleSearchQuery = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    if (value.length <= 2) {
      searchParams.delete("search_query");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    /*
      THIS IS USED TO UPDATE URL WHEN THERE IS A NEW 
      DEBOUNCED VALUE
    */

    if (debouncedSearchQuery) {
      let modifiedQuery = removeExtraSpace(debouncedSearchQuery);
      searchParams.set("search_query", modifiedQuery);
      setSearchParams(searchParams, { replace: true });
    }
  }, [debouncedSearchQuery]);

  return (
    <SearchbarContainer $displayBar={toggleSearchbar}>
      <SearchbarInput
        type="text"
        name="search"
        id="search"
        value={searchQuery}
        onChange={handleSearchQuery}
        placeholder={`Search with "${placeholders[placeholderIndex]}"`}
      />
      <SearchIcon>
        <i className="fas fa-search"></i>
      </SearchIcon>
      <CloseBtnDiv>
        <CloseButton onClick={handleToggle} type="button">
          <i className="fas fa-times"></i>
        </CloseButton>
      </CloseBtnDiv>
    </SearchbarContainer>
  );
};

export default SearchBar;
