import axiosInstance from "../utils/axiosInstance";

// function to edit the details of the logged in teacher
export const editTeacherDetail = async (
  detailsData,
  feeData,
  rmvFeeDataIds
) => {
  let formData = new FormData();

  Object.keys(detailsData).forEach((key) => {
    formData.append(`${key}`, detailsData[key]);
  });

  formData.append("feeData", JSON.stringify(feeData));
  formData.append("rmvFeeDataIds", JSON.stringify(rmvFeeDataIds));

  try {
    let response = await axiosInstance.patch(`/teacher/edit-detail/`, formData);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      console.error(error.request);
      return error.request;
    } else {
      console.error(error);
    }
  }
};

// FUNCTION TO GET ALL THE ACCEPTED STUDENTS FOR THE TEACHERS

export const getEnrolledStudents = async (tuitionType, searchQuery) => {
  try {
    let response = await axiosInstance.get(`/teacher/enrolled-students/`, {
      params: {
        tuition_type: tuitionType,
        search_query: searchQuery,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGenStudents = async () => {
  try {
    let response = await axiosInstance.get(`/teacher/gen-accepted-student/`);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

export const getHomStudents = async () => {
  try {
    let response = await axiosInstance.get(`/teacher/hom-accepted-student/`);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

// FUCNTION TO SEARCH STUDENT

export const searchAccStudent = async (query) => {
  let body = {
    query: query,
  };
  try {
    let response = await axiosInstance.post(`/teacher/search-student/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

export const getEditIfoDetails = async () => {
  try {
    let response = await axiosInstance.get(`teacher/get-edit-info-details/`);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
};

export const getTotalExpectedFees = async (tuitionType, month, year) => {
  try {
    let response = await axiosInstance.get(`teacher/total-expected-fees/`, {
      params: {
        tuition_type: tuitionType,
        month: month,
        year: year,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTotalCollectedFees = async (tuitionType, month, year) => {
  try {
    let response = await axiosInstance.get(`teacher/total-collected-fees/`, {
      params: {
        tuition_type: tuitionType,
        month: month,
        year: year,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const getBankDetails = async () => {
  try {
    let response = await axiosInstance.get(`teacher/get-bank-details/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const submitBankAccountDetails = async (bankDetail) => {
  try {
    let response = await axiosInstance.post(
      "/teacher/create-bank-detail/",
      bankDetail
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const editBankAccountDetails = async (bankDetail) => {
  try {
    let response = await axiosInstance.patch(
      "/teacher/update-bank-detail/",
      bankDetail
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getStudentPaymentStatus = async ({
  paymentStatusParam,
  tuitionTypeParam,
  monthParam,
  yearParam,
}) => {
  try {
    let response = await axiosInstance.get("/teacher/student-payment-status/", {
      params: {
        payment_status: paymentStatusParam,
        tuition_type: tuitionTypeParam,
        month: monthParam,
        year: yearParam,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
