import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { logoutAction, userLoginAction } from "../actions/authAction";

const initialState = {
  status: "pending",
  isAuthenticated: localStorage.getItem("token") ? true : false,
  revive: localStorage.getItem("revive")
    ? localStorage.getItem("revive")
    : null,

  // access token
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,

  user: localStorage.getItem("token")
    ? jwtDecode(localStorage.getItem("token"))
    : null,

  error: {
    message: null,
    statusCode: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    getUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoginAction.fulfilled, (state, action) => {
      const { payload } = action;
      state.status = "success";
      state.isAuthenticated = true;
      state.revive = payload.access;
      state.token = payload.refresh;
      state.user = jwtDecode(payload.access);
      state.error.message = null;
      state.error.statusCode = null;
    });
    builder.addCase(userLoginAction.rejected, (state, action) => {
      const { payload } = action;
      state.status = "failed";
      state.error.message = payload.detail;
      state.error.statusCode = payload.status;
    });
    builder.addCase(logoutAction.fulfilled, (state, action) => {
      state.status = "success";
      state.isAuthenticated = false;
      state.revive = null;
      state.token = null;
      state.user = null;
      state.error.message = null;
      state.error.statusCode = null;
    });
    builder.addCase(logoutAction.rejected, (state, action) => {
      state.status = "failed";
      state.isAuthenticated = false;
      state.revive = null;
      state.token = null;
      state.user = null;
      state.error.message = action.payload?.detail;
      state.error.statusCode = action.payload?.status;
    });
  },
});

const { reducer, actions } = authSlice;

export const { getUserInfo } = actions;

export const getAuthenticatedStatus = (state) => state.auth.isAuthenticated;
export const getUser = (state) => state.auth.user;

export default reducer;
