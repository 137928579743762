import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NotificationBar } from "../../../components/notificationBar";

import { useNavigate } from "react-router-dom";
import {
  BtnContainer,
  Container,
  DetailsForm,
  FormBody,
  SubmitBtn,
} from "./EditProfileStyles";

import EditProfileForm from "./Edit Form/EditProfileForm";

import { getUserProfile } from "../../../redux/slices/userInfoSlice";
import { resizeProfileImage } from "../../../utils/imageModification";

import { updateStudentProfile } from "../../../redux/actions/userInfoActions";
import { setProgress } from "../../../redux/slices/loadingbarSlice";
import { setMetaData } from "../../../redux/slices/metaDataSlice";

const EditProfile = () => {
  //getting information from the slices
  const profile = useSelector(getUserProfile);

  // states for loading bar

  // initialising navigate
  const navigate = useNavigate();

  // initialising dispatch
  const dispatch = useDispatch();

  //initial states of form values
  const [detailsData, setDetailsData] = useState({
    name: "",
    date_of_birth: "",
    student_phone: "",
    parent_phone: "",
    address: "",
    parent_name: "",
    standard: "",
    school_name: "",
    board: "",
    gender: "",
    state: "",
  });

  //state to get the image
  const [photo, setPhoto] = useState("");

  const [photoPreview, setPhotoPreview] = useState("");

  // state to control ui while resing the image
  const [isPhotoResizing, setIsPhotoResizing] = useState(false);

  useEffect(() => {
    // SETS PREFIL VALUES FROM GLOBAL STATE TO LOCAL STATE

    let updatedDetailsData = {
      name: profile.name || "",
      date_of_birth: profile.date_of_birth || "",
      student_phone: profile.student_phone || "",
      parent_phone: profile.parent_phone || "",
      address: profile.address || "",
      parent_name: profile.parent_name || "",
      standard: profile.standard || "",
      school_name: profile.school_name || "",
      board: profile.board || "0",
      gender: profile.gender || "",
      state: profile.state || "0",
    };

    setDetailsData(updatedDetailsData);

    setPhoto(profile.profile_photo || "");
    setPhotoPreview(profile.profile_photo || "");
  }, [profile]);

  useEffect(() => {
    const title = "Edit Profile";
    dispatch(setMetaData({ title: title }));
  }, []);

  // function to change the state of photo
  const changePhoto = (e) => {
    const file = e.target.files[0];

    if (file.size > 10485760) {
      NotificationBar(
        `The image size is greater than 10mb. Please upload an image of a smaller size.`,
        "info"
      );
      return;
    }
    const targetFileSize = 200 * 1024; // Maximum file size in bytes (200 KB)

    if (file.size > targetFileSize) {
      setIsPhotoResizing(true);
      // Check if the file size is greater than the maximum size
      setPhotoPreview(URL.createObjectURL(file));
      resizeProfileImage(file, (resizedFile) => {
        setPhoto(resizedFile);
        setIsPhotoResizing(false);
      });
    } else {
      setPhoto(file);
      setPhotoPreview(URL.createObjectURL(file));
    }
  };

  // onchange function for form
  const handleChangeDetailsData = (e) => {
    setDetailsData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
  // onchange handler for entering only numbers
  const handleOnlyNumbers = (e) => {
    const regex = /^[0-9\b]+$/;

    const value = e.target.value;
    if (value === "" || regex.test(value)) {
      setDetailsData({
        ...detailsData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmitDetailsData = async (e) => {
    dispatch(setProgress(20));
    e.preventDefault();

    let newDetailsData = {};

    Object.keys(detailsData).forEach((key) => {
      if (detailsData[key] !== profile[key]) {
        newDetailsData[key] = detailsData[key];
      }
    });

    if (photo !== profile.profile_photo) {
      newDetailsData.profile_photo = photo;
    }

    try {
      dispatch(setProgress(40));

      let response = await dispatch(
        updateStudentProfile(newDetailsData)
      ).unwrap();
      NotificationBar(`${response.message}`, "success", 5000);

      navigate(`/student/profile`);
    } catch (error) {
      NotificationBar(`${error.error}`, "error");
    } finally {
      dispatch(setProgress(100));
    }
  };

  return (
    <>
      <Container>
        <DetailsForm onSubmit={handleSubmitDetailsData}>
          <FormBody>
            <EditProfileForm
              detailsData={detailsData}
              changePhoto={changePhoto}
              isPhotoResizing={isPhotoResizing}
              photoPreview={photoPreview}
              handleSubmitDetailsData={handleSubmitDetailsData}
              handleChangeDetailsData={handleChangeDetailsData}
              handleOnlyNumbers={handleOnlyNumbers}
              photo={photo}
            />
          </FormBody>
          <BtnContainer>
            <SubmitBtn type="submit">Submit</SubmitBtn>
          </BtnContainer>
        </DetailsForm>
      </Container>
    </>
  );
};

export default EditProfile;
