import { useQuery } from "@tanstack/react-query";
import { Suspense, lazy, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getEnrolledStudents } from "../../../api/teacher";
import { CardContainer } from "./enrolledStuStyles";

const InfoCards = lazy(() => import("../../../components/Cards/InfoCards"));

const StudentCardSection = ({ setProgress }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tuitionTypeParam = searchParams.get("tuition_type");
  const searchQueryParam = searchParams.get("search_query");
  const [enrolledStudents, setEnrolledStudents] = useState([]);

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["teacher/fetchStudents", tuitionTypeParam, searchQueryParam],
    queryFn: () => getEnrolledStudents(tuitionTypeParam, searchQueryParam),
    refetchOnWindowFocus: false,
    enabled: tuitionTypeParam !== null,
    retry: false,
  });

  useEffect(() => {
    /*
     CONTROLS THE PROGRESS BAR
    */
    if (isLoading) {
      setProgress(80);
    } else {
      setProgress(100);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading) {
      console.log(data);
      setEnrolledStudents(data);
      let infoArr = data?.map((item) => ({
        id: item.id,
        studentName: item.name,
        photo: item.profile_photo,
        details: [
          {
            label: "Parent's Name",
            value: item.parent_name,
          },
          {
            label: "Student's Phone",
            value: item.student_phone,
          },
          {
            label: "Parent's Phone",
            value: item.parent_phone,
          },
          {
            label: "Class",
            value: item.standard,
          },
          {
            label: "Board",
            value: item.board,
          },
          {
            label: "School",
            value: item.school_name,
          },
          {
            label: "Address",
            value: item.address,
          },
        ],
      }));

      setEnrolledStudents(infoArr);
    }
  }, [isLoading, data]);

  if (isLoading) {
    return <></>;
  }

  return (
    <CardContainer>
      {enrolledStudents.length > 0 ? (
        enrolledStudents.map((student) => (
          <Suspense fallback={<div>Loading...</div>}>
            <InfoCards
              key={student.id}
              title={student.studentName}
              image={student.photo}
              information={student.details}
            />
          </Suspense>
        ))
      ) : (
        <p>No enrolled students</p>
      )}
    </CardContainer>
  );
};

export default StudentCardSection;
