import styled, { keyframes } from "styled-components";

const typeWriterAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

export const SearchbarContainer = styled.div`
  /* border: 1px solid grey; */
  background: var(--clr-neutral-100);
  display: flex;
  width: 40rem;
  height: 100%;
  border-radius: 100vw;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  @media screen and (300px <= width <= 550px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 2;
    transform: translateY(${(props) => (props.$displayBar ? "0" : "-100%")});
    transition: all 200ms ease-in;
  }
`;

export const SearchIcon = styled.div`
  /* outline: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  font-size: 1.2rem;
  overflow: hidden;
  background: var(--clr-neutral-100);
`;

export const SearchbarInput = styled.input`
  background: var(--clr-neutral-100);
  &[type="text"] {
    border: none;
    outline: none;
    font-size: var(--fs-600);
    font-family: var(--ff-secondary);
    width: 100%;
    border-radius: 100vw;
    padding-right: 1rem;
    padding-left: 3rem;
    overflow: hidden;
    white-space: nowrap;
    /* animation: ${typeWriterAnimation} 2000ms steps(40) infinite; */

    @media screen and (width <=550px) {
      font-size: var(--fs-500);
      padding-right: 2.8rem;
    }
  }
`;

export const CloseBtnDiv = styled.div`
  /* outline: 1px solid blue; */
  display: none;
  justify-content: center;
  align-items: center;
  width: 2.8rem;
  height: 100%;
  position: absolute;
  right: 0;
  overflow: hidden;
  background: var(--clr-neutral-100);

  @media screen and (width <= 550px) {
    display: flex;
  }
`;

export const CloseButton = styled.button`
  outline: none;
  border: none;
  height: 100%;
  width: 100%;
  background: transparent;
  font-size: 1.2rem;
  cursor: pointer;
`;
