import styled, { css } from "styled-components";

export const Wrapper = styled.section`
  /* outline: 1px solid red; */
  display: grid;
  grid-template-columns: 1fr 25%;
  grid-template-rows: auto auto;
  gap: 1rem;
  overflow-x: clip;
  position: relative;
  padding: 1.5rem 1rem;

  @media screen and (max-width: 90rem) and (min-width: 50em) {
    grid-template-columns: 1fr 28%;
  }

  @media screen and (max-width: 50em) {
    min-height: 100%;
    min-height: 100dvh;
    min-height: 100svh;
    grid-template-columns: 100%;
    grid-template-rows: auto auto 1fr;
    padding: 1rem 0.7rem;
  }
`;

export const CardContainer = styled.div`
  /* outline: 1px solid green; */
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  gap: 1rem;

  @media screen and (max-width: 50em) {
    grid-row: 2;
    /* grid-template-rows: repeat(3, auto); */
    /* overflow-x: cl;
    overflow-y: clip; */

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Card = styled.div`
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 11rem;
  background-color: ${(props) => props.$bgColor && `${props.$bgColor}`};
  color: #17295e;
`;

export const CardTitle = styled.p`
  font-size: 1.09rem;
  font-weight: var(--fw-bold);
`;

export const ExtraCardInfo = styled.p`
  font-size: 1rem;
`;

export const AmountValue = styled.p`
  font-size: 2.3rem;
  display: flex;
  align-items: center;
`;

export const RupeeSymbol = styled.span`
  font-family: "Courier New", Courier, monospace;
`;

export const Aside = styled.aside`
  /* outline: 1px solid purple; */
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
  gap: 1rem;
`;

export const FiltersWrapper = styled.div`
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
  border-radius: 2rem;
  /* border: 3px dashed black; */
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 1rem 1rem 1.7rem 1rem;
  background-color: var(--clr-neutral-100);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media screen and (max-width: 50em) {
    position: fixed;
    /* inset: 40% 0 0 0; */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80vh;
    height: 80dvh;
    height: 80svh;
    z-index: 1000;
    border-radius: 0;
    overflow-y: auto;
    box-shadow: 0px 0px 4rem rgba(0, 0, 0, 0.5);
    transform: ${({ $toggleDisplay }) =>
      $toggleDisplay ? `translateY(0)` : "translateY(100%);"};
    transition: transform 300ms ease-in-out;
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  p {
    font-size: clamp(1rem, 1.5vw + 1rem, 1.5rem);
    font-weight: var(--fw-bold);
  }

  @media screen and (max-width: 50em) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const FilterLabel = styled.p`
  font-weight: var(--fw-bold);
`;

export const BankWrapper = styled.div`
  grid-column: 2;
  grid-row: 3 / span 2;
  border-radius: 2rem;
  width: 100%;
  /* border: 4px dashed black; */
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  padding: 1rem 1rem 1.7rem 1rem;
  background-color: var(--clr-neutral-100);
  /* background-color: #ffe4b5; */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  @media screen and (max-width: 50em) {
    position: fixed;
    /* inset: 65% 0 0 0; */
    bottom: 0;
    left: 0;
    width: 100%;
    height: 65vh;
    height: 65dvh;
    height: 65svh;
    z-index: 1000;
    border-radius: 0;
    box-shadow: 0px 0px 4rem rgba(0, 0, 0, 0.5);
    transform: ${({ $toggleDisplay }) =>
      $toggleDisplay ? `translateY(0)` : "translateY(100%);"};
    transition: transform 300ms ease-in-out;
  }
`;

export const BankHeader = styled(FilterHeader)`
  @media screen and (max-width: 50em) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const CurrentBankDetailContainer = styled.div`
  /* outline: 1px solid green; */
  border: 1px solid
    ${(props) =>
      props.$isBankAccount === true
        ? "hsl(120, 100%, 10%)"
        : props.$isBankAccount === false
        ? "hsl(0, 100%, 50%)"
        : "#000"};
  border-radius: 0.5rem;
  background-color: ${(props) =>
    props.$isBankAccount === true
      ? "hsl(120, 100%, 90%)"
      : props.$isBankAccount === false
      ? "hsl(0, 100%, 90%)"
      : "#fff"};

  color: ${(props) =>
    props.$isBankAccount === true
      ? "hsl(140, 100%, 30%)"
      : props.$isBankAccount === false
      ? "hsl(0, 100%, 30%)"
      : "#000"};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  padding-block: 0.8rem;
  padding-inline: 1rem;
  width: 100%;

  .icon {
    font-size: 1.9rem;
  }
  p {
    font-size: 1.1rem;
  }
`;

export const BankButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PaymentWrapper = styled.div`
  border-top: 1px solid lightgray;
  grid-column: 1 / span 1;
  grid-row: 2 / span 3;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media screen and (max-width: 50em) {
    /* display: none; */
    grid-row: 3;
  }
`;

export const PaymentStatusFitlerContainer = styled.div`
  /* outline: 1px solid red; */
  padding-block: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  max-height: 5rem;
`;

export const PaymentFilterButton = styled.button`
  outline: none;
  border: none;
  appearance: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: var(--clr-neutral-100);
  color: #333;
  text-align: center;
  text-decoration: none;
  user-select: none;
  touch-action: manipulation;
  will-change: transform;
  user-select: none;

  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    /* background-color: #1a1a1a; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
    background: var(--clr-neutral-900);
    color: var(--clr-neutral-100);
  }

  ${({ $active }) =>
    $active &&
    `
    box-shadow: none;
    transform: translateY(0);
    background: var(--clr-neutral-900);
    color: var(--clr-neutral-100);
  `}
`;

export const PaymentFeeAmount = styled.span`
  font-weight: var(--fw-bold);
`;

export const CloseButton = styled.button`
  font-size: 1.5rem;
  border: none;
  outline: none;
  background: transparent;
  display: none;

  @media screen and (max-width: 50em) {
    display: block;
  }
`;

export const MobileHeaderContainer = styled.div`
  display: none;
  @media screen and (max-width: 50em) {
    display: flex;
    max-height: 5rem;
    /* padding-inline: 1rem; */
    align-items: center;
    justify-content: space-between;
  }
`;

export const TransferButton = styled.button`
  outline: none;

  border-radius: 0.5rem;
  cursor: pointer;
  text-align: center;
  padding: 0.5rem 0.9rem;
  display: inline-flex;
  gap: 0.3rem;
  align-items: center;
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  background-color: var(--clr-neutral-100);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    color: #fff;
    background-color: rgb(33, 33, 33);
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
`;
