import { useSelector } from "react-redux";
import {
  getBoardOptions,
  getClassesOptions,
} from "../../../../redux/slices/optionsSlice";
import { stateList } from "../../../../utils/stateList";
import {
  BtnContainer,
  Container,
  DetailsForm,
  FormBody,
  Header,
  InputContainer,
  PersonalDetailsContainer,
  PhotoDisclaimer,
  PhotoUploadContainer,
  ProfilePhotoPreview,
  SubmitBtn,
} from "./enterDetailsFormDetails";

const EnterDetailsForm = ({
  detailsData,
  changePhoto,
  photoPreview,
  handleSubmitDetailsData,
  handleChangeFormData,
  handleOnlyNumbers,
  photo,
  isPhotoResizing,
}) => {
  const boardOptions = useSelector(getBoardOptions);
  const classsesOptions = useSelector(getClassesOptions);

  const controlSubmitBtnDisability = () => {
    const isFieldEmpty = Object.values(detailsData).some((item) => {
      return item === "";
    });

    return isFieldEmpty;
  };

  return (
    <Container>
      <Header>
        <p>Enter Your Details</p>
      </Header>
      <DetailsForm onSubmit={handleSubmitDetailsData}>
        <FormBody>
          <PhotoUploadContainer>
            <ProfilePhotoPreview>
              <img src={photoPreview} alt="" />
            </ProfilePhotoPreview>
            <label htmlFor="photo">
              {isPhotoResizing ? (
                <p>Resizing Photo...</p>
              ) : (
                <>
                  <i className="fas fa-camera-retro"></i>
                  <p>Upload your photo</p>
                </>
              )}
            </label>
            <input
              type="file"
              name="photo"
              id="photo"
              accept="image/png, image/jpeg"
              onChange={changePhoto}
              disabled={isPhotoResizing}
              required
            />
            <PhotoDisclaimer>
              <p>
                The photo is displayed to teachers for idetification purposes
                only after they accept your request.
              </p>
            </PhotoDisclaimer>
          </PhotoUploadContainer>
          <PersonalDetailsContainer>
            <InputContainer>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                id="name"
                value={detailsData.name}
                onChange={handleChangeFormData}
                required
              />
            </InputContainer>
            <InputContainer>
              <label htmlFor="dob">Date of Birth</label>
              <input
                type="date"
                name="dob"
                id="dob"
                value={detailsData.dob}
                onChange={handleChangeFormData}
                required
              />
            </InputContainer>
            <InputContainer>
              <label htmlFor="gender"> Gender </label>
              <select
                name="gender"
                id="gender"
                value={detailsData.gender}
                onChange={handleChangeFormData}
                required
              >
                <option value="" disabled hidden>
                  Select
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </InputContainer>
            <InputContainer>
              <label htmlFor="parentName">
                Parent's Name
                <p className="disclaimer">Either father's or mother's</p>
              </label>
              <input
                type="text"
                name="parentName"
                id="parentName"
                value={detailsData.parentName}
                onChange={handleChangeFormData}
                required
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="studentPhone">
                Your Phone Number
                <span className="disclaimer">
                  If you do not have a phone number please provide your parent's
                  number.
                </span>
              </label>
              <input
                type="tel"
                name="studentPhone"
                id="studentPhone"
                value={detailsData.studentPhone}
                onChange={handleOnlyNumbers}
                maxLength={10}
                required
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="parentPhone">Your Parent's Phone Number</label>
              <input
                type="tel"
                name="parentPhone"
                id="parentPhone"
                value={detailsData.parentPhone}
                onChange={handleOnlyNumbers}
                maxLength={10}
                required
              />
            </InputContainer>

            <InputContainer>
              <label htmlFor="state">State</label>
              <select
                name="state"
                id="state"
                value={detailsData.state}
                onChange={handleChangeFormData}
                required
              >
                <option value="" disabled hidden>
                  Select
                </option>
                {stateList.map((state) => (
                  <option key={state.stateAbbr} value={state.stateAbbr}>
                    {state.stateName}
                  </option>
                ))}
              </select>
            </InputContainer>
            <InputContainer>
              <label htmlFor="address">Full Address</label>
              <input
                type="text"
                name="address"
                id="address"
                value={detailsData.address}
                onChange={handleChangeFormData}
                required
              />
            </InputContainer>
            <InputContainer>
              <label htmlFor="standard">Your Class</label>
              <select
                name="standard"
                id="standard"
                onChange={handleChangeFormData}
                value={detailsData.standard}
              >
                <option hidden disabled value="">
                  Select
                </option>
                <>
                  {classsesOptions.lower_primary?.length > 0 && (
                    <optgroup label="LOWER PRIMARY">
                      {classsesOptions.lower_primary.map((cls) => (
                        <option
                          key={cls.name_of_class}
                          value={cls.name_of_class}
                        >
                          {cls.name_of_class}
                        </option>
                      ))}
                    </optgroup>
                  )}
                  {classsesOptions.upper_primary?.length > 0 && (
                    <optgroup label="UPPER PRIMARY">
                      {classsesOptions.upper_primary.map((cls) => (
                        <option
                          key={cls.name_of_class}
                          value={cls.name_of_class}
                        >
                          {cls.name_of_class}
                        </option>
                      ))}
                    </optgroup>
                  )}
                  {classsesOptions.secondary?.length > 0 && (
                    <optgroup label="SECONDARY">
                      {classsesOptions.secondary.map((cls) => (
                        <option
                          key={cls.name_of_class}
                          value={cls.name_of_class}
                        >
                          {cls.name_of_class}
                        </option>
                      ))}
                    </optgroup>
                  )}
                  {classsesOptions.higher_secondary?.length > 0 && (
                    <optgroup label="HIGHER SECONDARY">
                      {classsesOptions.higher_secondary.map((cls) => (
                        <option
                          key={cls.name_of_class}
                          value={cls.name_of_class}
                        >
                          {cls.name_of_class}
                        </option>
                      ))}
                    </optgroup>
                  )}
                  {classsesOptions.graduation?.length > 0 && (
                    <optgroup label="GRADUATION">
                      {classsesOptions.graduation.map((cls) => (
                        <option
                          key={cls.name_of_class}
                          value={cls.name_of_class}
                        >
                          {cls.name_of_class}
                        </option>
                      ))}
                    </optgroup>
                  )}
                  {classsesOptions.post_graduation?.length > 0 && (
                    <optgroup label="POST GRADUATION">
                      {classsesOptions.post_graduation.map((cls) => (
                        <option
                          key={cls.name_of_class}
                          value={cls.name_of_class}
                        >
                          {cls.name_of_class}
                        </option>
                      ))}
                    </optgroup>
                  )}
                </>
              </select>
            </InputContainer>
            <InputContainer>
              <label htmlFor="schoolName">Your School's Name</label>
              <input
                type="text"
                name="schoolName"
                id="schoolName"
                value={detailsData.schoolName}
                onChange={handleChangeFormData}
                required
              />
            </InputContainer>
            <InputContainer>
              <label htmlFor="board">Your Current Board</label>
              <select
                name="board"
                id="board"
                onChange={handleChangeFormData}
                value={detailsData.board}
              >
                <option hidden disabled value="">
                  Select
                </option>
                {boardOptions?.length > 0 ? (
                  boardOptions.map((element, index) => {
                    return (
                      <option key={index} value={element.board_name}>
                        {element.board_name}
                      </option>
                    );
                  })
                ) : (
                  <option>No options</option>
                )}
              </select>
            </InputContainer>
          </PersonalDetailsContainer>
        </FormBody>
        <BtnContainer>
          <SubmitBtn disabled={controlSubmitBtnDisability()} type="submit">
            Submit
          </SubmitBtn>
        </BtnContainer>
      </DetailsForm>
    </Container>
  );
};

export default EnterDetailsForm;
