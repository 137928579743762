export const stateList = [
  { stateAbbr: "AN", stateName: "Andaman and Nicobar" },
  { stateAbbr: "AP", stateName: "Andhra Pradesh" },
  { stateAbbr: "AR", stateName: "Arunachal Pradesh" },
  { stateAbbr: "AS", stateName: "Assam" },
  { stateAbbr: "BR", stateName: "Bihar" },
  { stateAbbr: "CH", stateName: "Chandigarh" },
  { stateAbbr: "DN", stateName: "Dadra and Nagar Haveli" },
  { stateAbbr: "DD", stateName: "Daman and Diu" },
  { stateAbbr: "DL", stateName: "Delhi" },
  { stateAbbr: "GA", stateName: "Goa" },
  { stateAbbr: "GJ", stateName: "Gujarat" },
  { stateAbbr: "HR", stateName: "Haryana" },
  { stateAbbr: "HP", stateName: "Himachal Pradesh" },
  { stateAbbr: "JK", stateName: "Jammu and Kashmir" },
  { stateAbbr: "KA", stateName: "Karnataka" },
  { stateAbbr: "KL", stateName: "Kerala" },
  { stateAbbr: "LD", stateName: "Lakshadweep" },
  { stateAbbr: "MP", stateName: "Madhya Pradesh" },
  { stateAbbr: "MH", stateName: "Maharashtra" },
  { stateAbbr: "MN", stateName: "Manipur" },
  { stateAbbr: "ML", stateName: "Meghalaya" },
  { stateAbbr: "MZ", stateName: "Mizoram" },
  { stateAbbr: "NL", stateName: "Nagaland" },
  { stateAbbr: "OR", stateName: "Orissa" },
  { stateAbbr: "PY", stateName: "Pondicherry" },
  { stateAbbr: "PN", stateName: "Punjab" },
  { stateAbbr: "RJ", stateName: "Rajasthan" },
  { stateAbbr: "SK", stateName: "Sikkim" },
  { stateAbbr: "TN", stateName: "Tamil Nadu" },
  { stateAbbr: "TR", stateName: "Tripura" },
  { stateAbbr: "UP", stateName: "Uttar Pradesh" },
  { stateAbbr: "WB", stateName: "West Bengal" },
];
