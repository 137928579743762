import {
  ArrowIcon,
  Button,
  Option,
  OptionsList,
  SelectContainer,
} from "./selectBtnStyles";

const CustomSelectButton = ({
  options = [],
  isOpen = false,
  onToggle = () => {},
  value = "",
  handleSelect = () => {},
  buttonStyles = {
    buttonWidth: "100%",
    buttonBackgroundColor: "#fff",
  },
}) => {
  const { buttonWidth, buttonBackgroundColor } = buttonStyles;

  return (
    <SelectContainer>
      <Button
        buttonWidth={buttonWidth}
        buttonBackgroundColor={buttonBackgroundColor}
        $isOpen={isOpen}
        onClick={onToggle}
      >
        {value}
        <ArrowIcon $isOpen={isOpen}>
          <i className="fas fa-caret-down"></i>
        </ArrowIcon>
      </Button>
      <OptionsList
        buttonWidth={buttonWidth}
        buttonBackgroundColor={buttonBackgroundColor}
        $isOpen={isOpen}
      >
        {options.map((option, index) => (
          <Option
            key={index}
            onClick={() => handleSelect(option.value, option.label)}
          >
            {option.label}
          </Option>
        ))}
      </OptionsList>
    </SelectContainer>
  );
};

export default CustomSelectButton;
