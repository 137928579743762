import {
  Form,
  InputContainer,
  Message,
  SendBtn,
  TitleContainer,
} from "./contactformStyles";

const FormFragments = ({
  formData,
  disableSendBtn,
  changeFormData,
  onlyNumber,
  submitFormData,
}) => {
  return (
    <>
      <TitleContainer>
        <i className="fas fa-pen"></i>
        <p>Write to us</p>
      </TitleContainer>
      <Form onSubmit={submitFormData}>
        <InputContainer>
          <label htmlFor="name">Name</label>
          <input
            onChange={changeFormData}
            value={formData.name}
            placeholder="your name"
            type="text"
            name="name"
            id="name"
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="email">Email</label>
          <input
            onChange={changeFormData}
            value={formData.email}
            placeholder="your email"
            type="email"
            name="email"
            id="email"
            maxLength={100}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="phone">Phone</label>
          <input
            onChange={onlyNumber}
            value={formData.phone}
            type="tel"
            placeholder="your phone number"
            name="phone"
            id="phone"
            maxLength={10}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="message">Message</label>
          <Message
            onChange={changeFormData}
            value={formData.message}
            name="message"
            id="message"
            cols="30"
            rows="5"
            placeholder="please write within 150 characters"
            maxLength={150}
          />
        </InputContainer>
        <SendBtn type="submit" disabled={disableSendBtn()}>
          <i className="fas fa-paper-plane"></i>Send
        </SendBtn>
      </Form>
    </>
  );
};

export default FormFragments;
