import { Content, ContentHeader } from "./privacyStyles";

const UserAccess = () => {
  return (
    <Content id="user_access">
      <ContentHeader>
        <p>6. User Access</p>
      </ContentHeader>
      <p>
        You may be allowed to access and view the Platform as a guest but to
        have access to all the features and benefits on our Platform, you are
        required to first register (either as a student or as a teacher) on our
        Platform. Once you register as student/ teacher then you are required to
        provide certain Personal Information when asked after registration. As a
        registered User, you can modify some of your Personal Information by
        accessing the edit profile section of this platform.
      </p>
    </Content>
  );
};

export default UserAccess;
