import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.main)`
  /* outline: 2px solid red; */
  user-select: none;
  min-height: calc(100vh - 4.375rem);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 480px) {
    padding: 0.8rem 0.8rem;
  }
`;

export const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (max-width: 480px) {
    min-height: 80vh;
  }
`;

export const IntroAndImgContainer = styled.div`
  /* outline: 1px solid green; */
  display: flex;
  gap: 1rem;
  min-height: 70vh;
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const Headline = styled.div`
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  p {
    font-size: clamp(3rem, 2vw + 2.875rem, 5rem);
    font-family: "Fraunces", serif;
    color: #082f62;
  }

  @media (max-width: 480px) {
    p {
      font-size: 3.2rem;
      justify-content: unset;
      text-align: left;
    }
  }
`;

export const Intro = styled.div`
  /* outline: 1px solid red; */
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  p {
    font-size: clamp(1.2rem, 1.5vw + 0.313rem, 2rem);
    font-family: "Commissioner", sans-serif;
    color: rgb(61, 61, 61);
    text-align: justify;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    font-size: clamp(1.2rem, 1.5vw + 0.313rem, 2rem);
    font-family: "Commissioner", sans-serif;
    width: fit-content;
    background: #ff0000;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 0.62rem;
    transition: 270ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;

    &:hover {
      background: #b30000;
    }
  }

  @media screen and (max-width: 480px) {
    p {
      font-size: 0.9rem;
    }
  }
`;

export const TandSImgContainer = styled(motion.div)`
  /* outline: 1px solid pink; */
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: clamp(15rem, 26.1vw + 1rem, 28rem);
  }
`;

export const StudentMainContainer = styled.div`
  /* outline: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 100%;
  min-height: 80vh;
`;

export const StudenSeperator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: orange;
  height: 0.3rem;
  border-radius: 25rem;
  width: 70%;
`;

export const StudentIntroImageContainer = styled.div`
  /* outline: 3px solid red; */
  display: flex;
  /* flex: 1; */
  margin: auto;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const StudentIntroSection = styled.div`
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  gap: 3rem;

  @media screen and (max-width: 480px) {
    gap: 0.5rem;
  }
`;

export const StudentHeadline = styled.div`
  /* outline: 1px solid green; */
  display: flex;
  width: 100%;
  p {
    font-size: clamp(2rem, 2vw + 2.875rem, 5rem);
    font-family: "Fraunces", serif;
    color: #082f62;
  }

  @media screen and (max-width: 480px) {
    p {
      font-size: 2rem;
    }
  }
`;

export const StudentIntroPara = styled.div`
  /* outline: 1px solid blue; */
  /* flex-basis: 50%; */
  display: flex;

  justify-content: center;
  align-items: center;
  p {
    font-size: 1.3rem;
    font-family: "Commissioner", sans-serif;
    color: rgb(61, 61, 61);
    text-align: justify;
  }

  @media screen and (max-width: 480px) {
    p {
      font-size: 0.9rem;
    }
  }
`;

export const StudentImage = styled.div`
  /* outline: 1px solid green; */
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    /* outline: 1px solid pink; */
    width: 31rem;
  }
`;

export const TeacherMainContainer = styled.div`
  /* outline: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 100%;
  min-height: 80vh;
`;

export const TeacherSeperator = styled.div`
  position: absolute;
  background-color: orange;
  top: 0;
  right: 0;
  height: 0.3rem;
  border-radius: 25rem;
  width: 70%;
`;

export const TeacherIntroSection = styled.div`
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 3rem;
  flex-basis: 50%;

  @media screen and (max-width: 480px) {
    gap: 0.5rem;
  }
`;
export const TeacherIntroImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const TeacherHeadline = styled.div`
  /* outline: 1px solid green; */
  display: flex;
  width: 100%;
  p {
    font-size: clamp(3rem, 2vw + 2.875rem, 5rem);
    font-family: "Fraunces", serif;
    color: #082f62;
  }
  @media screen and (max-width: 480px) {
    p {
      font-size: 2rem;
    }
  }
`;

export const TeacherIntro = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.3rem;
    font-family: "Commissioner", sans-serif;
    color: rgb(61, 61, 61);
    text-align: justify;
  }

  @media screen and (max-width: 480px) {
    p {
      font-size: 0.9rem;
    }
  }
`;

export const TeacherImage = styled.div`
  /* outline: 1px solid green; */
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    /* outline: 1px solid pink; */
    width: 31rem;
  }
`;
