import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

// export const baseURL = "http://192.168.29.59:8000/api";
// export const baseURL = "http://127.0.0.1:8000/api";
export const baseURL = "https://api.schoolies.in/api";
export const baseWs = "ws://127.0.0.1:8000/ws";

export const staticUrl =
  "https://schoolies-spaces.sgp1.digitaloceanspaces.com/static/";
// creating the axios instance

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 60 * 10000,
  withCredentials: true,
  headers: {
    Authorization: localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : null,
  },
});

// intercepting the request
axiosInstance.interceptors.request.use(async (req) => {
  // condition to check if token available
  if (localStorage.getItem("token")) {
    // this value is being set from local storage
    req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;

    let user = jwtDecode(localStorage.getItem("token"));

    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) {
      return req;
    }

    try {
      let response = await axios.post(`${baseURL}/account/token/refresh/`, {
        refresh: localStorage.getItem("revive"),
      });

      localStorage.setItem("token", response.data.access);
      localStorage.setItem("revive", response.data.refresh); // refresh

      // this value is being set from the response recieved
      req.headers.Authorization = `Bearer ${response.data.access}`;
      return req;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          localStorage.clear();
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log(error.message);
      }
    }
  }

  // * setting req.headers.Authorization to null if there is no token in local storage.
  req.headers.Authorization = null;
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      const responseData = error.response.data;
      if (
        responseData.code === "token_not_valid" &&
        responseData.detail === "Token is invalid or expired"
      ) {
        localStorage.clear();
      }
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

// * Might intercept response if needed

export default axiosInstance;
