import { Content, ContentHeader } from "./privacyStyles";

const WeCollectInfo = () => {
  return (
    <Content id="we_collect_info">
      <ContentHeader>
        <p>2. We Collect Information</p>
      </ContentHeader>
      <p>
        In order to access the Platform, you will need to register (as a teacher
        or as a student) with us. We ask for basic information which may include
        your name, an email address, state of residence, country, and password,
        along with a username and mobile number, adhaar number and some other
        details.
      </p>
      <p>
        If you are a teacher, we may also collect certain additional information
        from you to enable your public profile. Your public profile will only
        contain Personal Information that are required for students or Users to
        know you (as applicable) viz., your name, your educational
        qualifications, profession, experience, location, classes you take to
        teach & subjects, your photo, fees you charge per months and such
        similar information that may help or enable a student or other User to
        know you better. The information in your public profile will be based on
        the information you provide to us or as updated by you on such
        applicable sections. If you are a teacher registered with us, then, we
        also collect aadhaar number from you to complete our verification
        process and same with the students as well. When you write to us
        (contact us) with a question or to ask for help in our platform, we will
        keep that data for future reference (if required).
      </p>
    </Content>
  );
};

export default WeCollectInfo;
