import { useLayoutEffect, useState } from "react";
import CustomButton from "../../components/Button/CustomButton";
import { scroll_to_top } from "../../utils/scrollActions";
import Content from "./Content Container/Content";
import Filters from "./Filters/Filters";
import Searchbar from "./Searchbar/Searchbar";
import {
  ButtonContainer,
  Container,
  QueryHeader,
  Wrapper,
} from "./queryStyles";

const Query = () => {
  const [activeBtn, setActiveBtn] = useState(null);

  const [toggleFilterDisplay, setToggleFilterDisplay] = useState(false);

  const [toggleSearchDisplay, setSearchToggleDisplay] = useState(false);

  const handleToggleFilterDisplay = (e = null) => {
    setToggleFilterDisplay((curr) => !curr);
    if (e) {
      setActiveBtn(e.target.id);
    } else {
      setActiveBtn(null);
    }
  };

  const handleSearchbarToggleDisplay = (e = null) => {
    setSearchToggleDisplay((curr) => !curr);
    if (e) {
      setActiveBtn(e.target.id);
    } else {
      setActiveBtn(null);
    }
  };

  useLayoutEffect(() => {
    scroll_to_top();
  }, []);

  return (
    <Wrapper>
      <QueryHeader>
        <Searchbar
          toggleSearchDisplay={toggleSearchDisplay}
          handleToggleSearchDisplay={handleSearchbarToggleDisplay}
        />
        <ButtonContainer>
          <CustomButton
            buttonLabel="Search"
            variant="outline"
            id="search_button"
            icon={<i className="fas fa-search"></i>}
            onClick={handleSearchbarToggleDisplay}
            currentActiveBtn={activeBtn}
          />
          <CustomButton
            buttonLabel="Filters"
            variant="outline"
            id="filters_button"
            icon={<i className="fas fa-filter"></i>}
            onClick={handleToggleFilterDisplay}
            currentActiveBtn={activeBtn}
          />
        </ButtonContainer>
      </QueryHeader>
      <Container>
        <Filters
          handleFilterToggleDisplay={handleToggleFilterDisplay}
          toggleFilterDisplay={toggleFilterDisplay}
        />
        <Content />
      </Container>
    </Wrapper>
  );
};

export default Query;
