import { useState } from "react";
import { Helmet } from "react-helmet";
import { changeNewPassword } from "../../api/authApi";
import { NotificationBar } from "../notificationBar";
import LoadingBar from "react-top-loading-bar";

import {
  Container,
  Heading,
  InputContainer,
  PasswordChangeForm,
  SubmitBtn,
} from "./changePasswordStyles";

const ChangePassword = () => {
  // states for passwords
  const [newPassword, setNewPassword] = useState("");
  const [conPassword, setConPassword] = useState("");

  // states for loading bar
  const [progress, setProgress] = useState(0);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const handleConPasswordChange = (e) => {
    setConPassword(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setProgress(20);
    if (newPassword !== conPassword) {
      NotificationBar(
        "New Password and Confirm New Password did not match.",
        "error"
      );
      setProgress(100);
      return;
    }

    try {
      setProgress(40);
      let response = await changeNewPassword(newPassword);
      setProgress(90);
      if (response.status === 200) {
        NotificationBar("Password changed successfully", "success");
        setNewPassword("");
        setConPassword("");
        setProgress(100);
      } else {
        NotificationBar("Someting went wrong. Please try again later", "error");
        setProgress(100);

        return;
      }
    } catch (error) {
      NotificationBar("Server error. Please try again later", "error");
      setProgress(100);

      return;
    }
  };

  const controlSubmitDisability = () => {
    if (newPassword === "" || conPassword === "") {
      return true;
    }
    return false;
  };

  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Container>
        <PasswordChangeForm onSubmit={handleFormSubmit}>
          <Heading>
            <p>Change Your Password</p>
          </Heading>
          <InputContainer>
            <label htmlFor="newPassword">New Password</label>
            <input
              value={newPassword}
              onChange={handleNewPasswordChange}
              type="password"
              name="newPassword"
              id="newPassword"
              autoComplete="newPassword"
              required
            />
          </InputContainer>
          <InputContainer>
            <label htmlFor="conPassword">Confirm New Password</label>
            <input
              value={conPassword}
              onChange={handleConPasswordChange}
              type="password"
              name="conPassword"
              id="conPassword"
              autoComplete="newPassword"
              required
            />
          </InputContainer>
          <SubmitBtn disabled={controlSubmitDisability()} type="submit">
            Change Password
          </SubmitBtn>
        </PasswordChangeForm>
      </Container>
    </>
  );
};

export default ChangePassword;
