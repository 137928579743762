import { TableContentLink, TableContentContainer } from "./t&cMainStyles";

const TableContents = () => {
  return (
    <TableContentContainer>
      <ol>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="about_platform_services"
          >
            About platform services
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="right_to_student_access"
          >
            Right to access for students
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="right_to_teacher_access"
          >
            Right to access for teachers
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="security"
          >
            Security
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="fee-payment"
          >
            Fee Payment
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="refund_policy"
          >
            Refund policy
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="processing_of_personal_info"
          >
            Processing of personal information
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="schoolies_rights"
          >
            Schoolies' rights
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="platform_availability"
          >
            Platform availability
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="account_deletion"
          >
            Deletion of account
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="contact_user_support"
          >
            Contact for user support
          </TableContentLink>
        </li>
      </ol>
    </TableContentContainer>
  );
};

export default TableContents;
