import { Content, ContentHeader } from "./t&cMainStyles";

const Security = () => {
  return (
    <Content id="security">
      <ContentHeader>
        <p>4. Security</p>
      </ContentHeader>
      <p>
        You are responsible for maintaining the confidentiality of your account
        and password to access the Platform. You acknowledge that your account
        is personal to you and agree not to provide any other person with access
        to the Platform and to restrict access to your device to prevent any
        unauthorized access to your account. You agree to accept responsibility
        for all activities that occur under your account.{" "}
      </p>
      <p>
        You should use particular caution when accessing your account from a
        public or shared computer so that others are not able to view or record
        your username, password, or other Personal Information. You should take
        all necessary steps to ensure that the password is kept confidential and
        secure at all times, and if you have any reason to believe that your
        password has become known to anyone else, or if the password is being,
        or is likely to be, used in an unauthorized manner, you agree to
        immediately change your password or inform us of any unauthorized access
        to or use of your username or password, so that we are able to help you
        stop or prevent such unauthorized access. Please ensure that the details
        you provide us are correct and complete.
      </p>
      <p>
        You represent that the information provided by you at the time of the
        registration are correct, true and accurate, and you agree to update the
        same as and when there is any change in the said information. Please
        read our Privacy Policy to understand how we handle your information.
        Please note that we reserve the right to reject or put on-hold your
        registration on and also reserve the right to refuse access to the
        Platform, terminate accounts, at any time without providing any notice
        to you, and/or reserve the right to disable any account, if you have
        violated any provision of the platform.
      </p>
    </Content>
  );
};

export default Security;
