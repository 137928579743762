import { Content, ContentHeader } from "./t&cMainStyles";

const ProcessingOfPersonalInfo = () => {
  return (
    <Content id="processing_of_personal_info">
      <ContentHeader>
        <p>7. Processing of Personal Information</p>
      </ContentHeader>
      <p>
        All our collection, processing, sharing and storing of any Personal
        Information collected from you shall be in accordance with our Privacy
        Policy. Kindly read the same to understand the security measured
        undertaken by Schoolies to safeguard your Personal Information
      </p>
    </Content>
  );
};

export default ProcessingOfPersonalInfo;
