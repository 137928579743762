import { useQuery } from "@tanstack/react-query";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import AnimatedRoutes from "./components/AnimatedRoutes";
import useAnalyticsPageView from "./hooks/useAnalyticsPageView";
import { fetchOptions } from "./redux/actions/optionsActions";
import { getOptionsStatus } from "./redux/slices/optionsSlice";
import LoadingBar from "react-top-loading-bar";
import { getProgress, setProgress } from "./redux/slices/loadingbarSlice";
import { Helmet } from "react-helmet";
import { getMetaDataStates } from "./redux/slices/metaDataSlice";
function App() {
  const dispatch = useDispatch();

  const optionsStatus = useSelector(getOptionsStatus);

  const progress = useSelector(getProgress);

  const { title, description } = useSelector(getMetaDataStates);

  const getChoices = useQuery({
    queryKey: ["getChoices"],
    queryFn: () => dispatch(fetchOptions()),
    enabled: optionsStatus === "idle",
    refetchOnWindowFocus: false,
    retry: 2,
  });

  // Initialize Google Analytics
  ReactGA.initialize("G-JH9FPV7EW1");

  useAnalyticsPageView();

  return (
    <>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => dispatch(setProgress(0))}
        height={4}
      />
      <Helmet>
        <title>{title}</title>

        {description !== null && (
          <meta name="description" content={`${description}`} />
        )}
      </Helmet>
      <ToastContainer />
      <AnimatedRoutes />
    </>
  );
}

export default App;
