import styled from "styled-components";

export const Header = styled.div`
  border-bottom: 1px solid var(--color-bb);
  background-color: var(--clr-neutral-100);
  display: flex;
  gap: 1rem;
  padding: 1rem;
`;

export const Button = styled.button`
  outline: none;
  appearance: none;
  border: 0.06em solid #ccc;
  background-color: transparent;
  /* border-radius: 0.9375em; */
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  text-align: center;
  gap: 0.6em;
  justify-content: space-between;
  font-size: 1rem;
  padding: 0.6em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.active,
  &:hover {
    border: 0.06em solid #000;
    color: #fff;
    background-color: #1a1a1a;
  }
`;

export const PaidHeaderContainer = styled.div`
  background-color: var(--clr-neutral-100);
  display: flex;
  gap: 1rem;
  @media screen and (max-width: 20em) {
    flex-direction: column;
  }
`;

export const FilterContainer = styled.div`
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-weight: var(--fw-bold);
`;
