import { Suspense, lazy, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CardContainer } from "../smStyles";

const PendingCards = lazy(() => import("./PendingCards"));

const PendingPayment = ({ invoices = [] }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [pendingInvoices, setPendingInvoices] = useState(null);
  useEffect(() => {
    if (invoices.length > 0) {
      setPendingInvoices(invoices);
    }
  }, [invoices]);

  const rmvInvoiceAfterPayment = (invoiceId) => {
    if (pendingInvoices?.length > 0) {
      let newArr = pendingInvoices.filter((item) => {
        return item.id !== invoiceId;
      });

      setPendingInvoices(newArr);
    }
  };

  useEffect(() => {
    if (
      searchParams.get("month") !== null &&
      searchParams.get("year") !== null
    ) {
      searchParams.delete("month");
      searchParams.delete("year");
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return (
    <CardContainer>
      {pendingInvoices?.length > 0 ? (
        pendingInvoices?.map((element, index) => (
          <Suspense key={index} fallback={<div>Loading...</div>}>
            <PendingCards
              detail={element}
              removeCard={rmvInvoiceAfterPayment}
            />
          </Suspense>
        ))
      ) : (
        <p>No pending fees</p>
      )}
    </CardContainer>
  );
};

export default PendingPayment;
