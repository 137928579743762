import axiosInstance from "../utils/axiosInstance";

export const userRegister = async ({ email, password, regd_as }) => {
  const body = {
    email,
    password,
    regd_as,
  };

  try {
    const response = await axiosInstance.post(`/account/signup/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};
