import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getTeacherDetails } from "../../api/applicationFormApi";
import { NotificationBar } from "../../components/notificationBar";
import { setMetaData } from "../../redux/slices/metaDataSlice";
import { scroll_to_top } from "../../utils/scrollActions";
import TeacherDetails from "./Details/TeacherDetails";
import ApplicationForm from "./Form/ApplicationForm";
import MobileHeader from "./Mobile Header/MobileHeader";
import { Wrapper } from "./applyStyles";

const ApplyForTuitionLayout = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [toggleFormDisplay, setToggleFormDisplay] = useState(false);

  const {
    data: teacherDetail,
    isError,
    error,
  } = useQuery({
    queryKey: ["enrollment/teacherDetails", id],
    queryFn: () => getTeacherDetails(id),
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isError) {
      if (error.response.status === 400) {
        NotificationBar("No such teacher exists", "error", 4000);
      } else {
        NotificationBar(
          "We are unable to fetch the details of the teacher at the moment. Please try again later",
          "error",
          4000
        );
      }
    }
  }, [isError]);

  useEffect(() => {
    scroll_to_top();
    dispatch(setMetaData({ title: "Apply for tuition" }));
  }, []);

  const handleToggleFormDisplay = () => {
    setToggleFormDisplay((curr) => !curr);
  };

  return (
    <Wrapper>
      <MobileHeader onCLick={handleToggleFormDisplay} />
      <TeacherDetails teacherDetails={teacherDetail?.data} />
      <ApplicationForm
        toggleFormDisplay={toggleFormDisplay}
        onClose={handleToggleFormDisplay}
        teacherDetail={teacherDetail?.data}
      />
    </Wrapper>
  );
};

export default ApplyForTuitionLayout;
