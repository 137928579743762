import { Content, ContentHeader } from "./privacyStyles";

const ModificationToPrivacyPolicy = () => {
  return (
    <Content id="modification_to_pricvacy_policy">
      <ContentHeader>
        <p>8. Modification To Privacy Policy</p>
      </ContentHeader>
      <p>
        Our Privacy Policy is subject to change at any time without notice. To
        make sure you are aware of any changes, please review this policy
        periodically. These changes will be effective immediately on the Users
        of Schoolies. Please note that at all times you are responsible for
        updating your Personal Information, including to provide us with your
        most current e-mail address and mobile number. Use of Schoolies after
        any change to this Privacy Policy shall indicate your acknowledgement of
        such changes and agreement to be bound by the terms and conditions of
        such changes.
      </p>
    </Content>
  );
};

export default ModificationToPrivacyPolicy;
