import styled from "styled-components";

export const Wrapper = styled.main`
  border-top: 1px solid var(--clr-neutral-400);
  min-height: calc(100vh - 4.375rem);

  display: flex;
  gap: 1rem;
  padding: 1rem;

  @media screen and (max-width: 57em) {
    flex-direction: column;
    gap: 2rem;
  }

  @media (max-width: 45em) {
    min-height: calc(100vh - 4.375rem);
    /* min-height: calc(100svh - 4.375rem);
    min-height: calc(100dvh - 4.375rem); */
  }
`;
