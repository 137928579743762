import { useQuery } from "@tanstack/react-query";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { fetchInvoices } from "../../../api/paymentApi";
import { NotificationBar } from "../../../components/notificationBar";
import { setProgress } from "../../../redux/slices/loadingbarSlice";
import { setMetaData } from "../../../redux/slices/metaDataSlice";
import FmHeader from "./Header/FmHeader";
import PendingPayment from "./pending/PendingPayment";
import { Wrapper } from "./smStyles";
const PaidPayment = lazy(() => import("./paid/PaidPayment"));

const StuFeeManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paymentStatus = searchParams.get("payment_status") || "pending";
  const monthParam = searchParams.get("month");
  const yearParam = searchParams.get("year");

  const dispatch = useDispatch();

  const {
    data: invoices,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["student/invoices", paymentStatus, monthParam, yearParam],
    queryFn: () => fetchInvoices({ paymentStatus, yearParam, monthParam }),
    enabled: paymentStatus !== null,
    refetchOnWindowFocus: false,
    retry: 1,
  });

  useEffect(() => {
    dispatch(setMetaData({ title: "Fee Management" }));
  }, []);

  useEffect(() => {
    if (isError) {
      NotificationBar(
        "We are unable to fetch fee details at the moment",
        "error",
        "4000"
      );
    }
  }, [isError, error]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setProgress(70));
    } else {
      dispatch(setProgress(100));
    }
  }, [isLoading]);

  useEffect(() => {
    if (searchParams.get("payment_status") === null) {
      setSearchParams({ payment_status: "pending" });
    }
  }, [setSearchParams, searchParams]);

  const handlePage = () => {
    if (paymentStatus === "paid") {
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <PaidPayment invoices={invoices} />
        </Suspense>
      );
    } else if (paymentStatus === "pending") {
      return <PendingPayment invoices={invoices} />;
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Wrapper>
        <FmHeader />
        {handlePage()}
      </Wrapper>
    </>
  );
};

export default StuFeeManagement;
