import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  background: var(--clr-neutral-100);
  display: flex;
  flex-direction: column;
  flex: 0 0 20rem;
  max-width: 20rem;
  padding: 0.8rem 0.9rem;
  gap: 1.5rem;
  height: max-content;
  font-family: var(--ff-secondary);

  @media screen and (max-width: 60em) and (min-width: 30em) {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    width: 70%;
    height: 100%;
    z-index: 101;
    padding-inline: 1rem;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2),
      0 6px 6px -3px rgba(0, 0, 0, 0.15);

    transition: all 300ms ease-in-out;

    transform: ${({ $toggleDisplay }) =>
      $toggleDisplay ? "translateX(0)" : "translateX(-100%)"};
  }

  @media screen and (max-width: 30em) {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
    width: 100%;
    height: 100%;
    z-index: 101;
    padding-inline: 1rem;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.2),
      0 6px 6px -3px rgba(0, 0, 0, 0.15);

    transition: all 300ms ease-in-out;

    transform: ${({ $toggleDisplay }) =>
      $toggleDisplay ? "translateX(0)" : "translateX(-100%)"};
  }
`;

export const FilterHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 60em) {
    justify-content: space-between;
  }
`;

export const FilterHeading = styled.p`
  font-size: 1.5rem;
  font-weight: var(--fw-bold);
`;

export const FilterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  border-bottom: 2px solid lightgray;
  padding-block: 0.8rem;
  label {
    font-size: 1rem;

    font-weight: var(--fw-bold);
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;
  }

  &:last-of-type {
    border-bottom: 2px solid transparent;
  }
`;

export const Input = styled.input`
  --input-width: 100%;

  &[type="text"] {
    width: setSearchToggleDisplay var(--input-width);
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    font-size: 1rem;
    padding: 0.5rem;
    transition: box-shadow 0.3s linear;
    outline: none;
    &::placeholder {
      font-size: 0.9rem;
    }

    &:focus,
    &:hover {
      border-color: #1ad7e8;
      /* box-shadow: 0 0 10px #1aaee8; */
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CloseButton = styled.button`
  display: none;
  font-size: 1.5rem;
  background: transparent;
  outline: none;
  border: none;
  @media screen and (max-width: 60em) {
    display: block;
  }
`;

const Buttons = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--fw-bold);
  /* min-height: 3.75em; */
  font-family: var(--ff-button);
  min-width: 0;
  outline: none;
  padding: 0.5em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:hover {
    color: #fff;
    /* background-color: #1a1a1a; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #bdc3c7; /* Disabled button color */
    border: 0.06em solid #bdc3c7;
    color: #7f8c8d;
  }
`;

export const ApplyBtn = styled(Buttons)`
  border: 0.06em solid #1b4dff;
  color: #fff;
  background-color: #1b4dff;
`;

export const ClearAllBtn = styled(Buttons)`
  border: 0.06em solid #1a1a1a;
  &:hover {
    border: 0.06em solid #f44336;
    background-color: #f44336;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #bdc3c7; /* Disabled button color */
    border: 0.06em solid #bdc3c7;
    color: #7f8c8d;
  }
`;
