import axiosInstance from "../utils/axiosInstance";

export const getTeacherDetails = async (id) => {
  try {
    let response = await axiosInstance.get(
      `/enrollment/teacher-details/${id}/`
    );

    return response;
  } catch (error) {
    throw error;
  }
};
