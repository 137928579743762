import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMetaData } from "../../redux/slices/metaDataSlice";
import { staticUrl } from "../../utils/axiosInstance";
import { ClosedQuote, Container, Content, OpenQuote } from "./aboutStyles";

const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(setMetaData({ title: "About Us" }));
  }, []);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OpenQuote
        src={`${staticUrl}images/quote-left-solid.svg`}
        alt="open quote"
      />
      <ClosedQuote
        src={`${staticUrl}images/quote-right-solid.svg`}
        alt="closed quote"
      />

      <Content>
        <p>
          For a long time, we have felt that getting a tutor for private tuition
          is difficult and is a major problem and nobody seems to be doing it
          right. We decided to tackle it ourselves. Founded by a school teacher,
          Schoolies aims to provide tutors for private as well as home tuition
          for the student to achieve their dreams. We offer a fast, secure and
          accessible way for students to get tutors nearby.
        </p>

        <p>
          Students only get offline tutors (teachers that students can meet in
          person).
        </p>

        <p>
          Schoolies empowers student to choose private tutors/ teachers
          according to their needs and requirements.
        </p>
      </Content>
    </Container>
  );
};

export default About;
