import { Content, ContentHeader } from "./t&cMainStyles";

const RightToTeacherAccess = () => {
  return (
    <Content id="right_to_teacher_access">
      <ContentHeader>
        <p>3. Right to Teacher Access</p>
      </ContentHeader>
      <p>
        If you are a teacher, your access and use of the Platform may
        additionally be separate or different from students and shall be
        collectively governed by the terms of such agreement, the Terms and
        Conditions, other Platform Terms. Further, the permission given by
        Schoolies to access and use the Platform under these Terms and
        Conditions is subject to the following conditions:
      </p>
      <ol type="a">
        <li>You agree not to alter or modify any part of the Platform.</li>
        <li>
          You agree not to access other teachers’ information or share their
          information without their consent.
        </li>
        <li>You agree not to use the Platform for any other commercial use.</li>
        <li>
          You agree to receive installs and updates from time to time from
          Schoolies. These updates are designed to improve, enhance, and further
          develop the Platform and may take the form of bug fixes, enhanced
          functions, new software modules and completely new versions. You agree
          to receive such updates (and permit Schoolies to deliver these to you)
          as part of your use of the Platform. Schoolies reserves the right to
          discontinue any aspect of the Platform at any time with or without
          notice.
        </li>
      </ol>
    </Content>
  );
};

export default RightToTeacherAccess;
