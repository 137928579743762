import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import CustomSelectButton from "../../../components/Custom Select Button/CustomSelectButton";
import { getUserProfile } from "../../../redux/slices/userInfoSlice";

import { Helmet } from "react-helmet";
import SearchBar from "./Searchbar/SearchBar";
import StudentCardSection from "./StudentCardSection";
import { Header, SearchToggleButton, Wrapper } from "./enrolledStuStyles";

const EnrolledStudentsLayout = () => {
  const userProfile = useSelector(getUserProfile);

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [progress, setProgress] = useState(0);

  const [tuitionTypeValue, setTuitionTypeValue] = useState("");

  const [isTuitionTypeSelectOpen, setIsTuitionTypeOpen] = useState(false);

  const [tuitionTyepOptions, setTuitionTypeOptions] = useState([]);

  const [toggleSearchbar, setToggleSearchbar] = useState(false);

  const handleTuitionTypeToggle = () => {
    setIsTuitionTypeOpen((curr) => !curr);
  };

  const handleTuitionTypeSelect = (value, label) => {
    searchParams.set("tuition_type", value);
    setSearchParams(searchParams);
    setTuitionTypeValue(label);
    setIsTuitionTypeOpen(false);
  };

  const handleToggleSearchbar = () => {
    setToggleSearchbar((curr) => !curr);
  };

  useEffect(() => {
    let paramValue = {
      GEN: "GEN",
      HOME: "HOME",
    };
    if (searchParams.get("tuition_type") === null) {
      setSearchParams(
        {
          tuition_type: paramValue[userProfile.tuition_type] || "GEN",
        },
        { replace: true }
      );
    }
  }, [searchParams, setSearchParams, userProfile]);

  useEffect(() => {
    let optionsMap = {
      GEN: [{ value: "GEN", label: "General Tuition" }],
      HOME: [{ value: "HOME", label: "Home Tuition" }],
      "GEN-HOME": [
        { value: "GEN", label: "General Tuition" },
        { value: "HOME", label: "Home Tuition" },
      ],
    };

    let selectValue = {
      GEN: "General Tuition",
      HOME: "Home Tuition",
    };

    if (userProfile?.tuition_type) {
      setTuitionTypeOptions(optionsMap[userProfile.tuition_type]);
      setTuitionTypeValue(
        selectValue[userProfile.tuition_type] || "Genral Tuition"
      );
    }
  }, [userProfile.tuition_type]);

  return (
    <>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Helmet>
        <title>Enrolled Students</title>
      </Helmet>
      <Wrapper>
        <Header>
          <SearchToggleButton onClick={handleToggleSearchbar}>
            <i className="fas fa-search"></i>
          </SearchToggleButton>
          <SearchBar
            toggleSearchbar={toggleSearchbar}
            handleToggle={handleToggleSearchbar}
          />
          <CustomSelectButton
            options={tuitionTyepOptions}
            isOpen={isTuitionTypeSelectOpen}
            onToggle={handleTuitionTypeToggle}
            value={tuitionTypeValue}
            handleSelect={handleTuitionTypeSelect}
          />
        </Header>

        <StudentCardSection setProgress={setProgress} />
      </Wrapper>
    </>
  );
};

export default EnrolledStudentsLayout;
