import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAuthenticatedStatus, getUser } from "../../redux/slices/authSlice";
import SchooliesLogo from "../../svgs/SchooliesLogo";
import {
  AuthNavbar,
  BurgerMenuButton,
  BurgerMenuContainer,
  CTALink,
  CloseButton,
  CloseButtonContainer,
  Container,
  HeaderWrapper,
  LogoLink,
  NavigationLinks,
  NonAuthNavbar,
  ProfileButton,
} from "./rootHeaderStyles";

const RootHeader = () => {
  const isAuthenticated = useSelector(getAuthenticatedStatus);
  const user = useSelector(getUser);
  const [profileLink, setProfileLink] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const LinkMap = {
    TCH: "/teacher/profile",
    STU: "/student/profile",
  };

  useEffect(() => {
    if (isAuthenticated) {
      setProfileLink(LinkMap[user?.regd_as]);
    }
  }, [isAuthenticated, user, LinkMap]);

  useEffect(() => {
    const detectScroll = () => {
      // the number is in pixels
      if (window.scrollY > 50) {
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
    };

    window.addEventListener("scroll", detectScroll);
    return () => {
      window.removeEventListener("scroll", detectScroll);
    };
  }, []);

  const handleToggleNavMenuDisplay = () => {
    setIsMenuOpen((curr) => !curr);
  };

  return (
    <HeaderWrapper $isScrolling={isScrolling}>
      <Container>
        <LogoLink to="/">
          <SchooliesLogo />
        </LogoLink>
        {!isAuthenticated && (
          <BurgerMenuContainer>
            <BurgerMenuButton onClick={handleToggleNavMenuDisplay}>
              <i className="fas fa-bars"></i>
            </BurgerMenuButton>
          </BurgerMenuContainer>
        )}

        {!isAuthenticated && (
          <NonAuthNavbar $toggleDisplay={isMenuOpen}>
            <CloseButtonContainer>
              <CloseButton onClick={handleToggleNavMenuDisplay}>
                <i className="fas fa-times"></i>
              </CloseButton>
            </CloseButtonContainer>
            <NavigationLinks to="/">Home</NavigationLinks>
            <NavigationLinks to="/service">Services</NavigationLinks>
            <NavigationLinks to="/about-us">About</NavigationLinks>
            <NavigationLinks to="/contact">Contact</NavigationLinks>
            <NavigationLinks className="push_right" to="/login">
              Log In
            </NavigationLinks>
            <CTALink to="/sign-up">Sign up</CTALink>
          </NonAuthNavbar>
        )}
        {isAuthenticated && (
          <AuthNavbar>
            <ProfileButton to={profileLink}>Profile</ProfileButton>
          </AuthNavbar>
        )}
      </Container>
    </HeaderWrapper>
  );
};

export default RootHeader;
