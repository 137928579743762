import { Suspense, lazy, useEffect, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import { Container } from "./ReceivedReqStyles";

import { getReqs } from "../../../api/enrollmentApi";

import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { NotificationBar } from "../../../components/notificationBar";
import { getUserProfile } from "../../../redux/slices/userInfoSlice";

const ReceivedRequestCard = lazy(() =>
  import("./Received Requests Cards/ReceivedRequestCard")
);

const ReceivedRequests = () => {
  /*
      THIS COMPONENT FETCHES AND DISPLAYS THE REUEST SENT BY  
      STUDENTS TO TEACHERS BY RENDERING CARDS.
  */

  const userProfile = useSelector(getUserProfile);

  // state to control the Loading bar
  const [progress, setProgress] = useState(0);

  //state to store the information of student profile
  const [pendingRequests, setPendingRequests] = useState([]);

  // getting received requests
  const getRequests = useQuery({
    queryKey: ["recievedRequests/getRequests"],
    queryFn: getReqs,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: (response) => {
      setPendingRequests(response.data);
      setProgress(100);
    },
    onError: (error) => {
      if (error.response.status === 500) {
        setProgress(100);
        NotificationBar(`Facing server issues, cannot retrive data`, `error`);
      } else {
        setProgress(100);
        NotificationBar(`An unknown error occured`, `error`);
      }
    },
  });

  useEffect(() => {
    /*
     CONTROLS THE PROGRESS BAR
    */
    if (getRequests.isLoading) {
      setProgress(80);
    }
  }, [getRequests.isLoading]);

  return (
    <>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Helmet>
        <title>
          {userProfile.name ? "Requests - " + userProfile.name : "Requests"}
        </title>
      </Helmet>
      <Container>
        {pendingRequests && pendingRequests.length ? (
          pendingRequests.map((request, index) => {
            return (
              <Suspense fallback={<div>Loading...</div>}>
                <ReceivedRequestCard
                  key={index}
                  request={request}
                  setPendingRequests={setPendingRequests}
                  pendingRequests={pendingRequests}
                  setProgress={setProgress}
                />
              </Suspense>
            );
          })
        ) : (
          <p key="no-requests">No new requests available</p>
        )}
      </Container>
    </>
  );
};

export default ReceivedRequests;
