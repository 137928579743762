import { useQuery } from "@tanstack/react-query";
import { useEffect, useState, Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import LoadingBar from "react-top-loading-bar";
import { getInactiveEnrollments } from "../../../api/enrollmentApi";
import { getUserProfile } from "../../../redux/slices/userInfoSlice";

import { Container } from "./activateEnrollStyles";
const ActivateCard = lazy(() => import("./Activate Cards/ActivateCard"));
const AcitivateEnrollment = () => {
  const userProfile = useSelector(getUserProfile);

  const [inactiveEnrollments, setInactiveEnrollments] = useState([]);

  const [progress, setProgress] = useState(0);

  const fetchInactiveEnrollments = useQuery({
    queryKey: ["teacher/fetchInactiveEnrollments"],
    queryFn: getInactiveEnrollments,
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setInactiveEnrollments(response.data);
    },
    useErrorBoundary: (error) => {
      console.log(error);
    },
    onSettled: () => {
      setProgress(100);
    },
  });

  const removeAtivatedEnrollment = (acitvatedId) => {
    let newInActiveEnrollments = inactiveEnrollments.filter((enrollment) => {
      return enrollment.id !== acitvatedId;
    });
    setInactiveEnrollments(newInActiveEnrollments);
  };

  useEffect(() => {
    /*
     CONTROLS THE PROGRESS BAR
    */
    if (fetchInactiveEnrollments.isLoading) {
      setProgress(80);
    }
  }, [fetchInactiveEnrollments.isLoading]);

  if (fetchInactiveEnrollments.isLoading) {
    return <></>;
  }

  return (
    <>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        height={4}
      />
      <Helmet>
        <title>
          {userProfile.name
            ? "Activate Enrollment - " + userProfile.name
            : "Active Enrollment"}
        </title>
      </Helmet>
      <Container>
        <Suspense fallback={<div>Loading...</div>}>
          {inactiveEnrollments?.length > 0 ? (
            inactiveEnrollments.map((enrollment) => (
              <ActivateCard
                key={enrollment.student_phone}
                enrollment={enrollment}
                removeAtivatedEnrollment={removeAtivatedEnrollment}
              />
            ))
          ) : (
            <p>No inactive enrollment</p>
          )}
        </Suspense>
      </Container>
    </>
  );
};

export default AcitivateEnrollment;
