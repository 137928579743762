import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--clr-neutral-100);

  gap: 1rem;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 20rem));
  gap: 1.4rem;
  padding: 1rem;

  @media screen and (width <= 1100px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Card = styled.div`
  border-radius: 0.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.2rem;
`;

export const Divider = styled.hr``;

export const CardDetailContainer = styled(DetailsContainer)``;

export const CardItem = styled.div`
  color: #666;
  .bold {
    font-weight: var(--fw-bold);
  }
`;

export const ButtonContainer = styled.div`
  /* outline: auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;
export const PayButton = styled.button`
  outline: none;
  appearance: none;
  border: 0.06em solid #000;
  color: #fff;
  background-color: #1a1a1a;
  width: 100%;
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  border-radius: 0.5em;
  box-sizing: border-box;

  cursor: pointer;
  text-align: center;
  gap: 0.6em;
  justify-content: space-between;
  font-size: 1rem;
  padding: 0.6em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.active,
  &:hover {
  }
`;

export const SummaryHeader = styled.p`
  font-weight: var(--fw-bold);
  font-size: 1.1rem;
`;

export const SummaryAdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #666;
  font-size: 1rem;

  .bold {
    font-weight: var(--fw-bold);
  }
`;

export const SummaryItem = styled.div`
  color: #666;
  display: flex;
  justify-content: space-between;
`;

export const SummaryLabel = styled.p`
  text-align: left;
  font-weight: var(--fw-bold);
`;

export const SummaryAmount = styled.p`
  text-align: right;
`;

export const SummaryContainer = styled(DetailsContainer)`
  border-top: 1px solid var(--color-bb);
`;

export const PaidContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ReceiptButton = styled.button`
  outline: none;

  border-radius: 0.5rem;
  cursor: pointer;
  text-align: center;
  padding: 0.5rem 0.9rem;
  display: inline-flex;
  gap: 0.3rem;
  align-items: center;
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  background-color: var(--clr-neutral-100);
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    color: #fff;
    background-color: rgb(33, 33, 33);
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
`;

export const ReceiptWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;

  overflow: hidden;
`;

export const ReceiptContainer = styled(DetailsContainer)`
  padding-inline: 0;
`;

export const ReceiptSummaryContainer = styled(DetailsContainer)`
  border-top: 1px solid var(--color-bb);
  padding-inline: 0;
`;

export const ReceiptItem = styled(CardItem)``;
