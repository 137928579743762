import { GhostButton, OutlineButton, SlateButton } from "./styles";

const CustomButton = ({
  type = "button",
  onClick = () => {},
  buttonLabel = null,
  icon = null,
  variant = "outline",
  disabled = false,
  id = null,
  currentActiveBtn = null,
}) => {
  if (variant === "slate") {
    return (
      <SlateButton id={id} disabled={disabled} onClick={onClick} type={type}>
        {icon && icon} {buttonLabel && buttonLabel}
      </SlateButton>
    );
  }
  if (variant === "outline") {
    return (
      <OutlineButton
        id={id}
        disabled={disabled}
        onClick={onClick}
        type={type}
        $active={id !== null ? currentActiveBtn === id : false}
      >
        {icon && icon} {buttonLabel}
      </OutlineButton>
    );
  }

  if (variant === "ghost") {
    return (
      <GhostButton id={id} disabled={disabled} onClick={onClick} type={type}>
        {icon && icon} {buttonLabel && buttonLabel}
      </GhostButton>
    );
  }
};

export default CustomButton;
