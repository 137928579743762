import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const fetchOptions = createAsyncThunk(
  "optionsSlice/fetchOptions",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axiosInstance.get(`/choicemanager/select-options/`);

      return response.data;
    } catch (error) {
      if (error.response) {
        rejectWithValue(error.response);
      } else {
        rejectWithValue("Unable to fetch the options");
      }
    }
  }
);
