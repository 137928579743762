import { Helmet } from "react-helmet";
import FeeInformation from "./FeeInformation";
import ProfileInformation from "./ProfileInformation";
import { DetailContainer } from "./detailsStyles";

const TeacherDetails = ({ teacherDetails }) => {
  return (
    <>
      <Helmet>
        <title>Schoolies - Application</title>
      </Helmet>
      <DetailContainer>
        <ProfileInformation profileData={teacherDetails} />
        <FeeInformation
          tuitionType={teacherDetails?.tuition_type}
          feeDetails={teacherDetails?.tch_fee}
        />
      </DetailContainer>
    </>
  );
};

export default TeacherDetails;
