import styled from "styled-components";

export const Wrapper = styled.section`
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 50em) {
    width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(248, 248, 248);
  border-radius: 1rem;

  width: min(100%, 30rem);
  padding: 2rem 3rem;
  gap: 1.2rem;

  font-family: var(--ff-secondary);
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: 600;

  @media (max-width: 50em) {
    font-size: 1.3rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  input[type="text"],
  input[type="tel"],
  input[type="email"] {
    width: 100%;
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    font-size: 1rem;
    padding: 0.5rem;
    transition: box-shadow 0.3s linear;

    &:focus {
      outline: none;
      border-color: #1ad7e8;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const Message = styled.textarea`
  max-width: 100%;
  max-height: 8rem;
  border: 2px solid #aeaeb5;
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.5rem;
  transition: box-shadow 0.3s linear;

  &:focus {
    outline: none;
    border-color: #1ad7e8;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const SendBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: fit-content;
  border: none;
  outline: none;
  border-radius: 0.3rem;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  background: rgb(48, 138, 255);
  color: #fff;
  cursor: pointer;
  transition: background 300ms;

  &:hover {
    background: rgb(26, 115, 232);
  }

  &:disabled {
    cursor: not-allowed;
    background: rgb(142, 191, 255);
  }

  @media (max-width: 50em) {
    font-size: 1rem;
    gap: 0.5rem;
  }
`;

export const SuccessMessageCont = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;

  @media (max-width: 50em) {
    font-size: 1rem;
  }
`;
