import React from "react";
import {
  BankButtonContainer,
  BankHeader,
  BankWrapper,
  CloseButton,
  CurrentBankDetailContainer,
} from "./layoutStyles";

import Modal from "../../../components/Modal Layout/Modal";

import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getBankDetails } from "../../../api/teacher";
import CustomButton from "../../../components/Button/CustomButton";
import { NotificationBar } from "../../../components/notificationBar";
import LinkBankAccountModal from "../Modals/LinkBankAccountModal";

const BankAccountDetail = ({
  setLoadingProgress,
  toggleBankDisplay,
  onClose,
}) => {
  const [isBankAccount, setIsBankAccount] = useState(null);

  const [isLinkBankDetailModalOpen, setIsLinkBankDetailModalOpen] =
    useState(false);

  const [bankDetails, setBankDetails] = useState({
    customerName: "",
    accountNo: "",
    ifscCode: "",
    panNumber: "",
  });

  const hashAccountNumber = (accountNo) => {
    // console.log(accountNo.split(""));

    let accountNoArr = accountNo.split("");

    let endIndex = accountNoArr.length - 4;

    let newArr = accountNoArr.map((element, index) => {
      if (index < endIndex) {
        return "X";
      } else {
        return element;
      }
    });

    let modifiedString = newArr.join("");

    return modifiedString;
  };

  const fetchBankDetails = useQuery({
    queryKey: ["teacher/fetchBankDetails"],
    queryFn: getBankDetails,
    refetchOnWindowFocus: false,
    retry: 0,
    onSuccess: (response) => {
      setIsBankAccount(true);
      setBankDetails({
        customerName: response.data.customer_name,
        accountNo: response.data.account_number,
        ifscCode: response.data.ifsc_code,
        panNumber: response.data.pan_number,
      });
    },
    onError: (error) => {
      if (error.response.status === 404) {
        setIsBankAccount(false);
      } else {
        NotificationBar(
          "Cannot fetch bank account details now.",
          "error",
          5000
        );
      }
    },
  });

  const handleLinkBankModal = () => {
    // toggles link bank modal open

    setIsLinkBankDetailModalOpen((curr) => !curr);
  };

  const displayIcon = () => {
    if (isBankAccount === true) {
      return <i className="fas fa-check-circle"></i>;
    } else if (isBankAccount === false) {
      return <i className="fas fa-times-circle"></i>;
    } else {
      return <></>;
    }
  };

  const displayAccountInfo = () => {
    if (isBankAccount === true) {
      return (
        <>
          <p>{bankDetails?.customerName}</p>
          <p>{hashAccountNumber(bankDetails?.accountNo)}</p>
        </>
      );
    } else if (isBankAccount === false) {
      return <p>No bank account is linked</p>;
    } else {
      return <></>;
    }
  };
  return (
    <>
      <BankWrapper $toggleDisplay={toggleBankDisplay}>
        <BankHeader>
          <p>Bank Account Detail</p>
          <CloseButton onClick={onClose} type="button">
            <i className="fas fa-times"></i>
          </CloseButton>
        </BankHeader>
        {isBankAccount !== null ? (
          <>
            <CurrentBankDetailContainer $isBankAccount={isBankAccount}>
              <span className="icon">{displayIcon()}</span>
              {displayAccountInfo()}
            </CurrentBankDetailContainer>
            {isBankAccount === false && (
              <BankButtonContainer>
                <CustomButton
                  buttonLabel="Link Account"
                  icon={<i className="fas fa-link"></i>}
                  id="link_account"
                  variant="slate"
                  onClick={handleLinkBankModal}
                />
              </BankButtonContainer>
            )}
          </>
        ) : (
          <p>Loading...</p>
        )}
      </BankWrapper>
      <Modal isOpen={isLinkBankDetailModalOpen}>
        <LinkBankAccountModal
          setInputValue={setBankDetails}
          inputValue={bankDetails}
          setClose={setIsLinkBankDetailModalOpen}
          setLoadingProgress={setLoadingProgress}
        />
      </Modal>
    </>
  );
};

export default BankAccountDetail;
