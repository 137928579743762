import styled from "styled-components";

const TuitionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  font-size: 1.1rem;
  margin-inline: auto;
  width: 100%;
  p {
    color: var(--clr-neutral-900);
  }
  span {
    font-weight: var(--fw-bold);
  }

  @media screen and (max-width: 50em) {
    padding: 0.5rem;
    font-size: 0.9rem;
    margin-inline: unset;
  }
`;

const FeesInfoContainer = styled(TuitionInfoContainer)``;

const GenFeeInfoCont = styled.div`
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
`;

const HomeFeeCont = styled(GenFeeInfoCont)``;

const InfoHeading = styled.div`
  display: flex;
  padding: 0.5rem;
  p {
    font-size: 1.1rem;
    font-weight: 600;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  overflow: hidden;
  width: 100%;
  text-align: left;
`;

const TableHead = styled.thead`
  th {
    /* border: 1px solid green; */
    padding: 0.2rem 0.5rem;
    font-weight: 900;
    border-bottom: 1px solid #000;

    &:nth-child(2),
    &:nth-child(3) {
      text-align: right;
    }

    @media (max-width: 50em) {
      width: 100%;
      padding: 0.3rem;

      width: 15%;
    }
  }
`;

const TableBody = styled.tbody`
  td {
    padding: 0.3rem 0.5rem;

    &:nth-child(2),
    &:nth-child(3) {
      text-align: right;
    }
  }
`;

export {
  TuitionInfoContainer,
  FeesInfoContainer,
  GenFeeInfoCont,
  HomeFeeCont,
  InfoHeading,
  Table,
  TableHead,
  TableBody,
};
