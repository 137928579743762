import styled from "styled-components";

export const FormCointainer = styled.section`
  /* outline: 1px solid green; */
  display: flex;
  flex-direction: column;
  flex: 0 1 22rem;
  min-width: 15rem;
  height: max-content;
  border-radius: 0.6rem;
  padding: 1rem;
  gap: 1.5rem;
  background-color: rgb(245, 245, 245);

  @media screen and (max-width: 57em) {
    position: fixed;
    z-index: 101;
    top: 0;
    right: 0;
    border-radius: 0;
    width: 55%;
    flex-shrink: 0;
    height: 100%;
    transition: all 300ms ease-in-out;
    transform: ${({ $toggleDisplay }) =>
      $toggleDisplay ? "translateX(0)" : "translateX(100%)"};
  }

  @media screen and (max-width: 45em) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  font-family: var(--ff-secondary);

  @media screen and (max-width: 57em) {
    /* outline: auto; */
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-family: var(--ff-secondary);
`;

export const Label = styled.label`
  font-weight: var(--fw-bold);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Buttons = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--fw-bold);
  font-family: var(--ff-button);

  /* min-height: 3.75em; */
  min-width: 0;
  outline: none;
  padding: 0.5em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:hover {
    color: #fff;
    /* background-color: #1a1a1a; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #bdc3c7; /* Disabled button color */
    border: 0.06em solid #bdc3c7;
    color: #7f8c8d;
  }
`;

export const SubmitButton = styled(Buttons)`
  border: 0.06em solid #1b4dff;
  color: #fff;
  background-color: #1b4dff;
`;

export const CloseButton = styled.button`
  display: none;
  font-size: 1.5rem;
  width: 3rem;
  background: transparent;
  outline: none;
  border: none;
  /* align-items: center; */
  justify-content: center;

  @media screen and (max-width: 57em) {
    display: flex;
  }
`;
