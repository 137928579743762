import { useState } from "react";
import { staticUrl } from "../../utils/axiosInstance";
import {
  Image,
  ImageAndInformationContainer,
  Information,
  Wrapper,
} from "./noResultsFoundStyles";

const NoResultsFound = () => {
  const [showText, setShowText] = useState(false);

  return (
    <Wrapper>
      <ImageAndInformationContainer>
        <Image
          onLoad={() => {
            setShowText(true);
          }}
          src={`${staticUrl}images/noResultsFound.svg`}
        />
        {showText && <Information>Sorry, no results found!</Information>}
      </ImageAndInformationContainer>
    </Wrapper>
  );
};

export default NoResultsFound;
