import styled from "styled-components";

// export const Container = styled.section`
//   /* border: 1px solid blue; */
//   display: flex;
//   flex-wrap: wrap;
//   flex: 1;
//   gap: 1rem;
//   justify-content: center;
//   background-color: #fff;
//   box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
//   padding: 1rem;
//   min-height: 100%;
//   @media (max-width: 50em) {
//     grid-area: outlet;
//     padding: 0.5rem;
//   }
// `;

export const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1.4rem;
  padding: 1rem;
`;
