import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const NotificationBar = (message, type, autoClose = 3000) => {
  if (type === "error") {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: autoClose,
      theme: "colored",
      hideProgressBar: true,
    });
  } else if (type === "info") {
    toast.info(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: autoClose,
      theme: "colored",
      hideProgressBar: true,
    });
  } else if (type === "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: autoClose,
      theme: "colored",
      hideProgressBar: true,
    });
  } else {
    toast(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: autoClose,
      theme: "colored",
      hideProgressBar: true,
    });
  }
};
