import { useEffect, useState } from "react";

const useDebounce = (value, delay = 300) => {
  const [debounceValue, setDebounceValue] = useState(value || "");

  useEffect(() => {
    if (value.length <= 2) return;

    const timerId = setTimeout(() => {
      setDebounceValue(value);
    }, delay);

    return () => clearTimeout(timerId);
  }, [value, delay]);

  return debounceValue;
};

export default useDebounce;
