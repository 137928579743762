import { useState } from "react";
import { Helmet } from "react-helmet";
import { sendResetEmail } from "../../../api/passwordResetApi";
import { NotificationBar } from "../../../components/notificationBar";
import { staticUrl } from "../../../utils/axiosInstance";

// STYLES
import {
  Container,
  InstructionContainer,
  FormContainer,
  SendEmailForm,
  SendEmailBtn,
  Logo,
} from "../forgotpasswordStyles";

const ForgotPassword = () => {
  // for loading
  const [isLoading, setIsLoading] = useState(false);

  // for changing page when email is sent
  const [emailSent, setEmailSent] = useState(false);

  // state to control the email input
  const [email, setEamil] = useState("");

  // onchange function for email input
  const onChangeEmail = (e) => {
    setEamil(e.target.value);
  };

  // function to handlle form submission
  const submitEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let response = await sendResetEmail(email);
    if (response.status === 200) {
      setEmailSent(true);
      setIsLoading(false);
    } else if (response.status === 400) {
      if (response.data.error === "CustomUser matching query does not exist.") {
        NotificationBar(
          "The user does not exist. Please enter your registered email.",
          "error"
        );
        setIsLoading(false);
        setEmailSent(false);
      }
    } else {
      NotificationBar(
        "We are unable to send an email at the moment. Please try again later.",
        "error"
      );
      setIsLoading(false);
      setEmailSent(false);
    }
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <title>Schoolies - Forgot Password</title>
      </Helmet>
      <FormContainer>
        <Logo src={`${staticUrl}images/only-logo.svg`} alt="schoolies" />

        <InstructionContainer>
          {emailSent ? (
            <p>
              An email has been sent to your eamil. Please follow the
              instructions in it to reset your password. If you cannot find the
              email in your inbox try to find it in Spam folder. The link will
              expire within 90 minutes from sending.
            </p>
          ) : (
            <p>Please enter your registered email</p>
          )}
        </InstructionContainer>

        {!emailSent && (
          <SendEmailForm onSubmit={submitEmail}>
            <input
              type="email"
              name="email"
              value={email}
              onChange={onChangeEmail}
              id="email"
              placeholder="Email"
              autoComplete="on"
              required
            />
            <SendEmailBtn disabled={isLoading}>
              <i className="fas fa-envelope"></i>{" "}
              {!isLoading ? "Send Email" : "Sending Email..."}
            </SendEmailBtn>
          </SendEmailForm>
        )}
      </FormContainer>
    </Container>
  );
};

export default ForgotPassword;
