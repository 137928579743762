import {
  Information,
  InformationContainer,
  ProfileImage,
  ProfileImageContainer,
  ProfileInformationWrapper,
} from "./detailsStyles";

import { getTuitionTypeName } from "../../../utils/getTuitionTypeName";

const ProfileInformation = ({ profileData }) => {
  return (
    <ProfileInformationWrapper>
      <ProfileImageContainer>
        <ProfileImage src={profileData?.profile_photo} />
      </ProfileImageContainer>
      <InformationContainer>
        <Information>
          <span>Name:</span> {profileData?.name}
        </Information>
        <Information>
          <span>Location:</span> {profileData?.location}
        </Information>
        <Information>
          <span>Current profession:</span> {profileData?.current_profession}
        </Information>
        {profileData?.current_profession === "TEACHER" && (
          <Information>
            <span>School name:</span> {profileData?.name_of_school}
          </Information>
        )}
        <Information>
          <span>Board(s) taught:</span> {profileData?.board}
        </Information>
        <Information>
          <span>Shifts(s) for tuition:</span> {profileData?.shift}
        </Information>
        <Information>
          <span>Subjects(s) taught:</span> {profileData?.subject}
        </Information>
        <Information>
          <span>Type of tuition taken:</span>{" "}
          {getTuitionTypeName(profileData?.tuition_type)}
        </Information>
        {["GEN", "GEN-HOME"].includes(profileData?.tuition_type) && (
          <Information>
            <span>Classes taken for general tuitions: </span>
            {profileData?.general_tuition_classes}
          </Information>
        )}
        {["HOME", "GEN-HOME"].includes(profileData?.tuition_type) && (
          <Information>
            <span>Classes taken for home tuitions: </span>
            {profileData?.home_tuition_classes}
          </Information>
        )}
      </InformationContainer>
    </ProfileInformationWrapper>
  );
};

export default ProfileInformation;
