import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ResultInfo = styled.div`
  display: flex;
  background: var(--clr-neutral-100);
  padding: 1rem;
  font-family: var(--ff-secondary);
  font-size: 1rem;
  font-weight: var(--fw-bold);
`;

export const ContentCardContainer = styled.div`
  /* outline: 1px solid green; */
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21rem, 23rem));
  /* background: var(--clr-neutral-100); */
  /* min-height: 100%; */
  width: 100%;
  gap: 1rem;
  padding-inline: 0.5rem;
  margin-bottom: 2rem;

  @media screen and (max-width: 50em) {
    /* justify-content: center; */
    gap: 0.5rem;
  }
  @media screen and (max-width: 35em) {
    /* justify-content: center; */
    gap: 1rem;
    justify-content: center;
  }
`;

export const CardWrapper = styled.div`
  background-color: var(--clr-neutral-100);
  font-family: var(--ff-secondary);
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  overflow: hidden;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.02);
  }
`;

export const CardBanner = styled.div`
  width: 100%;
  height: 9rem;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  background-color: orange;
`;

export const CardProfileImageContainer = styled.div`
  width: 9rem;
  height: 9rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  background: #fff;
  border-radius: 50%;

  transform: translateY(50%);
  transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &:hover {
    transform: translateY(50%) scale(1.3);
  }
`;

export const CardContent = styled.div`
  /* outline: 1px solid green; */
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  padding: 1.5rem;
  gap: 1rem;
`;

export const CardTitle = styled.h2`
  margin-top: 4.2rem;
  text-align: center;
`;

export const CardExtraInfo = styled.p`
  text-align: center;
  font-size: 0.95rem;
`;

export const CardDetail = styled.p`
  span {
    font-weight: var(--fw-bold);
  }
`;

export const CardButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem;
`;

export const SeeDetailsBtn = styled.button`
  appearance: none;
  border: none;
  border-radius: 0.5em;
  box-sizing: border-box;
  border: 0.06em solid var(--clr-neutral-900);
  color: var(--clr-neutral-100);
  background-color: var(--clr-neutral-900);
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--fw-bold);
  font-family: var(--ff-button);
  width: 100%;
  outline: none;
  padding: 0.7em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    color: #fff;
    /* background-color: #1a1a1a; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
`;
