import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.main)`
  /* outline: 1px solid red; */
  min-height: calc(100vh - 4.375rem);
  overflow-x: hidden;
  padding: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--primary-ff);

  @media screen and (max-width: 50em) {
    /* justify-content: unset;
    align-items: unset; */
  }
`;

const QuoteImg = styled.img`
  width: 7rem;
  aspect-ratio: 1;
  position: absolute;
  opacity: 0.5;

  @media screen and (max-width: 50em) {
    width: 4rem;
  }
`;

export const OpenQuote = styled(QuoteImg)`
  /* outline: 1px solid red; */
  top: 2rem;
  left: 2rem;
  @media screen and (max-width: 50em) {
    top: 0.2rem;
    left: 0.2rem;
  }
`;

export const ClosedQuote = styled(QuoteImg)`
  /* outline: 1px solid green; */
  position: absolute;
  bottom: 2rem;
  right: 2rem;

  @media screen and (max-width: 50em) {
    bottom: 0.2rem;
    right: 0.2rem;
  }
`;

export const Content = styled.section`
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    text-align: center;
    font-size: 1.5rem;
    font-family: "Alegreya", serif;
  }

  @media screen and (max-width: 50em) {
    width: 100%;

    padding: 0.5rem;

    p {
      /* text-align: justify; */
      font-size: 1.1rem;
    }
  }
`;
