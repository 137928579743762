import dayjs from "dayjs";
import { getTuitionTypeName } from "../../../utils/getTuitionTypeName";
import {
  CardContainer,
  CardDetailContainer,
  CardItem,
  CardTitle,
  CardTitleContainer,
} from "./statusStyles";

const RequestStatusCards = ({ requestDetail }) => {
  return (
    <>
      <CardContainer>
        <CardTitleContainer>
          <CardTitle>
            <span>⏳</span>Request Information
          </CardTitle>
        </CardTitleContainer>
        <CardDetailContainer>
          <CardItem>
            <p>
              <span className="bold">Applied to Teacher: </span>
              {requestDetail?.teacher_name}
            </p>
          </CardItem>
          <CardItem>
            <p>
              <span className="bold">Class Applied For: </span>
              {requestDetail?.applying_for_standard}
            </p>
          </CardItem>
          <CardItem>
            <p>
              <span className="bold">Subject(s) Applied For: </span>
              {requestDetail?.applying_for_subjects}
            </p>
          </CardItem>
          <CardItem>
            <p>
              <span className="bold">Board Applied For: </span>
              {requestDetail?.applying_for_board}
            </p>
          </CardItem>
          <CardItem>
            <p>
              <span className="bold">Tuition Type Applied For: </span>
              {getTuitionTypeName(requestDetail?.tuition_type)}
            </p>
          </CardItem>

          <CardItem>
            <p>
              <span className="bold">Status: </span>
              {requestDetail?.request_status}
            </p>
          </CardItem>
          <CardItem>
            <p>
              <span className="bold">Applied on: </span>
              {dayjs(requestDetail?.created_on).format("DD MMMM, YYYY")}
            </p>
          </CardItem>

          <CardItem>
            <p>
              <span className="bold">Enrollment Active Status: </span>
              {requestDetail?.enrollment_active_status}
            </p>
          </CardItem>
          <CardItem>
            <p>
              <span className="bold">Action taken on: </span>
              {requestDetail?.action_taken_on
                ? dayjs(requestDetail?.action_taken_on).format("DD MMMM, YYYY")
                : "Not Yet"}
            </p>
          </CardItem>
          <CardItem>
            <p>
              <span className="bold">Enrollment Activated On: </span>
              {requestDetail?.enrollment_activated_on
                ? dayjs(requestDetail?.enrollment_activated_on).format(
                    "DD MMMM, YYYY"
                  )
                : "Not Yet"}
            </p>
          </CardItem>
          {requestDetail?.declination_reason && (
            <CardItem>
              <p>
                <span className="bold">Reason for declination: </span>
                {requestDetail?.declination_reason}
              </p>
            </CardItem>
          )}
        </CardDetailContainer>
      </CardContainer>
    </>
  );
};

export default RequestStatusCards;
