import styled from "styled-components";

export const Container = styled.main`
  /* outline: 1px solid red; */
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4.375rem);
  overflow-x: hidden;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;

  p {
    font-size: 3rem;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }

  .highlight {
    color: red;
    font-size: 2rem;
  }

  @media (max-width: 50em) {
    min-height: calc(92vh - 4.375rem);

    p {
      font-size: 1.5rem;
    }

    .highlight {
      font-size: 1.2rem;
    }
  }
`;
