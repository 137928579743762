import { InputContainer, SuggestionItem, SuggestionsDropdown } from "./styles";

const LocationAutocomplete = ({
  value = "",
  name,
  id,
  onChange = () => {},
  onSuggestionClick = () => {},
  placeholder = "",
  required = false,
  suggestions = [],
  isSuggestionSelected,
  fullHeight = false,
}) => {
  return (
    <InputContainer className={`${fullHeight ? "full_height" : null}`}>
      <input
        type="text"
        value={value}
        name={name}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
      />
      {suggestions.length > 0 && (
        <SuggestionsDropdown $toggle={!isSuggestionSelected}>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
              key={index}
              onClick={() => onSuggestionClick(suggestion.description)}
            >
              <i className="fas fa-map-marker-alt"></i>
              <p>{suggestion.description}</p>
            </SuggestionItem>
          ))}
        </SuggestionsDropdown>
      )}
    </InputContainer>
  );
};

export default LocationAutocomplete;
