import { useNavigate } from "react-router-dom";
import { getTuitionTypeName } from "../../../utils/getTuitionTypeName";
import {
  CardBanner,
  CardButtonContainer,
  CardContent,
  CardDetail,
  CardExtraInfo,
  CardProfileImageContainer,
  CardTitle,
  CardWrapper,
  SeeDetailsBtn,
} from "./contentStyles";

const TeacherCards = ({ data }) => {
  const navigate = useNavigate();

  const handleSeeDetailsClick = (id) => {
    navigate(`/send-request/${id}`);
  };

  return (
    <CardWrapper>
      <CardBanner>
        <CardProfileImageContainer>
          <img src={data.profile_photo} alt="" />
        </CardProfileImageContainer>
      </CardBanner>
      <CardContent>
        <CardTitle>{data.name}</CardTitle>
        <CardExtraInfo>
          So far {data.total_students_enrolled}{" "}
          {Number(data.total_students_enrolled) > 1 ? `students` : "student"}{" "}
          have enrolled
        </CardExtraInfo>
        <CardDetail>
          <span>Location:</span> {data.location}
        </CardDetail>
        <CardDetail>
          <span>Tuition Type:</span> {getTuitionTypeName(data.tuition_type)}
        </CardDetail>
      </CardContent>
      <CardButtonContainer>
        <SeeDetailsBtn
          onClick={() => handleSeeDetailsClick(data.id)}
          type="button"
        >
          See Details
        </SeeDetailsBtn>
      </CardButtonContainer>
    </CardWrapper>
  );
};

export default TeacherCards;
