import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.main)`
  min-height: calc(100vh - 4.375rem);
  padding: 2rem;
  display: flex;
  align-items: center;
  overflow-x: clip;

  @media (max-width: 700px) {
    /* outline: 1px solid red; */
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 1rem 0.8rem;
  }
`;

export const TextAndSearchContainer = styled(motion.div)`
  /* outline: 1px solid red; */
  width: 50%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  .title {
    font-size: clamp(1rem, 2vw + 2.875rem, 5rem);
    font-family: "Fraunces", serif;
    font-weight: 400;
    line-height: 1;
    color: #082f62;
  }

  .intro {
    font-size: clamp(1.2rem, 1.5vw + 0.313rem, 2rem);
    font-family: "Commissioner", sans-serif;
    font-weight: 400;
    color: rgb(61, 61, 61);
  }

  @media (max-width: 700px) {
    gap: 2rem;
    width: 100%;
    min-height: 50%;
  }
`;

export const HeroImageContainer = styled(motion.div)`
  /* outline: 1px solid blue; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: clamp(20rem, 30vw + 6.25rem, 40rem);
  }

  @media (max-width: 480px) {
    width: 100%;
    min-height: 50%;
  }
`;

export const SearchbarWrapper = styled(motion.div)`
  /* outline: 1px solid green; */
  display: flex;
  justify-content: center;
  /* max-width: 65rem; */
  border-radius: 0.55rem;
  background: var(--clr-neutral-100);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1),
    0 6px 6px -5px rgba(0, 0, 0, 0.1);

  font-family: var(--primary-ff);
`;

export const SearchForm = styled.form`
  --input-width: 100%;
  --input-height: 100%;

  /* outline: 1px solid purple; */
  width: 100%;
  min-height: 4rem;
  border-radius: 0.55rem;
  font-family: var(--ff-secondary);
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 9rem;

  align-items: center;

  @media screen and (max-width: 70em) {
    align-items: normal;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;

    grid-template-areas:
      "location location"
      "subject standard"
      "button button";
  }
`;

export const InputContainer = styled.div`
  /* outline: 1px solid green; */
  border: 2px solid #ececec;
  display: flex;
  flex-direction: column;
  height: var(--input-height);
  width: var(--input-width);
  padding-top: 0.3rem;
  &:first-child {
    border-bottom-left-radius: 0.55rem;
    border-top-left-radius: 0.55rem;
  }

  &:not(:first-child) {
    border-left: none;
  }

  &:nth-child(3) {
    border-right: none;
  }

  &:last-of-type {
    border: none;
    padding: 0;
    border-bottom-right-radius: 0.55rem;
    border-top-right-radius: 0.55rem;
  }

  label {
    display: flex;
    padding-inline: 0.5rem;
    font-size: 0.9rem;
    font-weight: var(--fw-bold);
    color: rgba(0, 0, 0, 0.5);
  }
  input[type="text"] {
    height: var(--input-height);
    width: var(--input-width);
    padding: 0.5rem;
    outline: none;
    border: none;
    font-size: 1rem;
    border-bottom-left-radius: 0.55rem;

    &::placeholder {
      font-size: 0.9rem;
    }
  }
  @media screen and (max-width: 70em) {
    border: 2px solid #ececec;
    flex-direction: column;
    padding: 0.5rem 1rem;

    &:first-child {
      border-top-left-radius: 0.55rem;
      border-top-right-radius: 0.55rem;
      border-bottom-left-radius: 0;
    }

    input[type="text"] {
      border-radius: 0;
    }

    &:last-of-type {
      border: none;
      padding: 0;
    }

    &.location {
      /* outline: 3px solid red; */
      grid-area: location;
      border-bottom: none;
    }
    &.subject {
      /* outline: 3px solid red; */
      border-left: 2px solid #ececec;
      grid-area: subject;
    }
    &.standard {
      grid-area: standard;
      border-right: 2px solid #ececec;
    }
    &.button {
      /* margin-top: 1rem; */

      grid-area: button;
    }
  }
`;

export const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-top-right-radius: 0.55rem;
  border-bottom-right-radius: 0.55rem;
  background-color: #007bff;
  color: var(--clr-neutral-100);
  font-size: 1rem;
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  height: 100%;

  cursor: pointer;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }

  @media screen and (max-width: 70em) {
    border-radius: 0;
    padding: 0.75rem;
    border-bottom-left-radius: 0.55rem;
    border-bottom-right-radius: 0.55rem;
  }
`;
