import styled from "styled-components";

export const Wrapper = styled.section`
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const Header = styled.header`
  /* outline: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7rem;
  height: 4.5rem;
  gap: 1.2rem;
  border-bottom: 1px solid var(--color-bb);

  @media screen and (width <= 550px) {
    /* background-color: lightblue; */
    position: relative;
    z-index: 1;
    justify-content: space-between;
  }
`;

export const CardContainer = styled.div`
  /* outline: 1px solid blue; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 23rem));
  gap: 1.4rem;
  padding: 1rem;

  @media screen and (width <= 1100px) {
    align-items: center;
    justify-content: center;
  }
`;

export const SearchToggleButton = styled.button`
  display: none;
  outline: none;
  border: none;
  background-color: #2196f3;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
  color: var(--clr-neutral-100);
  @media screen and (width <= 550px) {
    display: flex;
  }
`;
