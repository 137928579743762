import styled from "styled-components";

import { Icon } from "../../../components/Modal Layout/modalStyles";

export const AcceptIcon = styled(Icon)`
  color: #4caf50;
`;

export const DeclineIcon = styled(Icon)`
  color: #f44336;
`;

export const LockIcon = styled(Icon)`
  color: #2196f3;
`;
export const LinkIcon = styled(Icon)`
  color: #2196f3;
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const ModalFormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  font-size: 1rem;
  outline: none;
  resize: none; /* Allow vertical resizing */
`;

export const PinFormBody = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PinInput = styled.input`
  width: 3.125rem;
  height: 3.125rem;
  font-size: 1.25rem;
  text-align: center;
  border: 0.0625rem solid #ccc;
  border-radius: 0.3125rem;
  outline: none;

  &:focus {
    border: 0.188rem solid #2196f3;
    /* border-color: #2196f3; */
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label``;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #2196f3; /* Material Design Blue */
  }
`;
