import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.main)`
  /* outline: 1px solid red; */
  display: flex;
  min-height: calc(100vh - 4.375rem);
  overflow-x: hidden;
  padding: 1rem;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 480px) {
    padding: 1.3rem;
  }
`;

export const FormContainer = styled.section`
  /* border: 1px solid red; */
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 23rem;
  padding: 2rem 1rem;
  align-items: center;
  margin: auto;
  box-shadow: rgb(0 0 0 / 45%) 0px 2px 10px;

  @media (max-width: 480px) {
    margin: auto;
    height: fit-content;
  }
`;

export const Logo = styled.img`
  width: 6rem;
  aspect-ratio: 1;
`;

export const InstructionContainer = styled.div`
  width: 100%;
  p {
    font-family: "Fraunces", serif;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    line-height: 1.5;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  input {
    outline: none;
    border: 2px solid #cdcdcd;
    width: 16.75rem;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
  }
`;

export const SendEmailForm = styled(Form)``;

export const PasswordResetForm = styled(Form)``;

const SubmitBtn = styled.button`
  width: 100%;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  background: rgb(213, 12, 34);
  color: #fff;
  padding: 0.5rem 1.8rem;
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: rgb(127, 6, 20);
  }

  &:disabled {
    background: rgb(213, 12, 34);
    cursor: not-allowed;
  }
`;

export const SendEmailBtn = styled(SubmitBtn)``;

export const PasswordResetBtn = styled(SubmitBtn)``;
