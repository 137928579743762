import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "../actions/authAction";
import {
  createStudentProfile,
  createTeacherProfile,
  fetchUserProfile,
  updateStudentProfile,
  updateTeacherProfile,
} from "../actions/userInfoActions";

const initialState = {
  status: "idle",
  profileInfo: {},
  isProfileAvailable: null,
  message: null,
  error: null,
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    updateIsProfileAvailable: {
      reducer: (state, action) => {
        state.isProfileAvailable = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.profileInfo = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createTeacherProfile.fulfilled, (state, action) => {
        state.status = "sucessful";
        state.profileInfo = action.payload.profile;
        state.message = action.payload.message;
      })
      .addCase(createTeacherProfile.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(updateTeacherProfile.fulfilled, (state, action) => {
        state.status = "sucessful";
        state.profileInfo = action.payload.profile;
        state.message = action.payload.message;
      })
      .addCase(updateTeacherProfile.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(createStudentProfile.fulfilled, (state, action) => {
        state.status = "successful";
        state.profileInfo = action.payload.profile;
        state.message = action.payload.message;
      })
      .addCase(createStudentProfile.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(updateStudentProfile.fulfilled, (state, action) => {
        state.status = "successful";
        state.profileInfo = action.payload.profile;
        state.message = action.payload.message;
      })
      .addCase(updateStudentProfile.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      .addCase(logoutAction.fulfilled, (state, action) => {
        localStorage.clear();
        return initialState;
      })
      .addCase(logoutAction.rejected, (state, action) => {
        localStorage.clear();
        return initialState;
      });
  },
});

export const getUserProfile = (state) => state.userInfo.profileInfo;
export const getUserInfoStatus = (state) => state.userInfo.status;
export const getUserInfoError = (state) => state.userInfo.error;
export const getIsProfileAvailable = (state) =>
  state.userInfo.isProfileAvailable;

export const { updateIsProfileAvailable } = userInfoSlice.actions;

export default userInfoSlice.reducer;
