import { ApplyButton, HeaderContainer } from "./mobileHeaderStyles";

const MobileHeader = ({ onCLick }) => {
  return (
    <HeaderContainer>
      <ApplyButton onClick={onCLick} type="button">
        Apply
      </ApplyButton>
    </HeaderContainer>
  );
};

export default MobileHeader;
