import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";

// PAGES
import {
  About,
  ActivateEnrollment,
  ApplyForTuition,
  Contact,
  EditDetailsForm,
  EditProfile,
  EnrolledStudentsLayout,
  FeeManagementLayout,
  ForgotPassword,
  Home,
  Login,
  MyTeacher,
  PasswordReset,
  PrivacyPolicyMain,
  Profile,
  Query,
  ReceivedRequests,
  Register,
  RequestStatus,
  RootLayout,
  Service,
  StuFeeManagement,
  StudentLayout,
  SubmitDetail,
  TeacherLayout,
  TeacherProfile,
  TermsAndConditions,
} from "../pages";

// ROUTE ACTIONS
import {
  RestrictAuthenticated,
  RestrictStudents,
  RestrictTeacher,
} from "../routeActions";

// COMPONENTS
import {
  AcitvateAccount,
  ChangePassword,
  DeleteAccount,
  NotFound,
  UnauthorizedPage,
  VerifyEmailSent,
} from "./";

function AnimatedRoutes() {
  let location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route element={<NotFound />} path="*" />
        <Route element={<RootLayout />} path="/">
          <Route element={<RestrictAuthenticated />}>
            <Route index element={<Home />} />
            <Route element={<Service />} path="service" />
            <Route element={<About />} path="about-us" />
            <Route element={<Contact />} path="contact" />
            <Route element={<Register />} path="sign-up" />
            <Route element={<Login />} path="login" />
            <Route
              element={<TermsAndConditions />}
              path="terms-and-conditions"
            />
            <Route element={<PrivacyPolicyMain />} path="privacy-policy" />
            <Route element={<VerifyEmailSent />} path="verify-email" />
            <Route element={<AcitvateAccount />} path="activate/:token" />
            <Route element={<ForgotPassword />} path="forgot-password" />
            <Route element={<PasswordReset />} path="reset-password/:token" />
          </Route>
          <Route element={<Query />} path="search" />
          <Route element={<ApplyForTuition />} path="send-request/:id" />
        </Route>
        <Route element={<UnauthorizedPage />} path="/unauthorized" />

        {/*TEACHER ROUTES */}
        <Route element={<RestrictTeacher />}>
          <Route element={<TeacherLayout />} path="/teacher">
            <Route index element={<TeacherProfile />} />

            <Route path="profile" element={<TeacherProfile />} />
            <Route path="edit-profile" element={<EditDetailsForm />} />
            <Route
              path="enrolled-students"
              element={<EnrolledStudentsLayout />}
            />

            <Route path="student-requests" element={<ReceivedRequests />} />
            <Route
              path="activate-enrollment"
              element={<ActivateEnrollment />}
            />
            <Route path="fee-management" element={<FeeManagementLayout />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="delete-account" element={<DeleteAccount />} />
          </Route>
        </Route>
        {/* end of teacher route */}

        {/* STUDENT ROUTES  */}
        <Route element={<RestrictStudents />}>
          <Route element={<StudentLayout />} exact path="/student">
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="edit-profile" element={<EditProfile />} />
            <Route path="submit-detail" element={<SubmitDetail />} />
            <Route path="request-status" element={<RequestStatus />} />
            <Route path="myteacher" element={<MyTeacher />} />
            <Route path="fee-management" element={<StuFeeManagement />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="delete-account" element={<DeleteAccount />} />
          </Route>
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
