import { createSelector, createSlice } from "@reduxjs/toolkit";
import { userLoginAction } from "../actions/authAction";

const initialState = {
  path: null,
  redirectionMode: null,
  search: null,
};

const redirectSlice = createSlice({
  name: "redirect",
  initialState,
  reducers: {
    setRedirectPath: (state, action) => {
      const { path, redirectionMode, search } = action.payload;
      state.path = path;
      state.redirectionMode = redirectionMode;
      state.search = search;
    },
    clearRedirectPath: (state) => {
      state.path = null;
      state.redirectionMode = "redirected";
      state.search = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLoginAction.fulfilled, (state, action) => {
      state.path = null;
      state.redirectionMode = "logout";
      state.search = null;
    });
  },
});

const { reducer, actions } = redirectSlice;

export const { setRedirectPath, clearRedirectPath } = actions;

export const getRedirectPath = (state) => state.redirect.path;
export const getRedirectionMode = (state) => state.redirect.redirectionMode;
export const getSearch = (state) => state.redirect.search;

export const getRedirectState = createSelector(
  getRedirectPath,
  getRedirectionMode,
  getSearch,
  (path, redirectionMode, search) => {
    return { path, redirectionMode, search };
  }
);

export default reducer;
