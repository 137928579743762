import axiosInstance from "../utils/axiosInstance";

// function to verify the account token
export const verifyAccount = async (token) => {
  const body = {
    token: token,
  };

  try {
    let response = await axiosInstance.post(`/account/activate-account/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

// getting the user to check if the user is already active
export const checkActivation = async (id) => {
  const body = {
    id: id,
  };

  try {
    let response = await axiosInstance.post(`/account/check-activation/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

// Function to send email on login if user account is inactive

export const mailOnLogin = async (email) => {
  let body = {
    email: email,
  };
  try {
    let response = await axiosInstance.post(
      `/account/activate-account-login/`,
      body
    );
  } catch (error) {
    throw error;
  }
};
