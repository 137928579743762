import { useQuery } from "@tanstack/react-query";
import { Suspense, lazy, useEffect, useState } from "react";
import { getMyTeachers } from "../../../api/studentApi";
import { Container } from "./myTeachersStyle";
import { useDispatch } from "react-redux";
import { setProgress } from "../../../redux/slices/loadingbarSlice";
import { setMetaData } from "../../../redux/slices/metaDataSlice";
const InfoCards = lazy(() => import("../../../components/Cards/InfoCards"));

const MyTeacher = () => {
  const [teacherInformation, setTeacherInformation] = useState([]);

  const dispatch = useDispatch();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["student/myTeachers"],
    queryFn: () => getMyTeachers(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  useEffect(() => {
    if (isError) {
      console.log(error.response);
    }
  }, [isError, error]);

  useEffect(() => {
    dispatch(setMetaData({ title: "My Teachers" }));
  }, []);

  useEffect(() => {
    if (!isLoading) {
      let infoArr = data?.map((item) => ({
        teacherName: item.name,
        id: item.id,
        photo: item.profile_photo,
        details: [
          { label: "Phone", value: item.phone },
          { label: "Address", value: item.full_address },
        ],
      }));

      setTeacherInformation(infoArr);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setProgress(70));
    } else {
      dispatch(setProgress(100));
    }
  }, [isLoading]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Container>
      {teacherInformation?.length > 0 ? (
        teacherInformation.map((info, index) => (
          <Suspense key={index} fallback={<div>Loading...</div>}>
            <InfoCards
              key={info.id}
              title={info.teacherName}
              information={info.details}
              image={info.photo}
            />
          </Suspense>
        ))
      ) : (
        <p>You are not enrolled to any teacher</p>
      )}
    </Container>
  );
};

export default MyTeacher;
