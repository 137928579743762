import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { submitBankAccountDetails } from "../../../api/teacher";
import {
  CancelButton,
  ConfirmButton,
  ModalFooter,
  ModalHeader,
} from "../../../components/Modal Layout/modalStyles";
import { NotificationBar } from "../../../components/notificationBar";
import {
  Input,
  InputContainer,
  Label,
  LinkIcon,
  ModalForm,
  ModalFormBody,
} from "./teacherModalStyles";

const LinkBankAccountModal = ({ setClose, setLoadingProgress }) => {
  const queryClient = useQueryClient();

  const initialBankAccState = {
    customerName: "",
    accountNo: "",
    ifscCode: "",
  };

  const [bankDetails, setBankDetails] = useState(initialBankAccState);

  const { mutate, isLoading, isError, error } = useMutation({
    mutationKey: ["teacher/linkBankAccountMutation"],
    mutationFn: (bankDetails) => submitBankAccountDetails(bankDetails),
    onSuccess: (response) => {
      queryClient.invalidateQueries(["teacher/fetchBankDetails"]);
      setBankDetails(initialBankAccState);
      NotificationBar("Bank account linked successfully", "success", 4000);
      setClose(false);
    },

    onSettled: () => {
      setLoadingProgress(100);
    },
  });

  useEffect(() => {
    if (isError) {
      if (error.response.status === 404) {
        NotificationBar(`${error.response.data.detail}`, "error", 5000);
      } else {
        NotificationBar(
          "Internal server error: We are unable to store your bank detail at the moment. Please try again later",
          "error",
          5000
        );
      }
    }
  }, [isError]);

  const handleOnChange = (e) => {
    // handling on change for bank detail
    const { name, value } = e.target;

    const regex = /^[0-9\b]+$/;

    if (name === "accountNo") {
      if (value === "" || regex.test(value)) {
        setBankDetails({
          ...bankDetails,
          [name]: value,
        });
      }
    } else {
      setBankDetails({
        ...bankDetails,
        [name]: value,
      });
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    mutate(bankDetails);
  };

  const handleCancelClick = () => {
    setBankDetails(initialBankAccState);
    setClose(false);
  };

  useEffect(() => {
    if (isLoading) {
      setLoadingProgress(50);
    }
  }, [isLoading]);

  return (
    <ModalForm onSubmit={handleOnSubmit}>
      <ModalHeader>
        <LinkIcon>
          <i className="fas fa-link"></i>
        </LinkIcon>
        <h3>Link Bank Account</h3>
      </ModalHeader>
      <ModalFormBody>
        <InputContainer>
          <Label htmlFor="customerName">Customer Name</Label>
          <Input
            type="text"
            name="customerName"
            id="customerName"
            value={bankDetails.customerName}
            onChange={handleOnChange}
          />
        </InputContainer>
        <InputContainer>
          <Label htmlFor="accountNo">Account Number</Label>
          <Input
            type="text"
            name="accountNo"
            id="accountNo"
            value={bankDetails.accountNo}
            onChange={handleOnChange}
            maxLength={16}
          />
        </InputContainer>
        <InputContainer>
          <Label htmlFor="ifscCode">IFSC Code</Label>
          <Input
            type="text"
            name="ifscCode"
            id="ifscCode"
            value={bankDetails.ifscCode}
            onChange={handleOnChange}
            maxLength={11}
          />
        </InputContainer>
        <InputContainer>
          <Label htmlFor="panNumber">PAN Number</Label>
          <Input
            type="text"
            name="panNumber"
            id="panNumber"
            value={bankDetails.panNumber}
            onChange={handleOnChange}
            maxLength={10}
          />
        </InputContainer>
      </ModalFormBody>
      <ModalFooter>
        <ConfirmButton type="submit">Submit</ConfirmButton>
        <CancelButton onClick={handleCancelClick} type="button">
          Cancel
        </CancelButton>
      </ModalFooter>
    </ModalForm>
  );
};

export default LinkBankAccountModal;
