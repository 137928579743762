import { useEffect, useState } from "react";
import {
  ApplyBtn,
  ButtonContainer,
  ClearAllBtn,
  FilterContainer,
  FilterForm,
  FilterHeader,
  FilterHeading,
  Input,
  InputContainer,
} from "./filterStyles";

import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import useChoices from "../../../hooks/useChoices";
import { getTuitionTypeName } from "../../../utils/getTuitionTypeName";
import { CloseButton } from "./filterStyles";

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    height: "100%",
    border: state.isFocused ? "2px solid #1ad7e8" : "2px solid #aeaeb5",
    boxShadow: "none",
    "&:hover": {
      border: "2px solid #1ad7e8", // Change border color on hover
    },
  }),
  indicatorSeparator: () => null,

  container: (provided) => ({
    ...provided,
    width: "100%",
    height: "100%",
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: "nowrap", // Prevent wrapping of placeholder text
    overflow: "hidden",
    textOverflow: "ellipsis", // Add ellipsis for text that overflows
    fontSize: "0.9rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: "nowrap", // Prevent wrapping of selected value
    overflow: "hidden",
    textOverflow: "ellipsis", // Add ellipsis for text that overflows
  }),
  valueContaine: (provided) => ({
    ...provided,
    // outline: "1px solid green",
  }),
  // ... other styles
};

const Filters = ({ handleFilterToggleDisplay, toggleFilterDisplay }) => {
  const { tuitionChoices } = useChoices();
  const [searchParams, setSearchParams] = useSearchParams();

  const teacherNameParam = searchParams.get("tch_name");
  const schoolNameParam = searchParams.get("tch_school_name");
  const tuitionTypeParam = searchParams.get("tuition_type");

  const locationParam = searchParams.get("location");

  const [filters, setFilters] = useState({
    teacherName: teacherNameParam || "",
    tuitionType: tuitionTypeParam || "",
    schoolName: schoolNameParam || "",
  });

  const handleTextInputChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitFilters = (e) => {
    e.preventDefault();
    if (filters.teacherName !== "") {
      searchParams.set("tch_name", filters.teacherName.trim());
    } else {
      searchParams.delete("tch_name");
    }

    if (filters.schoolName !== "") {
      searchParams.set("tch_school_name", filters.schoolName.trim());
    } else {
      searchParams.delete("tch_school_name");
    }

    if (filters.tuitionType !== "") {
      searchParams.set("tuition_type", filters.tuitionType);
    } else {
      searchParams.delete("tuition_type");
    }

    setSearchParams(searchParams);

    handleFilterToggleDisplay();
  };

  const handleTuitionTypeChange = (selectedValue, event) => {
    const { name } = event;

    setFilters({ ...filters, [name]: selectedValue.value });
  };

  const handleClearAllFilters = () => {
    setFilters({
      teacherName: "",
      tuitionType: "",
      schoolName: "",
    });

    searchParams.delete("tch_name");
    searchParams.delete("tch_school_name");
    searchParams.delete("tuition_type");

    setSearchParams(searchParams);
  };

  const handleFilterBtnDisable = () => {
    return (
      filters.teacherName === "" &&
      filters.schoolName === "" &&
      filters.tuitionType === ""
    );
  };

  useEffect(() => {
    setFilters({
      teacherName: "",
      tuitionType: "",
      schoolName: "",
    });
  }, [locationParam]);

  return (
    <FilterContainer $toggleDisplay={toggleFilterDisplay}>
      <FilterHeader>
        <FilterHeading>Filters</FilterHeading>
        <CloseButton onClick={handleFilterToggleDisplay} type="button">
          <i className="fas fa-times"></i>
        </CloseButton>
      </FilterHeader>
      <FilterForm onSubmit={handleSubmitFilters}>
        <InputContainer>
          <label htmlFor="teacherName">Teacher Name</label>
          <Input
            type="text"
            name="teacherName"
            id="teacherName"
            placeholder="Search by teacher name"
            value={filters.teacherName}
            onChange={handleTextInputChange}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="schoolName">School Name</label>
          <Input
            type="text"
            name="schoolName"
            placeholder="Search by school name"
            id="schoolName"
            value={filters.schoolName}
            onChange={handleTextInputChange}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="tuitionType">Tuition Type</label>
          <Select
            name="tuitionType"
            id="tuitionType"
            closeMenuOnSelect={true}
            placeholder="Search by tuition type"
            value={
              filters.tuitionType === ""
                ? filters.tuitionType
                : {
                    value: filters.tuitionType,
                    label: getTuitionTypeName(filters.tuitionType),
                  }
            }
            onChange={(selectedValue, event) =>
              handleTuitionTypeChange(selectedValue, event)
            }
            options={tuitionChoices}
            styles={customSelectStyles}
          />
        </InputContainer>
        <ButtonContainer>
          <ApplyBtn type="submit" disabled={handleFilterBtnDisable()}>
            Apply
          </ApplyBtn>
          <ClearAllBtn
            onClick={handleClearAllFilters}
            disabled={handleFilterBtnDisable()}
            type="button"
          >
            Clear All
          </ClearAllBtn>
        </ButtonContainer>
      </FilterForm>
    </FilterContainer>
  );
};

export default Filters;
