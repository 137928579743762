import axiosInstance from "../utils/axiosInstance";

// FUNCTION TO GET ALL THE TUITION REQUEST
export const getReqs = async () => {
  try {
    let response = await axiosInstance.get(`/enrollment/get-student-req/`);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

// FUNCTION TO SEND REQUEST
export const sendReq = async (formData) => {
  try {
    let response = await axiosInstance.post(`/enrollment/send-req/`, formData);
    return response;
  } catch (error) {
    throw error;
  }
};

// FUNCTION TO ACCEPT/ DECLINE ACTION ON THE REQUEST

export const handleRequestAction = async (action) => {
  const { id, requestAction, sessionEndDate, declinationReason } = action;
  try {
    let response = await axiosInstance.post(`/enrollment/action-req/${id}/`, {
      requestAction,
      sessionEndDate,
      declinationReason,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// FUNCTION TO GET THE REQUEST STATUS
export const getReqStatus = async () => {
  try {
    let response = await axiosInstance.get(`/enrollment/req-status/`);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

// FUNCTION TO CANCEL THE REQUEST
export const cancelReq = async (id) => {
  let body = {
    enrollId: id,
  };

  try {
    let response = await axiosInstance.post(`/enrollment/cancel-req/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

// function to apply for removal of student
export const RemoveStudent = async (formData, studentId) => {
  let body = {
    reason: formData.reason,
    removalDesc: formData.removalDesc,
    studentId: studentId,
  };

  try {
    let response = await axiosInstance.post(`/enrollment/rmv-student/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return error.request;
    }
  }
};

export const getInactiveEnrollments = async () => {
  try {
    let response = await axiosInstance.get(`/enrollment/inactive-enrollments/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const activateEnrollment = async (pin) => {
  try {
    let response = await axiosInstance.post(
      `/enrollment/activate-enrollment/`,
      { pin: pin }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
