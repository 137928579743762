import { Content, ContentHeader } from "./t&cMainStyles";

const RefundPolicy = () => {
  return (
    <Content id="refund_policy">
      <ContentHeader>
        <p>6. Refund Policy</p>
      </ContentHeader>
      <p>
        Upon making a payment for the fee amount as outlined, you (the user
        registered as a student) acknowledge and agree that all payments are
        final. Requests for changes, modifications, or cancellations of the
        payment will not be entertained, and payments are not eligible for
        refunds under any circumstances. This policy is in place to ensure the
        integrity and fairness of transactions for all users on the 'Schoolies'
        platform
      </p>
    </Content>
  );
};

export default RefundPolicy;
