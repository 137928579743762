import axiosInstance from "../utils/axiosInstance";

export const fetchInvoices = async ({
  paymentStatus,
  monthParam,
  yearParam,
}) => {
  try {
    let response = await axiosInstance.get("/payment/fetch-invoices/", {
      params: {
        payment_status: paymentStatus,
        month: monthParam,
        year: yearParam,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPendingInvoiceDetail = async (id) => {
  try {
    let response = await axiosInstance.get(`/payment/fetch-invoices/${id}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const initiatePayment = async (orderData) => {
  try {
    let response = await axiosInstance.post(
      `/payment/initiate-payment/`,
      orderData
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const verifyPayment = async ({
  razorpay_payment_id,
  razorpay_order_id,
  razorpay_signature,
  amount,
  invoiceId,
}) => {
  try {
    let response = await axiosInstance.post(
      `/payment/handle-payment/${invoiceId}/`,
      {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
        amount,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
