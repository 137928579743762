import { useEffect } from "react";
import { staticUrl } from "../../utils/axiosInstance";

import { useDispatch } from "react-redux";
import { setMetaData } from "../../redux/slices/metaDataSlice";
import Searchbar from "./Searchbar";
import {
  Container,
  HeroImageContainer,
  TextAndSearchContainer,
} from "./homeStyles";

const Home = () => {
  // this is to load the page at the top whenever visited

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    dispatch(
      setMetaData({
        title: "Find your perfect tutor near you",
        description:
          "Find your perfect tutor near you. Schoolies helps students who are in search of a tutor connect to tutors in or near their location.",
      })
    );
  }, []);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0 }}
    >
      <TextAndSearchContainer>
        <h1 className="title">Find your perfect tutor near you</h1>
        <h2 className="intro">
          We help you find your perfect tutor near you. Just search, it's that
          simple
        </h2>
        <Searchbar />
      </TextAndSearchContainer>
      <HeroImageContainer
        initial={{
          opacity: 0,
          scale: 0.2,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            duration: 1,
            delay: 0.3,
          },
        }}
      >
        <img
          src={`${staticUrl}images/hero_illustration_student_register.svg`}
          alt="hero"
          loading="lazy"
        />
      </HeroImageContainer>
    </Container>
  );
};

export default Home;
