import styled from "styled-components";

const Container = styled.div`
  /* border: 2px solid green; */
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--primary-padding);
  font-family: var(--primary-ff);
  background: var(--color-white);
  gap: 1rem;
  /* overflow-x: hidden;
  overflow-y: auto; */

  @media screen and (max-width: 50em) {
    padding: 0.5rem 0.5rem 2rem 0.5rem;
  }
`;

const DetailsForm = styled.form`
  /* border: 1px solid blue; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
  gap: 1rem;

  @media screen and (max-width: 50em) {
    width: 100%;
  }
`;

const BtnContainer = styled.section`
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

const Btn = styled.button`
  padding: 0.4rem 1.5rem;
  border-radius: 0.3rem;
  border: none;
  outline: none;
  background: var(--color-panel-purple);
  color: var(--color-white);
  cursor: pointer;

  &:disabled {
    background: rgb(205, 172, 255);
    cursor: not-allowed;
  }
`;

const SubmitBtn = styled(Btn)`
  background-color: var(--color-panel-success);

  &:disabled {
    background-color: rgb(112, 222, 139);
  }
`;

const InputContainer = styled.div`
  /* outline: 1px solid blue; */
  border-bottom: 1px solid lightgray;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 3rem;
  label {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 40%;

    .disclaimer {
      font-size: 0.8rem;
      color: rgb(122, 122, 122);
    }
  }

  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="date"] {
    width: 40%;
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    font-size: 1rem;
    padding: 0.5rem;
    transition: box-shadow 0.3s linear;

    &:focus {
      outline: none;
      border-color: #1ad7e8;
      box-shadow: 0 0 10px #1aaee8;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  input[type="file"]::file-selector-button {
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    background: rgb(183, 0, 0);
    color: #fff;
    cursor: pointer;

    &:hover {
      background: rgb(139, 0, 0);
    }
  }

  select {
    width: 40%;
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    padding: 0.5rem;
    transition: box-shadow 0.3s linear;

    &:focus {
      outline: none;
      border-color: #1ad7e8;
      box-shadow: 0 0 10px #1aaee8;
    }
  }

  .homeTuition-option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.1rem;
  }

  .shift-input-group {
    display: flex;
    gap: 0.6rem;
  }
  .shift-input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.1rem;
    cursor: pointer;
    input[type="radio"] {
      width: 1.6rem;
      height: 1.6rem;
      cursor: pointer;
    }
  }

  input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 50em) {
    flex-direction: column;
    align-items: unset;
    gap: 0.5rem;
    width: 100%;
    label {
      width: 100%;
    }
    input[type="text"],
    input[type="tel"],
    input[type="number"],
    input[type="date"] {
      width: 100%;
    }
    select {
      width: 100%;
    }

    .shift-input-group {
      /* border: 1px solid red; */
      width: 100%;
      align-items: left;
      flex-direction: column;
      gap: 0.8rem;
    }
    .shift-input-label {
      justify-content: unset;
    }

    .homeTuition-option {
      justify-content: left;
    }
  }
`;

const FormBody = styled.section`
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 50em) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export {
  DetailsForm,
  Container,
  BtnContainer,
  SubmitBtn,
  InputContainer,
  FormBody,
};
