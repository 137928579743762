import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getClassesOptions,
  getSubjectOptions,
} from "../redux/slices/optionsSlice";

const useChoices = () => {
  const [subjectChoices, setSubjectChoices] = useState([]);
  const [standardChoices, setStandardChoices] = useState([]);
  const [twoYearChoices, setTwoYearChoices] = useState([]);

  const tuitionChoices = [
    { value: "GEN", label: "General Tuition" },
    { value: "HOME", label: "Home Tuition" },
  ];

  const monthChoices = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const standardOptions = useSelector(getClassesOptions);
  const subjectOptions = useSelector(getSubjectOptions);

  useEffect(() => {
    /*
        THIS SETS THE OPTIONS FOR YEAR FILTER.
        IT TAKES THE CURRENT YEAR AS THE REFERENCE POINT
        AND GIVES TWO YEARS LESS THAN THE CURRENT YEAR AS 
        OPTIONS ALONG WITH THE CURRENT YEAR.
    */

    const referencePointYear = new Date().getFullYear();

    let optionList = [];

    for (let i = 0; i <= 2; i++) {
      let oneYearLess = referencePointYear - i;
      optionList.push({
        value: oneYearLess.toString(),
        label: oneYearLess.toString(),
      });
    }

    setTwoYearChoices(optionList);
  }, []);
  useEffect(() => {
    if (subjectOptions.length > 0) {
      setSubjectChoices(
        subjectOptions.map((subject) => ({
          value: subject.subject_name,
          label: subject.subject_name,
        }))
      );
    }

    let classOptions = [];

    if (standardOptions.lower_primary?.length > 0) {
      let lpArr = standardOptions.lower_primary.map((cls) => ({
        label: cls.name_of_class,
        value: cls.name_of_class,
      }));

      classOptions.push({
        label: "LOWER PRIMARY",
        options: lpArr,
      });
    }

    if (standardOptions.upper_primary?.length > 0) {
      let upperPrimArr = standardOptions.upper_primary.map((cls) => ({
        label: cls.name_of_class,
        value: cls.name_of_class,
      }));

      classOptions.push({
        label: "UPPER PRIMARY",
        options: upperPrimArr,
      });
    }

    if (standardOptions.secondary?.length > 0) {
      let secondaryArr = standardOptions.secondary.map((cls) => ({
        label: cls.name_of_class,
        value: cls.name_of_class,
      }));

      classOptions.push({
        label: "SECONDARY",
        options: secondaryArr,
      });
    }

    if (standardOptions.higher_secondary?.length > 0) {
      let higherSecArr = standardOptions.higher_secondary.map((cls) => ({
        label: cls.name_of_class,
        value: cls.name_of_class,
      }));

      classOptions.push({
        label: "HIGHER SECONDARY",
        options: higherSecArr,
      });
    }

    if (standardOptions.graduation?.length > 0) {
      let gradArr = standardOptions.graduation.map((cls) => ({
        label: cls.name_of_class,
        value: cls.name_of_class,
      }));

      classOptions.push({
        label: "GRADUATION",
        options: gradArr,
      });
    }

    if (standardOptions.post_graduation?.length > 0) {
      let postGradArr = standardOptions.post_graduation.map((cls) => ({
        label: cls.name_of_class,
        value: cls.name_of_class,
      }));

      classOptions.push({
        label: "POST GRADUATION",
        options: postGradArr,
      });
    }

    setStandardChoices(classOptions);
  }, [standardOptions, subjectOptions]);

  return {
    subjectChoices,
    standardChoices,
    tuitionChoices,
    monthChoices,
    twoYearChoices,
  };
};

export default useChoices;
