import styled from "styled-components";

export const DetailContainer = styled.section`
  /* outline: 1px solid purple; */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem 2.5rem;
  flex: 1 1 30rem;
  min-width: 30rem;

  @media screen and (max-width: 45em) {
    flex-direction: column;
    min-width: 0;
    padding: 0.5rem;
  }
`;

export const ProfileInformationWrapper = styled.div`
  /* outline: 1px solid green; */
  display: flex;
  width: 100%;
  gap: 1rem;

  @media screen and (max-width: 70em) {
    flex-direction: column;
  }

  @media screen and (max-width: 57em) {
    flex-direction: row;
  }
  @media screen and (max-width: 45em) {
    flex-direction: column;
  }
`;

export const ProfileImageContainer = styled.div`
  /* outline: auto; */
  display: flex;
  flex-shrink: 0;
  padding: 0.9rem;

  @media screen and (max-width: 70rem) {
    justify-content: center;
  }
  @media screen and (max-width: 45em) {
    padding: 0;
  }
`;

export const ProfileImage = styled.img`
  width: 15rem;
  height: 15rem;
  border-radius: 50%;

  object-fit: cover;
`;

export const InformationContainer = styled.div`
  /* outline: auto; */
  display: flex;
  flex-direction: column;
  padding: 0.9rem;
  gap: 1rem;
  font-family: var(--ff-secondary);

  @media screen and (max-width: 45em) {
    padding: 0;
  }
`;

export const Information = styled.p`
  span {
    font-weight: var(--fw-bold);
  }
`;

export const FeeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: 0.9rem;
  gap: 1rem;
  font-family: var(--ff-secondary);

  @media screen and (max-width: 45em) {
    padding: 0;
  }
`;

export const TableContainer = styled.div`
  border-radius: 1rem;
  background-color: var(--clr-neutral-100);
  border: 2px solid #edeef1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px rgba(0, 0, 0, 0.08) 0px 0px
    0px 1px;
  text-align: left;
  padding: 1.5rem;
  /* height: 100%; */
  width: 100%;
  overflow-x: auto;

  @media screen and (max-width: 50em) {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
`;

export const TableCaption = styled.caption`
  font-size: 1.12rem;
  font-weight: var(--fw-bold);
  text-align: left;
  margin-block: 0.5rem;
`;

export const TableHeader = styled.thead`
  /* background-color: rgb(245, 245, 245); */
  color: var(--clr-neutral-900);
  border-top: 2px solid #edeef1;
`;

export const TableRow = styled.tr`
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
`;

export const TableHeaderCell = styled.th`
  padding: 1.25rem 1rem;
  color: #a0a7b1;
`;

export const TableBody = styled.tbody`
  tr {
    cursor: pointer;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
      background-color: #e2ecff;
    }
  }
`;

export const TableCell = styled.td`
  padding: 0.5rem 1rem;
  vertical-align: middle;
`;
