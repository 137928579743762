import styled from "styled-components";

const Container = styled.div`
  /* border: 2px solid green; */
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--primary-padding);
  font-family: var(--primary-ff);
  gap: 1rem;

  @media screen and (max-width: 50em) {
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    gap: 0.5rem;
  }
`;
const Header = styled.header`
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 3rem;
  font-size: 1.2rem;
  color: var(--clr-neutral-900);
  font-weight: var(--fw-bold);
`;
const DetailsForm = styled.form`
  /* border: 1px solid blue; */
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-inline: auto;
  gap: 1rem;

  @media screen and (max-width: 50em) {
    width: 100%;
  }
`;
const FormBody = styled.section`
  /* border: 1px solid blue; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 50em) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const BtnContainer = styled.section`
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
`;

const Btn = styled.button`
  padding: 0.4rem 1.5rem;
  border-radius: 0.3rem;
  border: none;
  outline: none;
  background: var(--color-panel-purple);
  color: var(--color-white);
  cursor: pointer;

  &:disabled {
    background: rgb(205, 172, 255);
    cursor: not-allowed;
  }
`;

const SubmitBtn = styled(Btn)`
  background-color: var(--color-panel-success);

  &:disabled {
    background-color: rgb(112, 222, 139);
  }
`;

const InputContainer = styled.div`
  --input-width: 100%;

  /* outline: 1px solid blue; */
  border-bottom: 1px solid lightgray;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  align-items: center;
  gap: 1rem;
  label {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;

    .disclaimer {
      font-size: 0.8rem;
      color: rgb(122, 122, 122);
    }
  }

  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="date"] {
    width: var(--input-width);
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    font-size: 1rem;
    padding: 0.5rem;
    transition: box-shadow 0.3s linear;

    &:focus {
      outline: none;
      border-color: #1ad7e8;
      box-shadow: 0 0 10px #1aaee8;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  input[type="file"]::file-selector-button {
    border: none;
    outline: none;
    font-size: 1rem;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    background: rgb(183, 0, 0);
    color: #fff;
    cursor: pointer;

    &:hover {
      background: rgb(139, 0, 0);
    }
  }

  select,
  .css-b62m3t-container {
    width: var(--input-width);
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    padding: 0.5rem;
    transition: box-shadow 0.3s linear;

    &:focus {
      outline: none;
      border-color: #1ad7e8;
      box-shadow: 0 0 10px #1aaee8;
    }
  }

  .css-b62m3t-container {
    padding: 0;
    border: none;
    border-radius: none;
  }

  .css-1jqq78o-placeholder {
    color: black;
  }
  .css-13cymwt-control {
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    box-shadow: none;
    padding: 0.5rem;
    width: 100%;
  }

  .css-t3ipsp-control {
    outline: none;
    border-color: #1ad7e8;
    box-shadow: 0 0 10px #1aaee8;
    padding: 0.5rem;
  }

  .css-1fdsijx-ValueContainer {
    padding: 0;
  }
  #react-select-3-input,
  #react-select-2-input {
    &:focus {
      outline: none;
      border-color: none;
      box-shadow: none;
    }
    &:hover {
      outline: none;
      border-color: none;
      box-shadow: none;
    }
  }

  .homeTuition-option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.1rem;
  }

  .shift-input-group {
    display: flex;
    gap: 0.6rem;
  }
  .shift-input-label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.1rem;
    cursor: pointer;
    input[type="radio"] {
      width: 1.6rem;
      height: 1.6rem;
      cursor: pointer;
    }
  }

  @media (max-width: 50em) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    gap: 0.5rem;
    width: 100%;
    label {
      width: 100%;
    }
    input[type="text"],
    input[type="tel"],
    input[type="number"],
    input[type="date"] {
      width: 100%;
    }
    select,
    .css-b62m3t-container {
      width: 100%;
    }

    .shift-input-group {
      /* border: 1px solid red; */
      width: 100%;
      align-items: left;
      flex-direction: column;
      gap: 0.8rem;
    }
    .shift-input-label {
      justify-content: unset;
    }

    .homeTuition-option {
      justify-content: left;
    }
  }
`;

const PhotoUploadContainer = styled.div`
  /* border: 1px solid red; */
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  input[type="file"] {
    display: none;
  }

  label {
    background: var(--color-panel-primary);
    color: var(--color-white);
    padding: 0.5rem 1.5rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
  }

  @media screen and (max-width: 70em) {
    padding: 0.8rem;
  }

  @media screen and (max-width: 50em) {
    width: 100%;
    height: fit-content;
  }
`;
const ProfilePhotoPreview = styled.div`
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
  height: 20rem;
  width: 20rem;

  @media screen and (max-width: 70em) {
    height: 16rem;
    width: 16rem;
  }
`;

const PhotoDisclaimer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 600;
  padding-inline: 1rem;
`;

const PersonalDetailsContainer = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
`;

export {
  DetailsForm,
  Container,
  Header,
  BtnContainer,
  FormBody,
  SubmitBtn,
  PhotoUploadContainer,
  ProfilePhotoPreview,
  PersonalDetailsContainer,
  InputContainer,
  PhotoDisclaimer,
};
