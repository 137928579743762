import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableCaption,
} from "./tableStyles";

const StyledTable = ({ data, columns, caption }) => {
  return (
    <TableContainer>
      <Table>
        <TableCaption>{caption && caption(data)}</TableCaption>
        <TableHeader>
          <TableRow>
            {columns?.map((col, index) => (
              <TableHeaderCell key={index}>{col.Header}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <TableCell key={colIndex}>
                    {col.Cell ? col.Cell(row) : row[col.dataKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns?.length}>No data available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StyledTable;
