import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { getAuthenticatedStatus, getUser } from "../redux/slices/authSlice";
import {
  getRedirectState,
  setRedirectPath,
} from "../redux/slices/redirectSlice";
import { useEffect } from "react";

const RestrictTeacher = () => {
  const isAuthenticated = useSelector(getAuthenticatedStatus);
  const user = useSelector(getUser);
  const { path, redirectionMode } = useSelector(getRedirectState);

  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      if (path === null && redirectionMode === null) {
        dispatch(
          setRedirectPath({
            path: location.pathname,
            redirectionMode: "unauthenticated",
            search: location.search,
          })
        );
      }
    }
  }, [isAuthenticated, location, dispatch]);

  if (isAuthenticated && user.regd_as === "TCH") {
    return <Outlet />;
  } else if (!isAuthenticated) {
    return <Navigate to="/login" />;
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

export default RestrictTeacher;
