import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.main)`
  // outline: 1px solid red;
  min-height: calc(100vh - 4.375rem);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  gap: 1rem;
  overflow-x: hidden;
`;

export const GetInTouch = styled.p`
  font-size: 2rem;
  font-family: var(--ff-primary);

  @media (max-width: 50em) {
    font-size: 1.5rem;
  }
`;

export const Container = styled.section`
  /* border: 1px solid red; */
  width: min(var(--size-container), 100% - (var(--size-400) * 2));
  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 50em) {
    flex-direction: column-reverse;
  }
`;

export const PhyContactContainer = styled.div`
  /* outline: auto; */
  width: min(30rem, 100%);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PhyInformationContainer = styled.div`
  /* outline: auto; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PhyContactInformation = styled.p`
  font-size: 1rem;
  span {
    font-weight: var(--fw-bold);
    font-family: var(--ff-secondary);
  }
`;

export const PhyContactHeading = styled.p`
  font-size: 1.2rem;
  font-weight: var(--fw-bold);
  font-family: var(--ff-primary);
`;
