import { ModalBox, Overlay } from "./modalStyles";

const Modal = ({ isOpen, onClose = {}, children }) => {
  return (
    <>
      {isOpen && (
        <Overlay>
          <ModalBox>{children}</ModalBox>
        </Overlay>
      )}
    </>
  );
};

export default Modal;
