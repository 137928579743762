import styled from "styled-components";

export const Container = styled.section`
  /* border: 1px solid blue; */
  /* outline: 1px solid blue; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 23rem));
  gap: 1.4rem;
  padding: 1rem;

  @media screen and (width <= 1100px) {
    align-items: center;
    justify-content: center;
  }
`;
