import styled from "styled-components";

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  overflow: hidden;

  @media screen and (max-width: 50em) {
    padding: 1rem;
  }
`;

const PasswordChangeForm = styled.form`
  width: 30rem;
  /* min-height: 20rem; */
  border-radius: 0.5rem;
  background-color: var(--color-white);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Heading = styled.header`
  width: 100%;
  p {
    text-align: center;
    font-size: 1.1rem;
    font-weight: var(--fw-bold);
  }
`;

const InputContainer = styled.div`
  /* outline: 1px solid blue; */
  /* border-bottom: 1px solid lightgray; */
  /* padding: 1rem; */
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  label {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    .disclaimer {
      font-size: 0.8rem;
      color: rgb(122, 122, 122);
    }
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    border: 2px solid #aeaeb5;
    border-radius: 6px;
    font-size: 1rem;
    padding: 0.5rem;
    transition: box-shadow 0.3s linear;

    &:focus {
      outline: none;
      border-color: #1ad7e8;
      box-shadow: 0 0 10px #1aaee8;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  @media (max-width: 50em) {
    flex-direction: column;
    align-items: unset;
    gap: 0.5rem;
    width: 100%;
    label {
      width: 100%;
    }
    input[type="text"] {
      width: 100%;
    }
    select {
      width: 100%;
    }
  }
`;

const SubmitBtn = styled.button`
  width: fit-content;
  padding: 0.5rem 1.4rem;
  border-radius: 0.4rem;
  border: none;
  outline: none;
  background: var(--color-panel-success);
  color: var(--color-white);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: rgb(105, 211, 131);
  }
`;

export { Container, PasswordChangeForm, Heading, InputContainer, SubmitBtn };
