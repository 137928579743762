import dayjs from "dayjs";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMetaData } from "../../../redux/slices/metaDataSlice";
import { getUserProfile } from "../../../redux/slices/userInfoSlice";
import { getResidingState } from "../../../utils/getResdingState";
import {
  Container,
  DetailsContainer,
  Headline,
  PersonalDetails,
  PhotoContainer,
  ProfilePhoto,
} from "./studentProfileStyles";

const Profile = () => {
  // getting information from slices
  const profile = useSelector(getUserProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useLayoutEffect(() => {
    dispatch(setMetaData({ title: "Profile" }));
  }, []);

  return (
    <Container>
      <Headline>
        <p>Personal Information</p>
      </Headline>
      <DetailsContainer>
        <PhotoContainer>
          <ProfilePhoto>
            <img src={profile.profile_photo} alt="prfile photo" />
          </ProfilePhoto>
        </PhotoContainer>
        <PersonalDetails>
          <p>
            <span>Name : </span>
            {profile?.name}
          </p>
          <p>
            <span>Date of Birth : </span>
            {profile && dayjs(profile.date_of_birth).format("MMMM D, YYYY")}
          </p>
          <p>
            <span>Parent's Name : </span>
            {profile?.parent_name}
          </p>
          <p>
            <span>Gender : </span>
            {profile?.gender}
          </p>

          <p>
            <span>Your Phone Number : </span>
            {profile?.student_phone}
          </p>
          <p>
            <span>Parent Phone Number : </span>
            {profile?.parent_phone}
          </p>
          <p>
            <span>State : </span>
            {profile && getResidingState(profile.state)}
          </p>
          <p>
            <span>Address : </span>
            {profile?.address}
          </p>
          <p>
            <span>Class : </span>
            {profile?.standard}
          </p>
          <p>
            <span>School's Name : </span>
            {profile?.school_name}
          </p>
          <p>
            <span>Board : </span>
            {profile?.board}
          </p>
        </PersonalDetails>
      </DetailsContainer>
    </Container>
  );
};

export default Profile;
