import styled from "styled-components";

const LoadingSpinner = () => {
  return (
    <Wrapper>
      <Loader></Loader>
    </Wrapper>
  );
};

export default LoadingSpinner;

const Wrapper = styled.div`
  /* border: 1px solid green; */
  flex: 1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  border: 0.6rem solid #f3f3f3;
  border-radius: 50%;
  border-top: 0.6rem solid #656565;
  width: 7.5rem;
  height: 7.5rem;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
