import { useSearchParams } from "react-router-dom";
import { Button, Header } from "./headerStyles";

const FmHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const paymentStatus = searchParams.get("payment_status");

  const handleClick = (e) => {
    searchParams.set("payment_status", e.target.id);
    setSearchParams(searchParams);
  };

  return (
    <Header>
      <Button
        onClick={handleClick}
        id="pending"
        type="button"
        className={paymentStatus === "pending" ? "active" : null}
      >
        Pending
      </Button>
      <Button
        onClick={handleClick}
        id="paid"
        type="button"
        className={paymentStatus === "paid" ? "active" : null}
      >
        Paid
      </Button>
    </Header>
  );
};

export default FmHeader;
