const resizeProfileImage = (file, callback) => {
  // console.log(`resize called`);
  const maxDimension = 800; // max dimension of the image in pixels
  const imageQulaity = 0.8; // JPEG quality

  const reader = new FileReader();

  reader.onload = (event) => {
    const image = new Image();
    image.src = event.target.result;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      let width = image.width;
      let height = image.height;

      // Calculate the desired dimensions to maintain the aspect ratio
      if (width > height) {
        if (width > maxDimension) {
          height *= maxDimension / width;
          width = maxDimension;
        }
      } else {
        if (height > maxDimension) {
          width *= maxDimension / height;
          height = maxDimension;
        }
      }

      // Resize the image to the desired dimensions
      canvas.width = width;
      canvas.height = height;
      context.drawImage(image, 0, 0, width, height);

      // Convert the canvas to a Blob with the target quality
      canvas.toBlob(
        (blob) => {
          const compressedFile = new File([blob], file.name, {
            type: file.type,
          });

          // Invoke the callback function with the compressed file
          callback(compressedFile);
        },
        file.type,
        // targetFileSize / file.size
        imageQulaity
      );
    };
  };

  reader.readAsDataURL(file);
};

export { resizeProfileImage };
