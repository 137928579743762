import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { cancelReq, getReqStatus } from "../../../api/enrollmentApi";
import { NotificationBar } from "../../../components/notificationBar";
import RequestStatusCards from "./RequestStatusCards";
import { Container } from "./statusStyles";
import { useDispatch } from "react-redux";
import { setProgress } from "../../../redux/slices/loadingbarSlice";
import { setMetaData } from "../../../redux/slices/metaDataSlice";

const RequestStatus = () => {
  const dispatch = useDispatch();

  const {
    data: allRequests,
    isError,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["student/allRequests"],
    queryFn: () => getReqStatus(),
    refetchOnWindowFocus: false,
    retry: 1,
  });

  useEffect(() => {
    if (isError) {
      NotificationBar(
        "We are unable to get the requests that you have sent. Please try again later",
        "error",
        4000
      );
    }
  }, [isError]);

  useEffect(() => {
    if (isLoading) {
      dispatch(setProgress(70));
    } else {
      dispatch(setProgress(100));
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(setMetaData({ title: "Request Status" }));
  }, []);

  // ONLCICK FUNCTION TO CANCEL THE REQUEST
  const cancel = async (id) => {
    setProgress(20);
    try {
      setProgress(50);
      let response = await cancelReq(id);
      setProgress(90);
      if (response.status === 200) {
        NotificationBar("Request Canceled", "success");

        setProgress(100);
      }
    } catch (error) {}
  };

  return (
    <>
      <Container>
        {allRequests?.data.length > 0 ? (
          allRequests?.data.map((request) => (
            <RequestStatusCards requestDetail={request} />
          ))
        ) : (
          <p>No requests found</p>
        )}
      </Container>
    </>
  );
};

export default RequestStatus;
