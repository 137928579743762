import { Content } from "./t&cMainStyles";

const TandC = () => {
  return (
    <Content id="tandc">
      <p>
        Welcome to Schoolies! We hope that you have a pleasant experience using
        our Platform.
      </p>
      <p>
        These Terms and Conditions (“Terms and Conditions”) set out the terms
        and conditions for use of{" "}
        <span>
          <a href="https://schoolies.in">https://schoolies.in/</a>
        </span>{" "}
        (the
        <span>“Site”</span>) and any features and services offered on or through
        the Site are given below.
      </p>
      <p>
        These Terms and Conditions apply to all users of the Platform, including
        without limitations, all persons who register as a teacher on the
        Platform (referred to as <span>“Teacher”</span>) and users who register
        as a student (referred to as <span>“Student”</span>) or users who access
        the Platform otherwise (collectively referred to as <span>“you”</span>,
        <span>“your”</span>, or <span>“User(s)”</span>). These Terms and
        Conditions along with the Privacy Policy should be read together. Your
        access to the Platform is subject to your acceptance of the Platform
        Terms and Conditions and such acceptance of the Platform Terms forms a
        legally binding agreement between you and Schoolies.
      </p>
      <p>
        From time-to-time, updated versions of the Platform Terms may be made
        available as aforesaid for your reference. By visiting and accessing the
        Platform, providing your Personal Information using the Services offered
        or by otherwise signalling your agreement when the option is presented
        to you, you hereby expressly accept and agree to the Platform Terms. If
        you do not agree to any of the terms or do not wish to be bound by them,
        then please do not use the Platform in any manner.
      </p>
    </Content>
  );
};

export default TandC;
