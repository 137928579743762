import { Content, ContentHeader } from "./t&cMainStyles";

const SchooliesRights = () => {
  return (
    <Content id="schoolies_rights">
      <ContentHeader>
        <p>8. Schoolies' Rights</p>
      </ContentHeader>
      <p>
        In respect of the entire Platform, Schoolies reserves the following
        rights:
      </p>
      <ol type="a">
        <li>
          Schoolies reserves the right to put on-hold/ reject/ suspend/
          terminate your account on the Platform if the required details are
          incomplete/ incorrect.
        </li>
        <li>
          Schoolies reserves the right to remove you and/or the Users without
          notice if you violate any provisions of the Platform Terms.
        </li>
        <li>
          Schoolies may modify, terminate, or refuse to provide Services at any
          time for any reason, without notice.
        </li>
        <li>
          Schoolies reserves the right to access your account and/or the Users
          in order to respond to requests for technical support, to maintain the
          safety and security of the Platform.
        </li>
        <li>
          If Schoolies receives notice or becomes aware of, any violation of the
          Platform Terms, then, Schoolies reserves the right to refuse your
          access to the Platform, terminate accounts.
        </li>
      </ol>
    </Content>
  );
};

export default SchooliesRights;
