import { useEffect } from "react";
import LocationAutocomplete from "../../../../components/Location Autocomplete/LocationAutocomplete";
import { scroll_to_top } from "../../../../utils/scrollActions";
import { stateList } from "../../../../utils/stateList";
import { InputContainer } from "../editProStyles";
import {
  PersonalDetailsContainer,
  PhotoUploadContainer,
  ProfilePhotoPreview,
} from "./personalFormStyles";

const PersonalDetailsForm = ({
  detailsData,
  changePhoto,
  photoPreview,
  handleChangeFormData,
  handleOnlyNumbers,
  isPhotoResizing,
  locationOnChange,
  locationOnClick,
  suggestions,
  isSuggestionSelected,
}) => {
  useEffect(() => {
    scroll_to_top();
  }, []);

  return (
    <>
      <PhotoUploadContainer>
        <ProfilePhotoPreview>
          <img src={photoPreview} alt="" />
        </ProfilePhotoPreview>
        <label htmlFor="photo">
          {isPhotoResizing ? (
            <p>Resizing Photo...</p>
          ) : (
            <>
              <i className="fas fa-camera-retro"></i>
              <p>Upload your photo</p>
            </>
          )}
        </label>
        <input
          type="file"
          name="photo"
          id="photo"
          accept="image/*"
          onChange={changePhoto}
          disabled={isPhotoResizing}
          required
        />
      </PhotoUploadContainer>
      <PersonalDetailsContainer>
        <InputContainer>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={detailsData.name}
            onChange={handleChangeFormData}
            required
          />
        </InputContainer>

        <InputContainer>
          <label htmlFor="adhaar_number">Aadhaar Number</label>
          <input
            type="tel"
            name="adhaar_number"
            id="adhaar_number"
            value={detailsData.adhaar_number}
            onChange={handleOnlyNumbers}
            maxLength={12}
            required
          />
        </InputContainer>

        <InputContainer>
          <label htmlFor="phone">
            Phone Number
            <p className="disclaimer">
              Provide the number attached to your adhar card.
            </p>
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={detailsData.phone}
            onChange={handleOnlyNumbers}
            maxLength={10}
            required
          />
        </InputContainer>

        <InputContainer>
          <label htmlFor="state"> State </label>
          <select
            name="state"
            id="state"
            value={detailsData.state}
            onChange={handleChangeFormData}
            required
          >
            <option value="0" disabled hidden>
              Select
            </option>
            {stateList.map((state) => (
              <option key={state.stateAbbr} value={state.stateAbbr}>
                {state.stateName}
              </option>
            ))}
          </select>
        </InputContainer>

        <InputContainer>
          <label htmlFor="location">
            Location
            <p className="disclaimer">
              Please avoid adding sensitive information like building name,
              house name/number etc. here. Just give your location name.
            </p>
          </label>
          <LocationAutocomplete
            value={detailsData.location}
            name="location"
            id="location"
            onChange={locationOnChange}
            onSuggestionClick={locationOnClick}
            suggestions={suggestions}
            isSuggestionSelected={isSuggestionSelected}
            required={true}
          />
        </InputContainer>

        <InputContainer>
          <label htmlFor="full_address">Full Address</label>
          <input
            type="text"
            name="full_address"
            id="full_address"
            value={detailsData.full_address}
            onChange={handleChangeFormData}
            required
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="date_of_birth">Date of Birth</label>
          <input
            type="date"
            name="date_of_birth"
            id="date_of_birth"
            value={detailsData.date_of_birth}
            onChange={handleChangeFormData}
            required
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="gender"> Gender </label>
          <select
            name="gender"
            id="gender"
            value={detailsData.gender}
            onChange={handleChangeFormData}
            required
          >
            <option value="0" disabled hidden>
              Select
            </option>
            <option value="MALE">MALE</option>
            <option value="FEMALE">FEMALE</option>
          </select>
        </InputContainer>
      </PersonalDetailsContainer>
    </>
  );
};

export default PersonalDetailsForm;
