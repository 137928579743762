import { motion } from "framer-motion";
import { useEffect } from "react";
import Helmet from "react-helmet";
import { scroll_to_top } from "../../utils/scrollActions";
import { Container } from "./vEStyles";

const VerifyEmailSent = () => {
  useEffect(() => {
    scroll_to_top();
  }, []);

  return (
    <Container
      as={motion.div}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <title>Schoolies</title>
      </Helmet>

      <p>
        An email has been sent to your registered email address for
        verification. If you cannot find the email in your inbox, please check
        spam folder also.
      </p>

      <p className="highlight">The link shall expire within 5min.</p>
    </Container>
  );
};

export default VerifyEmailSent;
