import { Content, ContentHeader } from "./t&cMainStyles";

const AboutPlatformServices = () => {
  return (
    <Content id="about_platform_services">
      <ContentHeader>
        <p>1. About Platform Services</p>
      </ContentHeader>
      <p>
        Schoolies Platform is an online service platform enabling teachers to
        create account as teacher so that student can find them in this platform
        or to keep themselves available in the platform for the students.
        Schoolies acts as an intermediary between the teachers and students. In
        the same way students have to register themselves as stadents to access
        teachers.
      </p>
      <p>
        You agree to the terms and conditions and acknowledge that Schoolies
        shall have the right at any time to change or discontinue any Service ,
        feature of the Platform. Schoolies reserves the right to restrict your
        access to the Platform, if you choose not to provide/ partially provide
        the requested information.
      </p>
    </Content>
  );
};

export default AboutPlatformServices;
