import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserAccount } from "../../api/authApi";
import { NotificationBar } from "../notificationBar";

import { useNavigate } from "react-router-dom";
import { logoutAction } from "../../redux/actions/authAction";
import { getUserProfile } from "../../redux/slices/userInfoSlice";
import {
  Container,
  DeleteAccountForm,
  Heading,
  InputContainer,
  SubmitBtn,
} from "./deleteAccountStyles";

const DeleteAccount = () => {
  const { user } = useSelector((state) => state.auth);
  const profile = useSelector(getUserProfile);
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleEmailValidation = (e) => {
    setEmail(e.target.value);
    if (e.target.value !== profile.email) {
      return setIsValid(false);
    }
    setIsValid(true);
  };

  const controlSubmitDisability = () => {
    if (isValid) {
      return false;
    }
    return true;
  };

  // handle logout
  const handleLogout = async () => {
    try {
      let response = await dispatch(logoutAction()).unrwap();
      return navigate("/");
    } catch (error) {}
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();

    try {
      let response = await deleteUserAccount();
      if (response.status === 200) {
        NotificationBar("Your account has been deleted.", "success");
        setTimeout(handleLogout, 1000);
      } else if (response.status === 409) {
        NotificationBar(`${response.data.message}`, `error`);
        return;
      } else {
        NotificationBar(
          `Cannot delete your account at the moment. Please try again later`,
          `error`
        );
        return;
      }
    } catch (error) {
      NotificationBar("Server error", "error");
    }
  };

  return (
    <Container>
      <DeleteAccountForm onSubmit={handleDeleteSubmit}>
        <Heading>
          {user.regd_as === "TCH" ? (
            <p>
              Deleting this account will delete all you details immediately
              along with all the pending requests as well as enrolled students.
              We will never be able to recover them once you delete this
              account.
            </p>
          ) : (
            <p>
              Deleting this account will delete all you details immediately
              along with all the pending requests that you have sent as well as
              the teachers with whom you have enrolled. We will never be able to
              recover them once you delete this account.
            </p>
          )}
          <p>
            <span>Are you sure you want to delete this account?</span>
          </p>
        </Heading>
        <InputContainer isValid={isValid}>
          <label htmlFor="email">
            Please type the following <span>{profile.email}</span>
          </label>
          <input
            value={email}
            onChange={handleEmailValidation}
            type="email"
            name="email"
            id="email"
            required
          />
        </InputContainer>
        <SubmitBtn disabled={controlSubmitDisability()} type="submit">
          Delete Account
        </SubmitBtn>
      </DeleteAccountForm>
    </Container>
  );
};

export default DeleteAccount;
