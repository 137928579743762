import { Content, ContentHeader } from "./privacyStyles";

const HowWeUseInfo = () => {
  return (
    <Content id="use_info">
      <ContentHeader>
        <p>3. How We Use Information</p>
      </ContentHeader>
      <p>
        We collect information about your usage of our Platform. We also collect
        information about what happens when you use our Platform (e.g., page
        views, number of students enrolled, number of requests you receive etc).
        We use this information to provide our Platform to you, get insights on
        how people use our Platform so that we can make our Platform better, and
        understand and make predictions about User retention. We use your
        information to improve and customise the Platform and Services offered
        by us, including providing automatic updates to newer versions of our
        Platform and creating new features based on the Platform usage analysis.
        We may use information we gather to determine which areas of the
        Services are most frequently visited to understand how to enhance the
        Services.
      </p>
      <p>
        We may determine the approximate location of your Supported Device from
        your Internet Protocol (IP) address. We may collect and use this
        information to calculate how many people visit from certain geographic
        regions or to improve our Platform Services.
      </p>
      <p>
        We use your information as collected by us to allow you to access the
        Platform and the Services offered therein, we also use your information
        to prevent, detect, investigate, and take measures against criminal
        activity, fraud, misuse of or damage to our Platform or network, and
        other threats and violations to Schoolies or the safety of Schoolies,
        its users, or others.
      </p>
      <p>
        If we use or plan to use your information in a manner different than the
        purpose for which it is collected, then we will ask you for your consent
        prior to such use.
      </p>
    </Content>
  );
};

export default HowWeUseInfo;
