import styled from "styled-components";

export const Wrapper = styled.main`
  min-height: calc(100vh - (var(--size-rootheader)));
  display: flex;
  flex-direction: column;
  background: #f0f2f5;
  gap: 0.1rem;
`;

export const QueryHeader = styled.div`
  border-top: 1px solid var(--clr-neutral-400);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-neutral-100);
  padding: 0.9rem 1rem;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  gap: 0.2rem;
  /* padding: 1rem 0.5rem; */

  @media sreen and (max-width: 60em) {
    padding-inline: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: none;

  @media screen and (max-width: 60em) {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
  }
`;
