import { useQueries } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getTotalCollectedFees,
  getTotalExpectedFees,
} from "../../../api/teacher";
import { NotificationBar } from "../../../components/notificationBar";
import {
  AmountValue,
  Card,
  CardContainer,
  CardTitle,
  ExtraCardInfo,
  RupeeSymbol,
} from "./layoutStyles";

const Analytics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tuitionTypeParam = searchParams.get("tuition_type");
  const monthParam = searchParams.get("month");
  const yearParam = searchParams.get("year");
  const isParams =
    tuitionTypeParam !== null && monthParam !== null && yearParam !== null;

  const [totalExpectedFees, setTotalExpectedFees] = useState("");
  const [totalCollectedFee, setTotalCollectedFee] = useState("");
  const [totalPayout, setTotalPayout] = useState("");
  const [displayDate, setDisplayDate] = useState(null);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  const cardData = useQueries({
    queries: [
      {
        queryKey: [
          "feeManagement/totalExpectedFees",
          tuitionTypeParam,
          monthParam,
          yearParam,
        ],
        enabled: isParams === true,

        refetchOnWindowFocus: false,
        queryFn: () =>
          getTotalExpectedFees(tuitionTypeParam, monthParam, yearParam),
        onSuccess: (response) => {
          setTotalExpectedFees(response.data.total_expected_fees);
        },
        onError: (error) => {
          NotificationBar(
            "We are unable to fetch the total expected fee at the moment",
            "error",
            4000
          );
          setTotalExpectedFees("0");
        },
      },
      {
        enabled: isParams === true,
        refetchOnWindowFocus: false,
        queryKey: [
          "feeManagement/totalCollectedFee",
          tuitionTypeParam,
          monthParam,
          yearParam,
        ],

        queryFn: () =>
          getTotalCollectedFees(tuitionTypeParam, monthParam, yearParam),

        onSuccess: (response) => {
          setTotalCollectedFee(response.data.total_collected_fees);
        },
        onError: (error) => {
          NotificationBar(
            "We are unable to fetch the total collected fee at the moment",
            "error",
            4000
          );
          setTotalCollectedFee("0");
        },
      },
    ],
  });

  const handleDisplayDate = () => {
    let checkMonth = Number(monthParam) === currentMonth;
    let checkYear = Number(yearParam) === currentYear;

    let dateObj;
    let formatedDate;

    if (yearParam && monthParam) {
      if (checkMonth && checkYear) {
        dateObj = currentDate;
        formatedDate = dayjs(dateObj).format("DD MMMM, YYYY");
      } else {
        dateObj = new Date(Number(yearParam), Number(monthParam) - 1);
        formatedDate = dayjs(dateObj).format("MMMM-YYYY");
      }
    }
    setDisplayDate(formatedDate);
  };

  useEffect(() => {
    handleDisplayDate();
  }, [monthParam, yearParam]);

  return (
    <CardContainer>
      <Card $bgColor="#EEF4FF">
        <CardTitle>Total Expected Fees</CardTitle>
        {displayDate && <ExtraCardInfo>As on {displayDate}</ExtraCardInfo>}
        <AmountValue>
          <RupeeSymbol>₹</RupeeSymbol> {totalExpectedFees}
        </AmountValue>
      </Card>

      <Card $bgColor="#DBF9F2">
        <CardTitle>Total Collected Fees</CardTitle>
        {displayDate && <ExtraCardInfo>As on {displayDate}</ExtraCardInfo>}
        <AmountValue>
          <RupeeSymbol>₹</RupeeSymbol> {totalCollectedFee}
        </AmountValue>
      </Card>
    </CardContainer>
  );
};

export default Analytics;
