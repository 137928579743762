import { useLayoutEffect } from "react";

import { scroll_to_top } from "../../utils/scrollActions";
import HowLongInfoStored from "./HowLongInfoStored";
import HowWeUseInfo from "./HowWeUseInfo";
import IntroToPrivacy from "./IntroToPrivacy";
import ModificationToPrivacyPolicy from "./ModificationToPrivacyPolicy";
import Security from "./Security";
import TableContents from "./TableContents";
import UseOfPlatformByChild from "./UseOfPlatformByChild";
import UserAccess from "./UserAccess";
import WeCollectInfo from "./WeCollectInfo";
import { MainContentContainer, MainHeading, Wrapper } from "./privacyStyles";

const PrivacyPolicyMain = () => {
  useLayoutEffect(() => {
    scroll_to_top();
  }, []);

  return (
    <Wrapper>
      <MainContentContainer>
        <MainHeading>
          <p>Schoolies - Privacy Policies</p>
        </MainHeading>
        <TableContents />
        <IntroToPrivacy />
        <WeCollectInfo />
        <HowWeUseInfo />
        <Security />
        <HowLongInfoStored />
        <UserAccess />
        <UseOfPlatformByChild />
        <ModificationToPrivacyPolicy />
      </MainContentContainer>
    </Wrapper>
  );
};

export default PrivacyPolicyMain;
