export const getResidingState = (key) => {
  let stateData = {
    AN: "Andaman and Nicobar",
    AP: "Andhra Pradesh",
    AR: "Arunachal Pradesh",
    AS: "Assam",
    BR: "Bihar",
    CH: "Chandigarh",
    DN: "Dadra and Nagar Haveli",
    DD: "Daman and Diu",
    DL: "Delhi",
    GA: "Goa",
    GJ: "Gujarat",
    HR: "Haryana",
    HP: "Himachal Pradesh",
    JK: "Jammu and Kashmir",
    KA: "Karnataka",
    KL: "Kerala",
    LD: "Lakshadweep",
    MP: "Madhya Pradesh",
    MH: "Maharashtra",
    MN: "Manipur",
    ML: "Meghalaya",
    MZ: "Mizoram",
    NL: "Nagaland",
    OR: "Orissa",
    PY: "Pondicherry",
    PN: "Punjab",
    RJ: "Rajasthan",
    SK: "Sikkim",
    TN: "TamilNadu",
    TR: "Tripura",
    UP: "Uttar Pradesh",
    WB: "West Bengal",
  };

  return stateData[key];
};
