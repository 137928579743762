import { Content, ContentHeader } from "./privacyStyles";

const IntroToPrivacy = () => {
  return (
    <Content id="intro">
      <ContentHeader>
        <p>1. Introduction to Privacy Policy</p>
      </ContentHeader>
      <p>
        This privacy policy <span>(the "PRIVACY POLICY") </span>applies to your
        use of the website of Schoolies hosted at schoolies.in. The terms
        <span> "WE"</span>, <span>"OUR"</span> and <span>"US"</span> refer to
        Schoolies and the terms <span>"YOU"</span>, <span> "YOUR"</span> and
        <span> "USER(S)"</span> refer to all users of the Platform, including
        all students and teachers or tutors of Schoolies.
      </p>
      <p>
        The term “Personal Information” means the information which identifies a
        User, that is, full name, aadhar number/ identification number, email
        address, age, gender, location, photograph and/or phone number and any
        other data tie to such information that are provided at the time of
        registration or any time thereafter on the Platform. Our practices and
        procedures in relation to the collection and use of Personal Information
        have been set-out below in order to ensure safe usage of the Website for
        you. we try our best to provide security because of the inherent
        vulnerabilities of the internet, we cannot ensure or warrant complete
        security of all information that is being transmitted to us by you.
      </p>
      <p>
        Please read this policy carefully prior to accessing our platform and
        using the services. By visiting this Website, you agree and acknowledge
        to be bound by this Privacy Policy and you hereby consent that we will
        collect, use, process and share your Personal Information. If you do not
        agree with these terms, do not use the Platform. If any information you
        have provided or uploaded on the Platform violates the Platform’s Terms
        and conditions. Schoolies may be required to delete such information and
        revoke your access to the Platform if required.
      </p>
      <p>
        It is clarified that the terms and conditions that are provided
        separately, form an integral part of your use of this Website and should
        be read in conjunction with this Privacy Policy.
      </p>
      <p>
        If you have any questions about this Policy, please contact us at
        privacy@schoolies.com
      </p>
    </Content>
  );
};

export default IntroToPrivacy;
