import React from "react";

import { Content, ContentHeader } from "./privacyStyles";

const Security = () => {
  return (
    <Content id="security">
      <ContentHeader>
        <p>4. Security</p>
      </ContentHeader>
      <p>
        Your account is password protected. We protect the Personal Information
        that is stored in our database. However, no form or method of data
        storage or transmission system is fully secured. It is important for you
        to protect your account against unauthorised access to or use of your
        password and to your computer and if you have any reason to believe that
        your password has become known to anyone else, or if the password is
        being, or is likely to be, used in an unauthorised manner, you must
        immediately change your password or inform us. We limit the access to
        your Personal Information to those employees and contractors who need
        access to perform their job function, such as our customer service
        personnell.
      </p>
    </Content>
  );
};

export default Security;
