import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link as SLink } from "react-scroll";
import { staticUrl } from "../../utils/axiosInstance.js";

import { useDispatch } from "react-redux";
import { setMetaData } from "../../redux/slices/metaDataSlice.js";
import {
  Container,
  Headline,
  HeroSection,
  Intro,
  IntroAndImgContainer,
  StudenSeperator,
  StudentHeadline,
  StudentImage,
  StudentIntroImageContainer,
  StudentIntroPara,
  StudentIntroSection,
  StudentMainContainer,
  TandSImgContainer,
  TeacherHeadline,
  TeacherImage,
  TeacherIntro,
  TeacherIntroImageContainer,
  TeacherIntroSection,
  TeacherMainContainer,
  TeacherSeperator,
} from "./servicesStyles.js";

const Service = () => {
  // this is to load the page at the top whenever visited
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const dispatch = useDispatch();

  // for tracking if the element is in view
  const [studentRef, studentinView] = useInView({
    threshold: 0.7,
  });
  const [teacherRef, teacherinView] = useInView({
    threshold: 0.7,
  });

  //asigning for animation purposes
  const studenAanimation = useAnimation();
  const teacherAnimation = useAnimation();

  //this is for animation of the student image
  useEffect(() => {
    if (studentinView) {
      studenAanimation.start({
        x: 0,
        transition: {
          duration: 1,
          ease: "easeInOut",
        },
      });
    }

    if (!studentinView) {
      studenAanimation.start({
        x: "15vw",
        transition: {
          duration: 1,
          ease: "easeInOut",
        },
      });
    }
  }, [studentinView]);

  // this is for animation of the teacher image
  useEffect(() => {
    if (teacherinView) {
      teacherAnimation.start({
        x: 0,
        transition: {
          duration: 1,
          ease: "easeInOut",
        },
      });
    }

    if (!teacherinView) {
      teacherAnimation.start({
        x: "15vw",
        transition: {
          duration: 1,
          ease: "easeInOut",
        },
      });
    }
  }, [teacherinView]);

  useEffect(() => {
    let description =
      "Well, it's pretty simple. We connect students to tutors/ teachers and vice-versa. We noticed that if you are a student it sometimes takes a good effort to find a tutor/ teachers around you, and if you are a tutor it also takes effort to find students. Therefore we decided to reduce your efforts in a very easy way.";
    dispatch(
      setMetaData({
        title: "Services",
        description: description,
      })
    );
  }, []);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <HeroSection>
        <Headline>
          <p>What We Do?</p>
        </Headline>
        <IntroAndImgContainer>
          <Intro>
            <p>
              Well, it's pretty simple. We connect students to tutors and vice
              versa. We noticed that if you are a student it sometimes takes a
              good effort to find a tutor around you, and if you are a tutor it
              also takes effort to find students. Therefore we decided to reduce
              your efforts in a very easy way.
            </p>

            <SLink
              spy={true}
              smooth={true}
              offset={-100}
              duration={50}
              to="knowhow"
            >
              Know How <i className="fas fa-hand-point-down"></i>
            </SLink>
          </Intro>
          <TandSImgContainer
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,

              transition: {
                duration: 1,
                delay: 0.3,
              },
            }}
          >
            <img
              src={`${staticUrl}images/service_student_teacher.svg`}
              alt="service"
            />
          </TandSImgContainer>
        </IntroAndImgContainer>
      </HeroSection>
      {/* Student Service Section */}

      <StudentMainContainer id="knowhow">
        <StudenSeperator></StudenSeperator>

        <StudentIntroImageContainer>
          <StudentIntroSection ref={studentRef}>
            <StudentHeadline>
              <p>Are you a Student?</p>
            </StudentHeadline>
            <StudentIntroPara>
              <p>
                We understand that sometimes finding a tutor according to your
                requirement is tough. Perhaps the best tutor for others may not
                be the best for you. Walking from door to door finding an
                appropriate tutor in accordance with your requirements might be
                difficult. You might not be aware that a tutor perhaps lives
                near your location and you do not have to cover long distances
                to take tuition. So, here we solve this problem for you. We
                reduce your efforts to find the perfect tutor for yourself. Just
                register yourself as a student, search for the tutor with their
                name, location or a subject you want to get taught. Choose from
                the results that come up, send a request to join them and that's
                it. It's that easy.
              </p>
            </StudentIntroPara>
          </StudentIntroSection>
          <StudentImage as={motion.div} animate={studenAanimation}>
            <img
              src={`${staticUrl}images/student.svg`}
              alt="thinking student"
            />
          </StudentImage>
        </StudentIntroImageContainer>
      </StudentMainContainer>

      {/* Teacher Section */}

      <TeacherMainContainer>
        <TeacherSeperator></TeacherSeperator>

        <TeacherIntroImageContainer>
          <TeacherIntroSection ref={teacherRef}>
            <TeacherHeadline>
              <p>Are you a Teacher?</p>
            </TeacherHeadline>
            <TeacherIntro>
              <p>
                Are you a teaching enthusiast or you like to impart/share your
                knowledge with others but is unable to gather/find students for
                the same. If yes we are here to bridge the gap between teachers
                and students and help them connect through our platform. All you
                need to do is simply make your profile and we will help you find
                suitable student's pool. So get ready to bestow your knowledge
                and make some extra money.
              </p>
            </TeacherIntro>
          </TeacherIntroSection>
          <TeacherImage as={motion.div} animate={teacherAnimation}>
            <img src={`${staticUrl}images/Teacher.svg`} alt="teacher" />
          </TeacherImage>
        </TeacherIntroImageContainer>
      </TeacherMainContainer>
    </Container>
  );
};

export default Service;
