import { createSlice } from "@reduxjs/toolkit";
import { fetchOptions } from "../actions/optionsActions";

const initialState = {
  status: "idle",
  subject: [],
  standard: {},
  board: [],
  error: null,
};

const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptions.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchOptions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.board = action.payload.board;
        state.standard = action.payload.standard;
        state.subject = action.payload.subject;
      })
      .addCase(fetchOptions.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const getOptionsStatus = (state) => state.options.status;

export const getBoardOptions = (state) => state.options.board;
export const getSubjectOptions = (state) => state.options.subject;

export const getClassesOptions = (state) => state.options.standard;

export default optionsSlice.reducer;
