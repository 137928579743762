import axiosInstance from "../utils/axiosInstance";

// funtion to mail reset link

export const sendResetEmail = async (email) => {
  let body = {
    email: email,
  };
  try {
    let response = await axiosInstance.post(
      `/account/password-reset-mail/`,
      body
    );
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
};

// funtion to mail reset link

export const resetPassword = async (token, newPassword) => {
  let body = {
    token: token,
    password: newPassword,
  };
  try {
    let response = await axiosInstance.post(`/account/reset-password/`, body);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else {
      return error;
    }
  }
};
