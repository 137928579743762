import styled from "styled-components";
export const SearchbarWrapper = styled.div`
  /* outline: 1px solid green; */
  display: flex;
  justify-content: center;
  width: 50rem;
  border-radius: 0.55rem;
  background: var(--clr-neutral-100);
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1),
    0 6px 6px -5px rgba(0, 0, 0, 0.1);

  font-family: var(--ff-secondary);

  @media screen and (max-width: 60em) {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* max-height: 19rem; */
    border-radius: 0;
    padding: 1rem;

    z-index: 101;
    transition: all 300ms ease-in-out;
    transform: ${({ $toggleDisplay }) =>
      $toggleDisplay ? "translateY(0)" : "translateY(-100%)"};

    box-shadow: ${({ $toggleDisplay }) =>
      $toggleDisplay ? "0 8px 16px 8px rgba(0, 0, 0, 0.2)" : "none"};
  }
`;

export const SearchForm = styled.form`
  --input-width: 100%;
  --input-height: 100%;

  /* outline: 1px solid purple; */
  width: 100%;
  min-height: 3rem;
  border-radius: 0.55rem;

  display: grid;

  grid-template-columns:
    minmax(10rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr)
    minmax(4rem, 9rem);

  align-items: center;

  @media screen and (max-width: 60em) {
    align-items: normal;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto 1rem auto;

    grid-template-areas:
      "location location"
      "subject standard"
      ". ."
      "button button";
  }
`;

export const InputContainer = styled.div`
  /* outline: 1px solid green; */
  border: 2px solid #ececec;
  display: flex;
  flex-direction: column;
  height: var(--input-height);
  width: var(--input-width);
  padding-top: 0.3rem;
  &:first-child {
    border-bottom-left-radius: 0.55rem;
    border-top-left-radius: 0.55rem;
  }

  &:not(:first-child) {
    border-left: none;
  }

  &:nth-child(3) {
    border-right: none;
  }

  &:last-of-type {
    border: none;
    padding: 0;
    border-bottom-right-radius: 0.55rem;
    border-top-right-radius: 0.55rem;
  }

  label {
    display: flex;
    padding-inline: 0.5rem;
    font-size: 0.9rem;
    font-weight: var(--fw-bold);
    color: rgba(0, 0, 0, 0.5);
  }
  input[type="text"] {
    height: var(--input-height);
    width: var(--input-width);
    padding: 0.5rem;
    outline: none;
    border: none;
    font-size: 1rem;
    border-bottom-left-radius: 0.55rem;

    &::placeholder {
      font-size: 0.9rem;
    }
  }

  @media screen and (max-width: 60em) {
    border: 2px solid #ececec;
    flex-direction: column;
    padding: 0.5rem 1rem;

    &:first-child {
      border-radius: 0;
    }

    input[type="text"] {
      border-radius: 0;
    }

    &:last-of-type {
      border: none;
      padding: 0;
    }

    &.location {
      /* outline: 3px solid red; */
      grid-area: location;
      border-bottom: none;
    }
    &.subject {
      /* outline: 3px solid red; */
      border-left: 2px solid #ececec;
      grid-area: subject;
    }
    &.standard {
      grid-area: standard;
      border-right: 2px solid #ececec;
    }
    &.button {
      /* margin-top: 1rem; */

      grid-area: button;
    }
  }
`;

export const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-top-right-radius: 0.55rem;
  border-bottom-right-radius: 0.55rem;
  background-color: #007bff;
  color: var(--clr-neutral-100);
  font-family: var(--ff-button);
  font-size: 1rem;
  font-weight: var(--fw-bold);
  height: 100%;
  cursor: pointer;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  /* justify-self: stretch; */
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }

  @media screen and (max-width: 60em) {
    border-radius: 0.55rem;
    padding: 0.75rem;
  }
`;

export const SearchHeader = styled.div`
  display: none;
  @media screen and (max-width: 60em) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const CloseButton = styled.button`
  display: none;
  font-size: 1.5rem;
  background: transparent;
  outline: none;
  border: none;
  @media screen and (max-width: 60em) {
    display: block;
  }
`;
