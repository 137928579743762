import {
  PersonalDetailsContainer,
  PhotoDisclaimer,
  PhotoUploadContainer,
  ProfilePhotoPreview,
} from "./editProfileStyles";

import { useSelector } from "react-redux";
import {
  getBoardOptions,
  getClassesOptions,
} from "../../../../redux/slices/optionsSlice";
import { stateList } from "../../../../utils/stateList";
import { InputContainer } from "../EditProfileStyles";

const EditProfileForm = ({
  detailsData,
  changePhoto,
  photoPreview,
  handleChangeDetailsData,
  handleOnlyNumbers,
  isPhotoResizing,
}) => {
  const boardOptions = useSelector(getBoardOptions);
  const classsesOptions = useSelector(getClassesOptions);
  return (
    <>
      <PhotoUploadContainer>
        <ProfilePhotoPreview>
          <img src={photoPreview} alt="" />
        </ProfilePhotoPreview>
        <label htmlFor="photo">
          {isPhotoResizing ? (
            <p>Resizing Photo...</p>
          ) : (
            <>
              <i className="fas fa-camera-retro"></i>
              <p>Upload your photo</p>
            </>
          )}
        </label>
        <input
          type="file"
          name="photo"
          id="photo"
          accept="image/png, image/jpeg"
          onChange={changePhoto}
          disabled={isPhotoResizing}
        />
        <PhotoDisclaimer>
          <p>
            The photo is displayed to teachers for idetification purposes only
            after they accept your request.
          </p>
        </PhotoDisclaimer>
      </PhotoUploadContainer>
      <PersonalDetailsContainer>
        <InputContainer>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            value={detailsData.name}
            onChange={handleChangeDetailsData}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="date_of_birth">Date of Birth</label>
          <input
            type="date"
            name="date_of_birth"
            id="date_of_birth"
            value={detailsData.date_of_birth}
            onChange={handleChangeDetailsData}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="gender"> Gender </label>
          <select
            name="gender"
            id="gender"
            value={detailsData.gender}
            onChange={handleChangeDetailsData}
          >
            <option value="0" disabled hidden>
              Select
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </InputContainer>
        <InputContainer>
          <label htmlFor="parent_name">
            Parent's Name
            <p className="disclaimer">Either father's or mother's</p>
          </label>
          <input
            type="text"
            name="parent_name"
            id="parent_name"
            value={detailsData.parent_name}
            onChange={handleChangeDetailsData}
          />
        </InputContainer>

        <InputContainer>
          <label htmlFor="student_phone">Your Phone Number</label>
          <input
            type="tel"
            name="student_phone"
            id="student_phone"
            value={detailsData.student_phone}
            onChange={handleOnlyNumbers}
            maxLength={10}
          />
        </InputContainer>

        <InputContainer>
          <label htmlFor="parent_phone">Parent's Phone Number</label>
          <input
            type="tel"
            name="parent_phone"
            id="parent_phone"
            value={detailsData.parent_phone}
            onChange={handleOnlyNumbers}
            maxLength={10}
          />
        </InputContainer>

        <InputContainer>
          <label htmlFor="state"> State </label>
          <select
            name="state"
            id="state"
            value={detailsData.state}
            onChange={handleChangeDetailsData}
          >
            <option value="" disabled hidden>
              Select
            </option>
            {stateList.map((state) => (
              <option key={state.stateAbbr} value={state.stateAbbr}>
                {state.stateName}
              </option>
            ))}
          </select>
        </InputContainer>
        <InputContainer>
          <label htmlFor="address">Full Address</label>
          <input
            type="text"
            name="address"
            id="address"
            value={detailsData.address}
            onChange={handleChangeDetailsData}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="standard">Your Class</label>
          <select
            name="standard"
            id="standard"
            onChange={handleChangeDetailsData}
            value={detailsData.standard}
          >
            <option hidden disabled value="">
              Select
            </option>
            <>
              {classsesOptions.lower_primary?.length > 0 && (
                <optgroup label="LOWER PRIMARY">
                  {classsesOptions.lower_primary.map((cls) => (
                    <option key={cls.name_of_class} value={cls.name_of_class}>
                      {cls.name_of_class}
                    </option>
                  ))}
                </optgroup>
              )}
              {classsesOptions.upper_primary?.length > 0 && (
                <optgroup label="UPPER PRIMARY">
                  {classsesOptions.upper_primary.map((cls) => (
                    <option key={cls.name_of_class} value={cls.name_of_class}>
                      {cls.name_of_class}
                    </option>
                  ))}
                </optgroup>
              )}
              {classsesOptions.secondary?.length > 0 && (
                <optgroup label="SECONDARY">
                  {classsesOptions.secondary.map((cls) => (
                    <option key={cls.name_of_class} value={cls.name_of_class}>
                      {cls.name_of_class}
                    </option>
                  ))}
                </optgroup>
              )}
              {classsesOptions.higher_secondary?.length > 0 && (
                <optgroup label="HIGHER SECONDARY">
                  {classsesOptions.higher_secondary.map((cls) => (
                    <option key={cls.name_of_class} value={cls.name_of_class}>
                      {cls.name_of_class}
                    </option>
                  ))}
                </optgroup>
              )}
              {classsesOptions.graduation?.length > 0 && (
                <optgroup label="GRADUATION">
                  {classsesOptions.graduation.map((cls) => (
                    <option key={cls.name_of_class} value={cls.name_of_class}>
                      {cls.name_of_class}
                    </option>
                  ))}
                </optgroup>
              )}
              {classsesOptions.post_graduation?.length > 0 && (
                <optgroup label="POST GRADUATION">
                  {classsesOptions.post_graduation.map((cls) => (
                    <option key={cls.name_of_class} value={cls.name_of_class}>
                      {cls.name_of_class}
                    </option>
                  ))}
                </optgroup>
              )}
            </>
          </select>
        </InputContainer>
        <InputContainer>
          <label htmlFor="school_name">School's Name</label>
          <input
            type="text"
            name="school_name"
            id="school_name"
            value={detailsData.school_name}
            onChange={handleChangeDetailsData}
          />
        </InputContainer>
        <InputContainer>
          <label htmlFor="board">Board</label>
          <select
            name="board"
            id="board"
            onChange={handleChangeDetailsData}
            value={detailsData.board}
          >
            <option hidden disabled value="">
              Select
            </option>
            {boardOptions?.length > 0 &&
              boardOptions.map((element, index) => {
                return (
                  <option key={index} value={element.board_name}>
                    {element.board_name}
                  </option>
                );
              })}
          </select>
        </InputContainer>
      </PersonalDetailsContainer>
    </>
  );
};

export default EditProfileForm;
