import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  overflow: hidden;
  @media screen and (max-width: 50em) {
    padding: 1rem;
  }
`;

export const ModalBox = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 1.25rem;
  max-width: 400px;
  width: 100%;
`;

export const ModalHeader = styled.header`
  display: flex;
  align-items: center;
  /* border-bottom: 2px solid #e0e0e0; */
  gap: 1.3rem;
  user-select: none;
  h3 {
    font-size: 1.25rem;
    font-family: var(--ff-primary);
    font-weight: 700;
  }
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

export const Button = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  border-radius: 0.5em;
  box-sizing: border-box;
  color: #3b3b3b;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-family: var(--ff-button);
  font-weight: var(--fw-bold);
  /* min-height: 3.75em; */
  min-width: 0;
  outline: none;
  padding: 0.7em 1.6em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;

  &:hover {
    color: #fff;
    /* background-color: #1a1a1a; */
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: none;
    transform: translateY(0);
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #bdc3c7; /* Disabled button color */
    border: 0.06em solid #bdc3c7;
    color: #7f8c8d;
  }
`;

export const ConfirmButton = styled(Button)`
  border: 0.06em solid #1b4dff;
  color: #fff;
  background-color: #1b4dff;
`;

export const CancelButton = styled(Button)`
  border: 0.06em solid #1a1a1a;
  &:hover {
    border: 0.06em solid #f44336;
    background-color: #f44336;
  }
`;

export const CloseButton = styled(Button)`
  border: 0.06em solid #1a1a1a;
  &:hover {
    border: 0.06em solid #f44336;
    background-color: #f44336;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;
