import { TableContentLink, TableContentContainer } from "./privacyStyles";

const TableContents = () => {
  return (
    <TableContentContainer>
      <ol>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="intro"
          >
            Introduction to privacy policy
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="we_collect_info"
          >
            We collect information
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="use_info"
          >
            How we use your information
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="security"
          >
            Security
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="how_long"
          >
            How long your information can be stored
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="user_access"
          >
            User Access
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="use_of_platform_by_children"
          >
            Use of the Platform/Services by Children
          </TableContentLink>
        </li>
        <li>
          <TableContentLink
            spy={true}
            smooth={true}
            offset={-75}
            duration={0}
            to="modification_to_pricvacy_policy"
          >
            Modification to privacy policy
          </TableContentLink>
        </li>
      </ol>
    </TableContentContainer>
  );
};

export default TableContents;
