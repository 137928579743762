import styled from "styled-components";

export const Foooter = styled.footer`
  background: #24262b;
  padding: 4.375rem 4.375rem 2rem;
  min-height: 40vh;
  font-family: var(--ff-button);
  @media screen and (max-width: 50em) {
    padding: 1.5rem;
  }
`;

export const Container = styled.div`
  /* border: 1px solid white; */
  max-width: 73.2rem;
  margin: auto;

  @media screen and (max-width: 50em) {
    width: 100%;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  width: 25%;
  padding: 0 0.9rem;
  color: #bbb;

  h4 {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 1.87rem;
    font-weight: 500;
    position: relative;
    font-size: 1rem;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -0.625rem;
      background: #e91e63;
      height: 0.129rem;
      width: 3.125rem;
    }
  }

  li:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  a {
    font-size: 1rem;
    color: #bbbb;
    font-weight: 300;
    display: block;
    transition: all 0.3s ease;

    &:hover {
      color: #fff;
      padding-left: 0.5rem;
    }
  }

  img {
    width: 13rem;
    height: 3rem;
  }

  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 1.8rem;
  }
  @media (max-width: 574px) {
    width: 100%;
    padding: unset;

    &:first-child {
      /* border: 1px solid red; */
      margin-inline: auto;
      width: fit-content;
    }
  }
`;

export const SocialLink = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;

  i {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    &:hover {
      background-color: #ffff;
      color: #24262b;
    }
  }
`;

export const Developer = styled.div`
  /* border: 1px solid white; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: #fff;
  margin-top: 2rem;
  padding-top: 1rem;
  position: relative;
  text-align: center;

  &::before {
    content: "";
    height: 0.01rem;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: #fff;
  }
`;
