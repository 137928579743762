import { createAsyncThunk } from "@reduxjs/toolkit";
import { userLogin } from "../../api/authApi";
import axiosInstance from "../../utils/axiosInstance";

export const logoutAction = createAsyncThunk(
  "auth/logout",
  async (
    { navigate, isForced = false, expirationDate = null },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("revive");
      let response = await axiosInstance.post(`/account/logout/`, { token });

      return response.data;
    } catch (error) {
      if (error.response) {
        // console.log(error.response);
        return rejectWithValue({
          detail: error.response.data.detail,
          status: error.response.status,
        });
      }
      return rejectWithValue({
        detail: "Unable to login user",
        status: 500,
      });
    } finally {
      localStorage.removeItem("revive");
      localStorage.removeItem("token");

      return navigate("/");
    }
  }
);

export const userLoginAction = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      let response = await userLogin({ email, password });
      localStorage.setItem("token", response.data.access);
      localStorage.setItem("revive", response.data.refresh); // refresh token
      axiosInstance.defaults.headers[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      return response.data;
    } catch (error) {
      if (error.response) {
        // console.log(error.response);
        return rejectWithValue({
          detail: error.response.data.detail,
          status: error.response.status,
        });
      }
      return rejectWithValue({
        detail: "Unable to login user",
        status: 500,
      });
    }
  }
);
