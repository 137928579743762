import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getAuthenticatedStatus, getUser } from "../redux/slices/authSlice";

const RestrictAuthenticated = () => {
  const isAuthenticated = useSelector(getAuthenticatedStatus);
  const user = useSelector(getUser);

  if (isAuthenticated) {
    // console.log("is authenticated", isAuthenticated);
    if (user.regd_as === "TCH") {
      return <Navigate to="/teacher/profile" />;
    }
    if (user.regd_as === "STU") {
      return <Navigate to="/student/profile" />;
    }
    if (user.is_superuser) {
      return <Navigate to="/superuser/analytics" />;
    }
  } else {
    // console.log("is authenticated", isAuthenticated);

    return <Outlet />;
  }
};

export default RestrictAuthenticated;
