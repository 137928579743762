import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setMetaData } from "../../redux/slices/metaDataSlice";
import ContactForm from "./Contact Form/ContactForm";
import { Container, GetInTouch, Wrapper } from "./contactStyles";
import PhysicalContact from "./PhysicalContact";

const Contact = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(setMetaData({ title: "Contact" }));
  }, []);

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <GetInTouch>Get in Touch With Us</GetInTouch> */}
      <Container>
        <PhysicalContact />
        <ContactForm />
      </Container>
    </Wrapper>
  );
};

export default Contact;
