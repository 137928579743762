import axiosInstance from "../utils/axiosInstance";

export const searchTeachers = async ({
  locationParam,
  subjectParam,
  standardParam,
  teacherNameParam,
  teacherSchoolNameParam,
  tuitionTypeParam,
  page,
}) => {
  try {
    let response = await axiosInstance.get(`/search/search-teacher/`, {
      params: {
        location: locationParam,
        subject: subjectParam,
        standard: standardParam,
        tch_name: teacherNameParam || null,
        tch_school_name: teacherSchoolNameParam || null,
        tuition_type: tuitionTypeParam || null,
        page,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
